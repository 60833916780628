import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import husd from '../images/markets/husd.svg'
import usdt from '../images/markets/usdt.svg'
import depthFilda from '../images/markets/depth_filda.svg'

import styles from './Depth.module.scss'

import { formatBigNumberWithDecimals } from '../utils/numberFormat'
import GeneralInvestModal from './subComponents/staking/GeneralInvestModal'
import { isMobile } from 'react-device-detect'
import { useWindowWidth } from '@react-hook/window-size'

function Depth(props) {
    const { t } = useTranslation();
    const clientWidth = useWindowWidth()
    const mobileMode = isMobile || clientWidth < 980
    const [pools, setPools] = useState(props.data)
    const [pool, setPool] = useState(pools[0])
    const [showInvestModal, setShowInvestModal] = useState(false)

    const handleInvest = _ => {
        setShowInvestModal(true)
    }

    const handleInvestClose = _ => {
        setShowInvestModal(false)
    }

    const handleBorrowBegin = _ => {
        setShowInvestModal(false)
    }

    const handleBorrowDone = _ => {
        setShowInvestModal(true)
    }

    const tokenUpdater = async callback => {
        await props.updateData()
        return callback()
    }

    const poolHeader = pool && (
        <div className={styles.header}>
            <h3 className={styles.title}>
                <img src={depthFilda} className={styles.logo} alt="logo" />
                HUSD - USDT Pool (FilDA) V2
            </h3>
            <Row className={styles.rows}>
                <Col className={styles.poolInfo}>
                    <div className={styles.asset}>
                        <img src={husd} className={styles.logo} alt="logo" />
                        HUSD
                    </div>
                    <div className={styles.amount}>{formatBigNumberWithDecimals(pool.deposit.tokens[0].reserves, pool.deposit.tokens[0].decimals, null, true)}</div>
                    <div className={styles.portion}>{pool.deposit.tokens[0].percent.multipliedBy(100).toFixed(2)}%</div>
                </Col>
                <Col className={styles.poolInfo}>
                    <div className={styles.asset}>
                        <img src={usdt} className={styles.logo} alt="logo" />
                        USDT
                    </div>
                    <div className={styles.amount}>{formatBigNumberWithDecimals(pool.deposit.tokens[1].reserves, pool.deposit.tokens[1].decimals, null, true)}</div>
                    <div className={styles.portion}>{pool.deposit.tokens[1].percent.multipliedBy(100).toFixed(2)}%</div>
                </Col>

                <Col className={styles.poolInfo}>
                    <div className={styles.asset}>{t("Common.BorrowAPY")}</div>

                    <div className={styles.portion}>{pool.deposit.tokens[0].symbol}:&nbsp;{(pool.deposit.tokens[0].loanAPY - pool.deposit.tokens[0].loanMintAPY).toFixed(2)}%</div>
                    <div className={styles.portion}>{pool.deposit.tokens[1].symbol}:&nbsp;{(pool.deposit.tokens[1].loanAPY - pool.deposit.tokens[1].loanMintAPY).toFixed(2)}%</div>
                </Col>

                <Col>
                    <button style={{
                        background: "linear-gradient(274.07deg, rgb(79, 138, 255) 0%, rgb(147, 176, 245) 100%)",
                        padding: "1rem",
                        border: "none",
                        borderRadius: "10px",
                        color: "white",
                        width: mobileMode ? "100%" : "200px"
                    }} onClick={handleInvest}>{t('Pilot.Invest')}</button>

                    <div className={styles.apyInfo}>
                        <a href="https://depth.fi/deposit" target="_blank" style={{
                            color: "#a6a6b0",
                            wordBreak: "break-word"
                        }}>{t("goToDepthV1")}</a>
                    </div>
                </Col>
            </Row>
            <div className={styles.apyInfo}>
                Comprehensive APY: {pool.APY.toFixed(1)}%
            </div>
        </div>
    )

    const marketTable =
        <Container className={styles.marketContainer} style={{ marginTop: 20 }}>
            {poolHeader}
            {/* <Row className={styles.actionContainer}>
                <Col md={3} className={styles.tabList}>
                    {actionTabs}
                </Col>
                <Col md={9} className={styles.actionPane}>
                    {actionContainers[activeTab]}
                </Col>
            </Row> */}
        </Container>

    return (
        <div className={styles.backContainer}>
            {/* <Container>
                <img width={100} src={DepthLogo} />
            </Container> */}

            {marketTable}

            <GeneralInvestModal
                mobileMode={mobileMode}
                show={showInvestModal}
                data={pool}
                dataTree={props.marketsArr}
                tokenUpdater={tokenUpdater}
                handleBorrowBegin={handleBorrowBegin}
                handleBorrowDone={handleBorrowDone}
                handleClose={handleInvestClose}
                marketLogo={depthFilda}
                themeColor="#0660ff"
                from="depthFi" />
        </div>
    )
}

export default Depth
