import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import BigNumber from 'bignumber.js'
import ContentLoader from 'react-content-loader'
import { isMobile } from "react-device-detect";
import { useWindowWidth } from '@react-hook/window-size'

import styles from './MyPositions.module.scss'
import { NetworkTypeContext, WalletAddressContext, Web3Context } from '../context'
import FetchData from '../methods/FetchData'
import Config from '../utils/config'
import { Multicall } from 'ethereum-multicall'
import { formatBigNumberWithDecimals } from '../utils/numberFormat'
import { GeneralStakeModal } from './subComponents/GeneralStakeModal'
import PtdLogo from '../images/logo_ptd.webp'
import SoloLogo from '../images/logo_solo.png'
import DepthLogo from '../images/logo_depth.png'
import BoosterLogo from '../images/logo_booster.png'
import contractABI from '../utils/contractABI.json'

let HTPrice = 0
let DEPPrice = 0

function Pilot(props) {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { networkType } = useContext(NetworkTypeContext)
    const { web3 } = useContext(Web3Context)
    const clientWidth = useWindowWidth()
    const mobileMode = isMobile || clientWidth < 980
    const [loadingMarkets, setLoadingMarkets] = useState(true)
    const [loadingInvestments, setLoadingInvestments] = useState(true)
    const [banks, setBanks] = useState()
    const [myInvestments, setMyInvestment] = useState([])
    const [lossLessMining, setLossLessMining] = useState()
    const [selectedBankForStaking, setSelectedBankForStaking] = useState()

    const { t } = useTranslation()

    useEffect(() => {
        let fetching = false
        async function loadData() {
            if (!fetching && connectedAddress && networkType && networkType !== "unsupported" && props.marketsArr) {
                try {
                    fetching = true
                    const data = await fetch(Config.thirdMarkets["PTD"]["data"])
                    const res = await data.json()
                    const marketsArr = props.marketsArr //FetchData.getNetworkMarkets(networkType)

                    if (HTPrice === 0) {
                        HTPrice = marketsArr.find(item => {
                            return item.symbol === "HT"
                        }).price
                    }

                    const filteredBanks = {}
                    Object.values(res.banks).forEach(b => {
                        const index = marketsArr.findIndex(e => e.symbol === b.coin_symbol)
                        if (index > -1) {
                            filteredBanks[b.coin_symbol] = {
                                ...b,
                                ...marketsArr[index]
                            }
                            if (selectedBankForStaking && selectedBankForStaking.coin_symbol === b.coin_symbol) {
                                setSelectedBankForStaking(filteredBanks[b.coin_symbol])
                            }
                        }
                    })

                    setBanks(filteredBanks)
                    setLossLessMining(res.loss_less_mining)
                    setLoadingMarkets(false)
                } catch (e) {
                } finally {
                    fetching = false
                }
            }
        }

        let timer = null
        const updateData = () => {
            loadData()
            timer = setTimeout(updateData, 5000)
        }
        updateData()
        return () => {
            clearTimeout(timer)
            setBanks()
            setLossLessMining()
        }
    }, [connectedAddress, props.marketsArr, networkType, selectedBankForStaking])

    useEffect(() => {
        if (
            !networkType ||
            networkType === "unsupported" ||
            !connectedAddress ||
            connectedAddress === "" ||
            !lossLessMining ||
            lossLessMining.length === 0 ||
            !banks ||
            banks.length === 0 ||
            !props.depthFiData ||
            !props.boosterData
        ) {
            return
        }

        // const allSoloPools = Config.solo.pools
        const allDepthFiPools = props.depthFiData//config.depthFi.pools
        const allBoosterPools = props.boosterData

        const parsePools = async resultsFromMultiCall => {
            let pools = []
            const allData = Object.values(resultsFromMultiCall)

            const pilotData = allData.filter(item => {
                return item.originalContractCallContext.reference.indexOf("pilot_") === 0
            })

            const pilotPTokenData = allData.filter(item => {
                return item.originalContractCallContext.reference.indexOf("pilotPToken_") === 0
            })

            const pilotPHUSDData = allData.filter(item => {
                return item.originalContractCallContext.reference.indexOf("pilotPTokenPHUSD_") === 0
            })[0].callsReturnContext

            const bankData = allData.filter(item => {
                return item.originalContractCallContext.reference.indexOf("bank_") === 0
            })

            for (let i = 0; i < pilotData.length; i++) {
                const resultFromMultiCall = pilotData[i]
                let result = resultFromMultiCall.callsReturnContext
                const thePToken = Config.pilot.stakingRewards.pairs[resultFromMultiCall.originalContractCallContext.context.index]
                thePToken.from = "pilot"

                const banksData = FetchData.pickFromMultiCallResults("banks", bankData[i].callsReturnContext)
                thePToken.token.totalVal = banksData[2]
                thePToken.token.totalSupply = banksData[2]
                thePToken.token.totalDebt = banksData[3]
                thePToken.token.totalReserve = banksData[5]

                if (thePToken.pToken.symbol === "pHUSD" && pilotPHUSDData) {
                    result = result.concat(pilotPHUSDData)
                    thePToken.pToken.allowance = FetchData.pickFromMultiCallResults("allowance", result)
                    thePToken.pToken.totalSupply = FetchData.pickFromMultiCallResults("totalSupply", result)

                    if (thePToken.pToken.allowance.comparedTo(0) > 0) {
                        thePToken.pToken.approved = true
                    } else {
                        thePToken.pToken.approved = false
                    }
                } else {
                    thePToken.pToken.totalSupply = FetchData.pickFromMultiCallResults("totalSupply", pilotPTokenData[i].callsReturnContext)
                }

                thePToken.pToken.balanceOfForThis = FetchData.pickFromMultiCallResults("balanceOf", pilotPTokenData[i].callsReturnContext)

                const stakingRewardsBalance = FetchData.pickFromMultiCallResults("balanceOf", result)
                const stakingRewardsEarned = FetchData.pickFromMultiCallResults("earned", result)

                const theLossLessMining = lossLessMining[thePToken.pToken.symbol]
                if (theLossLessMining) {
                    let decimals = new BigNumber(10).pow(thePToken.pToken.decimals)
                    thePToken.stakingRewards.totalValue = new BigNumber(theLossLessMining.tvl).dividedBy(new BigNumber(theLossLessMining.total).dividedBy(decimals)).multipliedBy(stakingRewardsBalance.dividedBy(decimals)).toFixed(2)
                }

                if (stakingRewardsBalance.comparedTo(0) > 0) {
                    thePToken.bank = banks[thePToken.pool]
                    thePToken.bank.from = "pilot"
                    thePToken.stakingRewards.balanceOf = stakingRewardsBalance
                    thePToken.stakingRewards.APR = (Number(theLossLessMining.profitRate) * 100).toFixed(2)
                    thePToken.stakingRewards.earned = stakingRewardsEarned

                    if (thePToken) {
                        pools.push(thePToken)
                    }
                } else if (thePToken.pToken.balanceOfForThis.comparedTo(0) > 0) {
                    thePToken.bank = banks[thePToken.pool]
                    thePToken.bank.from = "pilot"
                    thePToken.stakingRewards.balanceOf = null
                    thePToken.stakingRewards.APR = null
                    thePToken.stakingRewards.earned = null

                    if (thePToken) {
                        pools.push(thePToken)
                    }
                }
            }

            // const soloData = allData.filter(item => {
            //     return item.originalContractCallContext.reference.indexOf("solo_") === 0
            // })

            // for (let i = 0; i < soloData.length; i++) {
            //     const resultFromMultiCall = soloData[i]
            //     const result = resultFromMultiCall.callsReturnContext
            //     const thePToken = allSoloPools[resultFromMultiCall.originalContractCallContext.context.index]

            //     thePToken.from = "solo"

            //     const poolsInfo = FetchData.pickFromMultiCallResults("pools", result)
            //     thePToken.APY = poolsInfo[6].dividedBy(100).toFixed(2)

            //     const users = FetchData.pickFromMultiCallResults("users", result)
            //     thePToken.amount = users[0]
            //     thePToken.rewardDebt = users[1]

            //     thePToken.unclaimedReward = FetchData.pickFromMultiCallResults("unclaimedReward", result)

            //     if (thePToken.rewardDebt.comparedTo(0) > 0 || thePToken.unclaimedReward.comparedTo(0) > 0) {
            //         const bak = { ...thePToken }
            //         if (props.marketsArr && props.marketsArr.length > 0) {
            //             Object.assign(thePToken, props.marketsArr.find(market => {
            //                 return market.symbol === thePToken.alias
            //             }))
            //             Object.assign(thePToken, bak)
            //         }

            //         thePToken.totalValue = thePToken.amount.shiftedBy(-thePToken.decimals).multipliedBy(thePToken.price)

            //         pools.push(thePToken)
            //     }
            // }

            if (DEPPrice === 0) {
                const DEPPriceData = allData.filter(item => {
                    return item.originalContractCallContext.reference.indexOf("DEPPrice") === 0
                })
                const getAmountsOut = FetchData.pickFromMultiCallResults("getAmountsOut", DEPPriceData[0].callsReturnContext)
                DEPPrice = getAmountsOut[0].dividedBy(getAmountsOut[1]).multipliedBy(HTPrice)
            }

            allDepthFiPools.forEach(depthFiPool => {
                depthFiPool.staking.reward.price = DEPPrice

                if (depthFiPool.staking.rewardDebt.comparedTo(0) > 0 || depthFiPool.deposit.totalDepositedValue.comparedTo(0) > 0) {
                    pools.push(depthFiPool)
                }
            })

            allBoosterPools.forEach(boosterPool => {
                if (boosterPool.stakedLPTokenAmount.comparedTo(0) > 0 || boosterPool.balanceOf.comparedTo(0) > 0) {
                    pools.push(boosterPool)
                }
            })

            setMyInvestment([...pools])
        }

        const fetchData = async () => {
            const multiCall = new Multicall({
                multicallCustomContractAddress: Config.multiCall.network[networkType].address,
                web3Instance: web3
            })

            const contractCallContext = []
            Object.values(Config.pilot.stakingRewards.pairs).forEach((pair, index) => {
                contractCallContext.push({
                    reference: "pilot_" + pair.pToken.symbol,
                    contractAddress: pair.stakingRewards.address,
                    abi: Config.pilot.stakingRewards.ABI,
                    calls: [
                        { reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] },
                        { reference: "earned", methodName: "earned", methodParameters: [connectedAddress] }
                    ],
                    context: { index }
                })

                contractCallContext.push({
                    reference: "pilotPToken_" + pair.pToken.symbol,
                    contractAddress: pair.pToken.address,
                    abi: pair.pToken.ABI,
                    calls: [
                        { reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] },
                        { reference: "totalSupply", methodName: "totalSupply" }
                    ],
                    context: { index }
                })

                if (pair.pToken.symbol === "pHUSD") {
                    contractCallContext.push({
                        reference: "pilotPTokenPHUSD_" + pair.pToken.symbol,
                        // contractAddress: pair.pToken.realToken.address,
                        contractAddress: pair.pToken.midToken.address,
                        // abi: pair.pToken.realToken.ABI,
                        abi: pair.pToken.midToken.ABI,
                        calls: [
                            { reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, pair.pToken.address] },
                            { reference: "totalSupply", methodName: "totalSupply" }
                        ],
                        context: { index }
                    })
                }

                contractCallContext.push({
                    reference: "bank_" + pair.pToken.symbol,
                    contractAddress: Config.pilot.address,
                    abi: Config.pilot.ABI,
                    calls: [
                        { reference: "banks", methodName: "banks", methodParameters: [pair.token.address] }
                    ],
                    context: { address: pair.token.address }
                })
            })

            // allSoloPools.forEach((pool, index) => {
            //     contractCallContext.push({
            //         reference: "solo_" + pool.for + "_" + pool.symbol,
            //         contractAddress: pool.for === "MDX" ? Config.solo.poolContract.address : Config.soloBXH.poolContract.address,
            //         abi: pool.for === "MDX" ? Config.solo.poolContract.ABI : Config.soloBXH.poolContract.ABI,
            //         calls: [
            //             { reference: "users", methodName: "users", methodParameters: [pool.indexOfPool, connectedAddress] },
            //             { reference: "pools", methodName: "pools", methodParameters: [pool.indexOfPool] },
            //             { reference: "unclaimedReward", methodName: "unclaimedReward", methodParameters: [pool.indexOfPool, connectedAddress] }
            //         ],
            //         context: { index }
            //     })
            // })

            // allDepthFiPools.forEach((pool, index) => {
            //     contractCallContext.push({
            //         reference: "depthFi_" + pool.name,
            //         contractAddress: pool.staking.poolContract.address,
            //         abi: pool.staking.poolContract.ABI,
            //         calls: [
            //             { reference: "pendingPiggy", methodName: "pendingPiggy", methodParameters: [pool.staking.LPToken.indexOfPool, connectedAddress] },
            //             { reference: "userInfo", methodName: "userInfo", methodParameters: [pool.staking.LPToken.indexOfPool, connectedAddress] }
            //         ],
            //         context: { index }
            //     })

            //     contractCallContext.push({
            //         reference: "curve",
            //         contractAddress: pool.deposit.curve.address,
            //         abi: pool.deposit.curve.ABI,
            //         calls: [
            //             { reference: "balances0", methodName: "balances", methodParameters: [pool.deposit.tokens[0].indexOfPool] },
            //             { reference: "balances1", methodName: "balances", methodParameters: [pool.deposit.tokens[1].indexOfPool] }
            //         ],
            //         context: { index }
            //     })

            //     contractCallContext.push({
            //         reference: "stakingToken_" + pool.name,
            //         contractAddress: pool.staking.LPToken.address,
            //         abi: pool.staking.LPToken.ABI,
            //         calls: [
            //             { reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, pool.staking.poolContract.address] },
            //             { reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] }
            //         ],
            //         context: { index }
            //     })
            // })

            contractCallContext.push({
                reference: "DEPPrice",
                contractAddress: Config.MDEXRouter,
                abi: contractABI.MDEXRouterABI,
                calls: [
                    { reference: "getAmountsOut", methodName: "getAmountsOut", methodParameters: ["1000000000000000000", ["0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F", "0x48C859531254F25e57D1C1A8E030Ef0B1c895c27"]] }
                ]
            })

            // Object.values(Config.solo.pools).forEach((pool, index) => {
            //     contractCallContext.push({
            //         reference: "soloToken_" + pool.symbol,
            //         contractAddress: pool.address,
            //         abi: contractABI.ERC20,
            //         calls: [
            //             { reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] }
            //         ],
            //         context: { index }
            //     })
            // })

            const results = (await multiCall.call(contractCallContext)).results
            parsePools(results)
            setLoadingInvestments(false)
        }

        fetchData()
    }, [networkType, connectedAddress, lossLessMining, banks, props.depthFiData, props.boosterData, web3])

    if (loadingInvestments || loadingMarkets) {
        return (
            <div className={styles.backContainer}>
                <Container className={styles.marketContainer}>
                    <div className={styles.title}>
                        {t('ThirdMarkets.MyPosition')}
                    </div>
                    <div className={styles.market}>
                        <ContentLoader
                            height={130}
                            width={"100%"}
                            speed={1}
                        >
                            <rect x="0" y="20" rx="4" ry="4" width="100%" height="40" />
                            <rect x="0" y="80" rx="4" ry="4" width="100%" height="40" />
                        </ContentLoader>
                    </div>
                </Container>
            </div>
        )
    }

    const investmentHeader = (
        <div className={styles.headerRow}>
            <div className={styles.headerItem}>{t('Pilot.Pool')}</div>
            <div className={styles.headerItem}>{t('Pilot.TotalValue')}</div>
            <div className={styles.headerItem}>{t('Pilot.Deposited')}&nbsp;/&nbsp;{t('Pilot.Owned')}</div>
            <div className={styles.headerItem}>{t('Pilot.APR')}</div>
            <div className={styles.headerItem} style={{ textAlign: "right" }}>{t('Pilot.Rewards')}</div>
            <div className={styles.headerItem} style={{ textAlign: "right" }}>{t('Pilot.Operate')}</div>
        </div>
    )

    const getTokenProperty = (pool, key) => {
        if (pool.from === "pilot") {
            if (key === "totalValue" || key === "APR") {
                return pool.stakingRewards[key] || "-"
            }

            if (key === "balanceOf") {
                return pool.stakingRewards.balanceOf || pool.pToken.balanceOfForThis
            }

            if (key === "earned") {
                return pool.stakingRewards[key] || new BigNumber(0)
            }

            if (key === "rewardsSymbol") {
                return "PTD"
            }

            if (key === "stakingSymbol") {
                return pool.pToken.symbol
            }

            if (key === "rewardsDecimals") {
                return Config.pilot.rewardToken.decimals
            }

            return pool.pToken[key] ?? "-"
        }

        if (pool.from === "solo") {
            if (key === "totalValue") {
                return pool.totalValue.toFixed(2)
            }

            if (key === "balanceOf") {
                return pool.amount
            }

            if (key === "APR") {
                return pool.APY ?? "-"
            }

            if (key === "earned") {
                return pool.unclaimedReward
            }

            if (key === "rewardsSymbol") {
                return pool.for
            }

            if (key === "stakingSymbol") {
                return pool.symbol
            }

            if (key === "rewardsDecimals") {
                if (pool.for === "mdx") {
                    return Config.solo.reward.decimals
                } else {
                    return Config.soloBXH.reward.decimals
                }
            }

            return pool[key]
        }

        if (pool.from === "depthFi") {
            if (key === "totalValue") {
                return pool.deposit.totalDepositedValue.toFixed(2) || "0.00"
            }

            if (key === "symbol") {
                return pool.name
            }

            if (key === "balanceOf") {
                return pool.staking.amount
            }

            if (key === "decimals") {
                return pool.staking.LPToken.decimals
            }

            if (key === "rewardsDecimals") {
                return pool.staking.reward.decimals
            }

            if (key === "rewardsSymbol") {
                return pool.staking.reward.symbol
            }

            if (key === "stakingSymbol") {
                return pool.staking.LPToken.symbol
            }

            if (key === "earned") {
                return pool.staking.poolContract.pendingPiggy
            }

            if (key === "APR") {
                return pool.staking.APY.toFixed(2) ?? "-"
            }

            return pool[key] ?? "-"
        }

        if (pool.from === "booster") {
            if (key === "symbol") {
                return pool.name
            }

            if (key === "balanceOf") {
                return pool.stakedLPTokenAmount
            }

            if (key === "stakingSymbol") {
                return pool.symbol
            }

            if (key === "totalValue") {
                return pool.totalValue.toFixed(2)
            }

            if (key === "earned") {
                return (<div style={{ fontSize: "small", textAlign: "right" }}>
                    <div>{pool.pendingRewardsBoo.shiftedBy(-Config.booster.rewardToken.decimals).toFixed(4)}&nbsp;{Config.booster.rewardToken.symbol}</div>
                    <div>{pool.pendingRewards.shiftedBy(-Config.booster.fildaAsReward.decimals).toFixed(6)}&nbsp;{Config.booster.fildaAsReward.symbol}</div>
                </div>)
            }

            if (key === "APR") {
                return pool.APY ? pool.APY.toFixed(2) : "-"
            }

            return pool[key] || ""
        }
    }

    const getLogoWithKey = key => {
        let sourceLogo = ""
        switch (key) {
            case "pilot":
                sourceLogo = PtdLogo
                break

            case "solo":
                sourceLogo = SoloLogo
                break

            case "depthFi":
                sourceLogo = DepthLogo
                break

            default:
                sourceLogo = BoosterLogo
                break
        }
        return sourceLogo
    }

    const investmentRows = myInvestments.map((invest, i) => {
        return (
            <div key={i} className={styles.pool} style={{
                borderBottom: i === (myInvestments.length - 1) ? "none" : ""
            }}>
                <div className={styles.poolItem}>
                    <div>
                        <img
                            className={styles.logo}
                            src={getTokenProperty(invest, "logo")}
                            alt="logo" />

                        <span>{getTokenProperty(invest, "symbol")}&nbsp;&nbsp;</span>

                        <img
                            className={styles.sourceLogo}
                            src={getLogoWithKey(invest.from)}
                            alt={invest.from} />
                    </div>
                </div>

                <div className={styles.poolItem}>
                    <span className={styles.label}>{t('Pilot.TotalValue')}</span>
                    <span>$&nbsp;{getTokenProperty(invest, "totalValue")}</span>
                </div>
                <div className={styles.poolItem}>
                    <span className={styles.label}>{t('Pilot.Deposited')}</span>
                    <span>{formatBigNumberWithDecimals(getTokenProperty(invest, "balanceOf"), getTokenProperty(invest, "decimals"), 8)}&nbsp;{getTokenProperty(invest, "stakingSymbol")}</span>
                </div>
                <div className={styles.poolItem}>
                    <span className={styles.label}>{t('Pilot.APR')}</span>
                    <span className="green">
                        {getTokenProperty(invest, "APR")}%
                    </span>
                </div>
                <div className={styles.poolItem + " green"}>
                    <span className={styles.label}>{t('Pilot.Rewards')}</span>
                    {invest.from === "booster" ? getTokenProperty(invest, "earned") : formatBigNumberWithDecimals(getTokenProperty(invest, "earned"), getTokenProperty(invest, "rewardsDecimals"), 8)}{" " + getTokenProperty(invest, "rewardsSymbol")}
                </div>
                <div className={styles.poolItem}>
                    <span className={styles.label}>&nbsp;</span>

                    <div style={{ textAlign: "right" }}>
                        <button className={styles.operateButton} onClick={() => setSelectedBankForStaking(invest)}>{t("Pilot.Details")}</button>
                    </div>
                </div>
            </div>)
    })

    const investmentTable =
        <Container className={styles.marketContainer}>
            <div className={styles.title}>
                {t('ThirdMarkets.MyPosition')}
            </div>
            <div className={styles.market}>
                {investmentHeader}
                {investmentRows}
            </div>
        </Container>

    const getDarkColorWithMarket = market => {
        let color = "black"
        switch (market) {
            case "pilot":
                color = "#5b31b9"
                break
            case "solo":
                color = "#ff5f8a"
                break
            case "depthFi":
                color = "#0660ff"
                break
            case "booster":
                color = "#ff6800"
                break
        }
        return color
    }

    const getLightColorWithMarket = market => {
        let color = "black"
        switch (market) {
            case "pilot":
                color = "#f1eef9"
                break
            case "solo":
                color = "#fbf2f5"
                break
            case "depthFi":
                color = "#edf3ff"
                break
            case "booster":
                color = "#fff3eb"
                break
        }
        return color
    }

    return (
        <div className={styles.backContainer} style={{
            display: myInvestments.length === 0 ? "none" : "",
            paddingTop: "60px"
        }}>
            {investmentTable}

            {selectedBankForStaking && (
                <GeneralStakeModal
                    styles={styles}
                    darkThemeColor={getDarkColorWithMarket(selectedBankForStaking.from)}
                    lightThemeColor={getLightColorWithMarket(selectedBankForStaking.from)}
                    marketLogo={getLogoWithKey(selectedBankForStaking.from)}
                    updateData={props.updateData}
                    mobileMode={mobileMode}
                    show={!!selectedBankForStaking}
                    pool={selectedBankForStaking}
                    handleClose={() => setSelectedBankForStaking()} />
            )}
        </div>
    )
}

export default Pilot
