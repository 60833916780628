import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import classNames from 'classnames'
import BigNumber from 'bignumber.js'
import log from '@src/utils/logger'
import { useTranslation } from 'react-i18next'
import { FaAngleDoubleRight } from 'react-icons/fa'
import CoreData from '@src/methods/CoreData'
import { NetworkTypeContext, WalletAddressContext, Web3Context } from '@src/context'
import Config from '@src/utils/config'
import FetchData from '@src/methods/FetchData'
import LoadingIcon from '@src/images/savingsloading.svg'
import ErrorIcon from '@src/images/error.svg'
import CheckIcon from '@src/images/check.svg'

const HecoPool = require('@src/lib/HecoPool.json')



export default function LPTokenRewardsModal(props) {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { networkType } = useContext(NetworkTypeContext)
    const { web3 } = useContext(Web3Context)

    const styles = props.styles
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [claimCompleted, setClaimCompleted] = useState(false)
    const [claimFailed, setClaimFailed] = useState(false)
    const [txnHash, setTxnHash] = useState('')
    const [mdxData, setMDXData] = useState(null)

    // const fetchRate = async (amount) => {
    //     const myContract = new web3.eth.Contract(HecoPool.abi, Config.FeeManagerContract[networkType])
    //     const molecular = await myContract.methods.FEE_MOLECULAR().call()
    //     const denominator = await myContract.methods.FEE_DENOMINATOR().call()
    //     return new BigNumber(molecular).div(denominator).toString()
    // }

    const handleClose = async () => {
        setLoading(false)
        setClaimCompleted(false)
        setClaimFailed(false)
        setTxnHash('')
        props.handleClaimClose()
    }

    useEffect(() => {
        if (props.show, props.allData) {
            const mdxData = props.allData.filter( i => i.symbol === 'MDX')[0]
            setMDXData(mdxData)
        }
    }, [props.show, props.allData])

    const handleClaim = async () => {
        setLoading(true)
        const gasInfo = await CoreData.getGasInfo(web3)
        const ctoken = await CoreData.getQTokenContract(web3, networkType, props.data.symbol)
        await ctoken.methods.claimMdx(connectedAddress).send({
            from: connectedAddress,
            gasLimit: web3.utils.toHex(gasInfo.gasLimit),
            gasPrice: web3.utils.toHex(gasInfo.gasPrice)
        })
        .on('transactionHash' , function(hash){
            props.data.lpRewardTxnHash = hash
            setTxnHash(hash)
        })
        .then(response => {
            console.log(response)
            if (response.events.Failure) {
                setClaimFailed(true)
            } else {
                setClaimCompleted(true)
            }
            props.data.lpRewardTxnHash = null
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            if (error.code === 4001) {
                handleClose()
            } else {
                setClaimFailed(true)
                props.data.lpRewardTxnHash = null
            }
        })
    }


    const fetchRewardValue = async () => {
        const ctoken = await CoreData.getQLPTokenContract(web3, networkType, props.data.symbol)
        const mdxAmount = await ctoken.methods.fTokenUserAccrued(connectedAddress).call()
        const fildaAmount = await ctoken.methods.compUserAccrued(connectedAddress).call()

        const mdxToken = await ctoken.methods.mdx().call()
        const fildaToken = await ctoken.methods.comp().call()

        const mdxDecimal = await getDecimal(mdxToken)
        const fildaDecimal = await getDecimal(fildaToken)

        const mdxValue = BigNumber(mdxAmount).shiftedBy(-parseInt(mdxDecimal)).toString()
        const fildaValue = BigNumber(fildaAmount).shiftedBy(-parseInt(mdxDecimal)).toString()


        return {mdxValue, fildaValue}
    }

    const getDecimal = async(token) => {
        const Contract = await new web3.eth.Contract(props.data.ABI, token)
        const decimal = await Contract.methods.decimals().call()
        return decimal
    }

    //UI Rendering

    const ModalLoading =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={LoadingIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="loading"
                    />
                    {
                        props.data.lpRewardTxnHash === '' ? 'loading' :
                            <a style={{ color: '#4FDAB8' }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
                    }
                </div>
            </Modal.Body>
        </div>


    const ModalForm =
        <>
            <Modal.Body>
                <div className={styles.claimContainer}>
                    <div className={styles.claimLabelWrap}>
                        <div className={styles.label}>{t('ClaimModal.Earned')}</div>
                        <div className={styles.label}>{t('ClaimModal.Balance')}</div>
                    </div>
                    <div className={styles.claimValueWrap}>
                        <div className={styles.rValue}>
                            <div className={styles.value}>{props.data.mdxReward ? `${props.data.mdxReward.toFixed(8)} MDX` : '0 MDX'}</div>
                            <div className={styles.value}>{props.data.fildaReward ? `${props.data.fildaReward.toFixed(8)} FILDA` : '0 FILDA'}</div>
                        </div>
                        <FaAngleDoubleRight className={styles.icon} />
                        <div className={styles.rValue}>
                            <div className={styles.value}>
                                {mdxData && mdxData.walletBalanceFormatted ? `${parseFloat(mdxData.walletBalanceFormatted).toFixed(8)} MDX` : '0 MDX'}
                            </div>
                            <div className={styles.value}>
                                {props.allData.compBalance ? `${parseFloat(props.allData.compBalance).toFixed(8)} FILDA` : '0 FILDA'}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="savings" 
                    onClick={handleClaim} 
                    disabled={!props.data.mdxReward & !props.data.mdxReward}
                >{t('Header.Collect')}</Button>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Cancel')}</Button>
            </Modal.Footer>
        </>

    const TxnSuccessMsg =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={CheckIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="error"
                    />
                </div>
                <div className={styles.approvalMsg}>{t('LPRewardModal.TokensClaimedSuccessfully')}</div>
                <a className={styles.borrowLink} style={{ color: "#BDB780" }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Close')}</Button>
            </Modal.Footer>
        </div>

    const TxnErrorMsg =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={ErrorIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="error"
                    />
                </div>
                <div className={styles.approvalMsg}>{t('LPRewardModal.TokensClaimFailed')}</div>
                <a className={styles.borrowLink} style={{ color: "#BDB780" }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Close')}</Button>
            </Modal.Footer>
        </div>

    const ModalRendered = loading ? ModalLoading : ModalForm

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            className={classNames(styles.txnModal, styles.lpTokenModal)}
            centered
            animation={false}>
            <Modal.Header closeButton>
                <img
                    src={props.data.logo}
                    width={props.data.isLPToken ? 62 : 36}
                    className="d-inline-block align-top"
                    alt={`${props.data.name} Logo`}
                />
                <div className={styles.assetName}>{props.data.name}</div>
                {
                    claimCompleted || claimFailed ? '' :
                        <div className={styles.txnTypeDesc}>
                            {t('Stake.Redeem.Rewards')}
                        </div>
                }
            </Modal.Header>
            {
                claimCompleted ? TxnSuccessMsg :
                    claimFailed ? TxnErrorMsg : ModalRendered
            }
        </Modal>
    )
}
