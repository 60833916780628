import React, {useContext, useEffect, useRef, useState} from 'react'
import {useLocation, Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { CgMenu, CgClose} from 'react-icons/cg'
import { CSSTransition } from 'react-transition-group'
import {NavScreen} from '@src/components/footerV2/commonNav'
import FildaLogo from '@src/images/v2/logo_text.svg'
import Header from '@src/components/Header'
import {SwitchLang} from '@src/components/littleComponents/switchLang'
import {CommonHeader} from './commonHeader';
import styles from './index.module.scss'


export const HeaderV2 = () => {
  const [modal, setModal] = useState(false) // mobile的modal
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const nodeRef = useRef(null)
  const V2 = ['/bankV2', '/stakingV2', '/liquidateV2']

  useEffect(() => {
    const handle = (e) => {
      if (!document.querySelector('.modalPhone')?.contains(e.target) && modal) {
        setModal(false)
      }
    }

    document.addEventListener('click', handle)
    return () => document.removeEventListener('click', handle)
  }, [modal])

  const renderNavDesktop = () => {
    return (
      <nav className={styles.headerNav}>
        <NavScreen/>
        <span className={styles.goApp}>
          <Link to="/bankV2">LAUNCH APP</Link>
        </span>
      </nav>
    )
  }


  const renderNavMobile = () => {
    return (
      <div className={styles.headerNavMobile} onClick={() => setModal(true)}>
        <CgMenu/>
      </div>
    )
  }

  const renderModalMobile = () => {
    return (
      <CSSTransition
        nodeRef={nodeRef}
        in={modal}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div ref={nodeRef} className={classNames('modalPhone', styles.modalMobile)}>
          <header>
            <div className={styles.closeIcon} onClick={() => setModal(false)}>
              <CgClose />
            </div>
          </header>
          <main>
            <Link to="/">
              <img className={styles.logo} src={FildaLogo} alt="Filda logo"/>
            </Link>
            <NavScreen/>
            <div className={styles.modalPhoneSwitchLang}>
              <SwitchLang drop={'up'}/>
            </div>
          </main>
        </div> 
      </CSSTransition>
    )
  }
  
  if (V2.includes(pathname) || pathname.startsWith('/marketsV2')) return <CommonHeader/>

  if (pathname !== '/welcome') return <Header/>

  return (
    <header className={styles.headerV2}>
        <Link to="/welcome">
          <img className={styles.logo} src={FildaLogo} alt="Filda logo"/>
        </Link>
        {renderNavDesktop()}
        {renderNavMobile()}
        {renderModalMobile()}
    </header>
  )
}


