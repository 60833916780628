import React from 'react'
import classNames from 'classnames'
import {useLocation} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FaGithub, FaWeixin, FaTwitter, FaTelegramPlane, FaMediumM, FaHeart } from 'react-icons/fa'
import Footer from '@src/components/Footer'
import {NavScreen} from './commonNav'
import {SwitchLang} from '@src/components/littleComponents/switchLang'
import FildaLogo from '@src/images/v2/logo_text.svg'
import styles from './index.module.scss'

export const FooterV2 = () => {
  const { t, i18n } = useTranslation()
  const { pathname } = useLocation()
  const V2 = ['/bankV2', '/welcome', '/stakingV2', '/liquidateV2']


  const renderMedia = () => {
    const iconSize = 24;
    return (
      <div className={styles.media_list}>
        <div className={classNames(styles.media_item)}>
          <a target="_blank" href="https://t.me/FilDAcommunity">
            <FaTelegramPlane size={iconSize}/>
            <span>TELEGRAM</span>
          </a>
        </div>
        <div className={classNames(styles.media_item)}>
          <a target="_blank" href='Wechat.jpeg'>
            <FaWeixin size={iconSize}/>
            <span>WECHAT</span>
          </a>
        </div>
        <div className={classNames(styles.media_item)}>
          <a target="_blank" href="https://twitter.com/fildafinance">
            <FaTwitter size={iconSize}/>
            <span>TWITTER</span>
          </a>
        </div>
        <div className={classNames(styles.media_item)}>
          <a target="_blank" href="https://www.reddit.com/r/FilDA/">
            <FaMediumM size={iconSize}/>
            <span>MEDIUM</span>
          </a>
        </div>
        <div className={classNames(styles.media_item)}>
          <a target="_blank" href="https://github.com/fildaio/FilDA">
            <FaGithub size={iconSize} title="Github"/>
            <span>GITHUB</span>
          </a>
        </div>
      </div>
    )
  }


  const renderPhoneMedia = () => {
    const iconSize = 16;
    return (
      <div className={styles.media_phoneList}>
        <div className={classNames(styles.media_phoneItem)}>
          <a target="_blank" href="https://t.me/FilDAcommunity">
            <FaTelegramPlane size={iconSize}/>
          </a>
        </div>
        <div className={classNames(styles.media_phoneItem)}>
          <a target="_blank" href='Wechat.jpeg'>
            <FaWeixin size={iconSize}/>
          </a>
        </div>
        <div className={classNames(styles.media_phoneItem)}>
          <a target="_blank" href="https://twitter.com/fildafinance">
            <FaTwitter size={iconSize}/>
          </a>
        </div>
        <div className={classNames(styles.media_phoneItem)}>
          <a target="_blank" href="https://www.reddit.com/r/FilDA/">
            <FaMediumM size={iconSize}/>
          </a>
        </div>
        <div className={classNames(styles.media_phoneItem)}>
          <a target="_blank" href="https://github.com/fildaio/FilDA">
            <FaGithub size={iconSize} title="Github"/>
          </a>
        </div>
      </div>
    )
  }



  return V2.includes(pathname)|| pathname.startsWith('/marketsV2') ? (
      <footer className={styles.footerV2}>
          {renderMedia()}
          <div className={styles.footerNav}>
            <NavScreen/>
          </div>
          
          {renderPhoneMedia()}
          <SwitchLang/>
          <img className={styles.logo} src={FildaLogo} alt="Filda logo"/>
          <div className={styles.poweredByText}>made with<span className={styles.heart}>❤️</span>by FilDA Team 🌍</div>
      </footer> 
    ) : <Footer/>
}