import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { LanguageContext } from '@src/context'
import CoreData from '@src/methods/CoreData'
import styles from './commonNav.module.scss'


export const NavScreen = (props) => {
  const [exchangeModal, setExchangeModal] = useState(false)
  const { t, i18n } = useTranslation()
  let timer = null;


  const handleMouse = (e, isOpen, isMenu = true) => {
    e.stopPropagation()
    clearTimeout(timer)
    if (isMenu) {
      timer = setTimeout(() => {
        setExchangeModal(isOpen)
      }, 300)
    } else {
      setExchangeModal(isOpen)
    }
  }


  return (
    <ul className={classNames('fd-navMenu', styles.menu)}>
      <li className="menuItem">
        <NavLink 
          className="headerLink"
          target="_blank"
          to={{
            pathname: 'https://filda-1.gitbook.io/filda/'
          }}
        >
          <span>{CoreData.toUpperCase(i18n.language, t('Footer.Docs'))}</span>
        </NavLink>
      </li>
      <li className="menuItem">
        <NavLink 
          className="headerLink"
          target="_blank"
          to={{
            pathname: 'https://info.mdex.me/#/token/0xe36ffd17b2661eb57144ceaef942d95295e637f0'
          }}
        >
          <span>
            {CoreData.toUpperCase(i18n.language, t('Footer.Exchange'))}
          </span>
        </NavLink>
      </li>
      <li className="menuItem">
        <NavLink 
          activeClassName="active" 
          className="headerLink" 
          to="/liquidateV2"
        >
          <span>
            {CoreData.toUpperCase(i18n.language, t('Footer.Liquidation'))}
          </span>
        </NavLink>
      </li>
    </ul>
  )
}

