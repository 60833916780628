import Config from '../utils/config'
import moment from 'moment'
import filda from '../images/markets/filda.svg'
import fildaV2 from '../images/v2/logo.svg'
import fela from '../images/markets/fela.svg'
import ela from '../images/markets/ela.svg'
import fhusd from '../images/markets/fhusd.svg'
import husd from '../images/markets/husd.svg'
import fclc from '../images/markets/fclc.svg'
import ht from '../images/markets/ht.svg'
import fht from '../images/markets/filda-ht.png'
import mdx from '../images/markets/mdx.png'
import elk from '../images/markets/elk.png'
import dog from '../images/markets/dog.png'
import tusd from '../images/markets/tusd.png'
import filDog from '../images/markets/filda-dog.png'
import filWht from '../images/markets/filda-wht.png'
import mdxfilda from '../images/markets/filda-mdx.png'
import ftusd from '../images/markets/tusd-filda.png'
import hfi from '../images/markets/hfi.png'
import share from '../images/markets/share.png'
import BigNumber from 'bignumber.js'
import CoreData from './CoreData'
import FetchData from './FetchData'
import log from '../utils/logger'

const getLogo = (tokenSymbol) => {
	switch (tokenSymbol) {
		case "FTUSD":
			return ftusd

		case 'TUSD':
			return tusd

		case "ELK":
			return elk

		case "FELA":
			return fela

		case "ELA":
			return ela

		case "FHUSD":
			return fhusd

		case 'HUSD':
			return husd

		case "FCLC":
			return fclc

		case "FHT":
			return fht

		case "HT":
			return ht

		case "MDXFILDA":
			return mdxfilda

		case "HFI":
			return hfi

		case "MDX":
			return mdx

		case "DOG":
			return dog

		case "FILDOG":
			return filDog

		case "FILWHT":
			return filWht

		case "SHARE":
			return share

		case 'FILDAV2':
			return fildaV2

		default:
			return filda
	}
}

const getPoolList = network => {
	const pools = Config.pools[network]
	if (!pools) {
		return []
	}

	return Object.keys(pools).map(k => ({ 'address': k })).reverse()
}

let rewardTokens = JSON.parse(window.localStorage.getItem("rewardTokens"))
let LPTokens = JSON.parse(window.localStorage.getItem("LPTokens"))

const getPoolInfo = async (web3, networkType, connectedAddress, poolAddress, multiCallResults, lpTokenData) => {
	const allPoolsConfig = Config.pools[networkType]
	if (!allPoolsConfig) {
		return
	}

	const pickFromMultiCallResults = (key, withinLPToken) => {
		const store = withinLPToken ? lpTokenData : multiCallResults
		if (store) {
			const thePool = store.find(item => {
				return item.methodName === key
			})

			let values = null
			if (thePool) {
				values = thePool.returnValues[0]
			} else {
				return null
			}

			if ((typeof values) === "string") {
				return values
			}

			if ((typeof values) === "number") {
				return values
			}

			if ((typeof values) === "object") {
				if (values.type === "BigNumber") {
					return new BigNumber(values.hex).toFixed()
				}
			}
		} else {
			return null
		}
	}

	const noMintRewardPoolABI = Config.noMintRewardPool.ABI
	const erc20ABI = Config.erc20.ABI
	const poolInfo = { address: poolAddress }

	const isShortcut = allPoolsConfig[poolAddress].isShortcut
	poolInfo.isShortcut = isShortcut
	poolInfo.indexOfPool = allPoolsConfig[poolAddress].indexOfPool
	poolInfo.lpTokenShortName = allPoolsConfig[poolAddress].lpTokenShortName
	poolInfo.fromDogswap = allPoolsConfig[poolAddress].fromDogswap
	poolInfo.pool = allPoolsConfig[poolAddress].pool
	poolInfo.lpTokenId = allPoolsConfig[poolAddress].lpTokenId
	poolInfo.lpPairAddress = allPoolsConfig[poolAddress].lpPairAddress
	if (allPoolsConfig[poolAddress].uiLpTokenId) {
		poolInfo.uiLpTokenId = allPoolsConfig[poolAddress].uiLpTokenId
	}
	poolInfo.isAirdrop = allPoolsConfig[poolAddress].isAirdrop
	poolInfo.lpToken = allPoolsConfig[poolAddress].lpToken

	let noMintRewardPoolContract
	if (!isShortcut) {
		noMintRewardPoolContract = await new web3.eth.Contract(noMintRewardPoolABI, poolAddress)
	} else {
		noMintRewardPoolContract = await new web3.eth.Contract(poolInfo.pool.ABI, poolInfo.pool.address)
	}

	let rewardTokenFromMultiCall = "0xe36ffd17b2661eb57144ceaef942d95295e637f0"
	if (allPoolsConfig[poolAddress].airdropToken) {
		rewardTokenFromMultiCall = allPoolsConfig[poolAddress].airdropToken
	}
	let rewardsFromMultiCall = 0
	if (poolInfo.lpTokenId === "FilDA-Airdrop") {
		rewardTokenFromMultiCall = pickFromMultiCallResults("rewardToken")// || await noMintRewardPoolContract.methods.rewardToken().call()
		rewardsFromMultiCall = pickFromMultiCallResults("rewards")// || await noMintRewardPoolContract.methods.rewards(connectedAddress).call()
	}

	let shareholderDetailFromMultiCall = null
	let durationFromMultiCall = 0
	if (poolInfo.isAirdrop) {
		shareholderDetailFromMultiCall = FetchData.pickFromMultiCallResults("getShareholderDetail", multiCallResults)
		durationFromMultiCall = pickFromMultiCallResults("duration")
	}

	let poolInfoFromMultiCall = null
	let userInfoFromMultiCall = null
	let totalAllocPointFromMultiCall = 0
	let mdxPerBlockFromMultiCall = 0
	// let DOGPerBlockFromMultiCall = 0
	let nameFromMultiCall = ""
	let totalSupplyFromMultiCall = 0
	let periodFinishFromMultiCall = 0
	let rewardRateFromMultiCall = 0
	let governanceFromMultiCall = 0
	let withdrawPeriodFromMultiCall = 0
	let get365EarnedByPidFromMultiCall = 0
	let pendingFromMultiCall = 0
	let pendingDOGFromMultiCall = 0
	// let lpAllocPointsFromMultiCall = 0
	if (poolInfo.isShortcut) {
		poolInfoFromMultiCall = pickFromMultiCallResults("poolInfo") || await noMintRewardPoolContract.methods.poolInfo(poolInfo.indexOfPool).call()
		userInfoFromMultiCall = pickFromMultiCallResults("userInfo") || await noMintRewardPoolContract.methods.userInfo(poolInfo.indexOfPool, connectedAddress).call()

		if (poolInfo.fromDogswap) {
			get365EarnedByPidFromMultiCall = pickFromMultiCallResults("get365EarnedByPid") //|| await noMintRewardPoolContract.methods.get365EarnedByPid(poolInfo.indexOfPool).call()
			pendingDOGFromMultiCall = pickFromMultiCallResults("pendingDOG") //|| await noMintRewardPoolContract.methods.pendingDOG(poolInfo.indexOfPool, connectedAddress).call()
			// lpAllocPointsFromMultiCall = pickFromMultiCallResults("lpAllocPoints") || await noMintRewardPoolContract.methods.lpAllocPoints().call()
			// DOGPerBlockFromMultiCall = pickFromMultiCallResults("DOGPerBlock") || await noMintRewardPoolContract.methods.DOGPerBlock().call()
		} else {
			pendingFromMultiCall = pickFromMultiCallResults("pending") //|| await noMintRewardPoolContract.methods.pending(poolInfo.indexOfPool, connectedAddress).call()
			totalAllocPointFromMultiCall = pickFromMultiCallResults("totalAllocPoint") //|| await noMintRewardPoolContract.methods.totalAllocPoint().call()
			mdxPerBlockFromMultiCall = pickFromMultiCallResults("mdxPerBlock") //|| await noMintRewardPoolContract.methods.mdxPerBlock().call()
		}
	} else {
		nameFromMultiCall = pickFromMultiCallResults("name") //|| await noMintRewardPoolContract.methods.name().call()
		totalSupplyFromMultiCall = pickFromMultiCallResults("totalSupply") //|| await noMintRewardPoolContract.methods.totalSupply().call()
		periodFinishFromMultiCall = pickFromMultiCallResults("periodFinish") //|| await noMintRewardPoolContract.methods.periodFinish().call()
		rewardRateFromMultiCall = pickFromMultiCallResults("rewardRate") //|| await noMintRewardPoolContract.methods.rewardRate().call()
		governanceFromMultiCall = pickFromMultiCallResults("governance") //|| await noMintRewardPoolContract.methods.governance().call()
		withdrawPeriodFromMultiCall = pickFromMultiCallResults("withdrawPeriod") //|| await noMintRewardPoolContract.methods.withdrawPeriod().call()
	}

	let earnedFromMultiCall = 0
	if (!poolInfo.isShortcut) {
		earnedFromMultiCall = pickFromMultiCallResults("earned") //|| await noMintRewardPoolContract.methods.earned(connectedAddress).call()
	}

	let lp_name = ""
	let lp_decimals = 0
	let lp_totalSupply = 0
	let lp_balanceOf = 0
	let lp_allowance = 0
	if (poolInfo.lpToken || poolInfo.isShortcut) {
		lp_name = pickFromMultiCallResults("name", true)
		lp_decimals = pickFromMultiCallResults("decimals", true)
		lp_totalSupply = pickFromMultiCallResults("totalSupply", true)
		lp_balanceOf = pickFromMultiCallResults("balanceOf", true)
		lp_allowance = pickFromMultiCallResults("allowance", true)
	}

	if (!isShortcut) {
		poolInfo.name = nameFromMultiCall
	} else {
		poolInfo.name = allPoolsConfig[poolAddress].lpTokenName
	}

	if (allPoolsConfig[poolAddress].lpTokenId === 'FilDA-Airdrop') {
		// FilDA Supernode voting rewards
		poolInfo.rewardTokenName = allPoolsConfig[poolAddress].lpTokenName
		poolInfo.rewardTokenSymbol = poolInfo.rewardTokenName
		poolInfo.lpTokenSymbol = poolInfo.rewardTokenName
		const defaultLogo = getLogo(poolInfo.rewardTokenSymbol)
		poolInfo.rewardTokenLogo = defaultLogo
		poolInfo.uiRewardTokenLogo = getLogo('FILDAV2')
		poolInfo.lpTokenLogo = getLogo("default")
		// multicall is not used here because such method cannot be called via smart contract
		const redpacketBalance = await noMintRewardPoolContract.methods.earned(connectedAddress).call()
		poolInfo.redpacketBalance = redpacketBalance
		poolInfo.redpacketBalanceFormatted = (poolInfo.redpacketBalance / Math.pow(10, 18))
		poolInfo.rewardsBalance = poolInfo.redpacketBalance
		poolInfo.rewardsBalanceFormatted = poolInfo.redpacketBalanceFormatted
		poolInfo.totalRedpacket = totalSupplyFromMultiCall / Math.pow(10, 18)

		const periodFinish = periodFinishFromMultiCall
		poolInfo.rewardTokenTokenContract = new web3.eth.Contract(erc20ABI, rewardTokenFromMultiCall)
		const totalRedpacketLeft = await poolInfo.rewardTokenTokenContract.methods.balanceOf(poolAddress).call()
		poolInfo.totalRedpacketLeft = totalRedpacketLeft / Math.pow(10, 18)
		poolInfo.withdrawPeriod = 0

		const now = Math.round((new Date()).getTime() / 1000)
		poolInfo.redpacketActive = periodFinish > now

		// poolInfo.rewardAPR = 0
		poolInfo.rewardsRedeemed = rewardsFromMultiCall / Math.pow(10, 18)

		return poolInfo
	}

	if (allPoolsConfig[poolAddress].isAirdrop) {
		// DAO Rewards
		poolInfo.rewardTokenName = allPoolsConfig[poolAddress].lpTokenName
		poolInfo.rewardTokenSymbol = poolInfo.rewardTokenName
		poolInfo.lpTokenSymbol = poolInfo.rewardTokenName
		poolInfo.rewardTokenLogo = getLogo(poolInfo.rewardTokenSymbol)
		poolInfo.uiRewardTokenLogo = getLogo('FILDAV2')
		poolInfo.lpTokenLogo = getLogo('')
		const rewardsBalance = earnedFromMultiCall
		const totalRewards = totalSupplyFromMultiCall
		const periodFinish = parseInt(periodFinishFromMultiCall);
		const [totalRewardsLeft, daoPoolContract] =
			await Promise.all([
				web3.eth.getBalance(poolAddress),
				new web3.eth.Contract(noMintRewardPoolABI, '0x73CB0A55Be009B30e63aD5830c85813414c66367')
			])
		poolInfo.rewardsBalance = rewardsBalance
		poolInfo.rewardsBalanceFormatted = (poolInfo.rewardsBalance / Math.pow(10, 18))
		poolInfo.totalRewards = totalRewards
		poolInfo.totalRewards = poolInfo.totalRewards / Math.pow(10, 18)
		poolInfo.totalRewardsLeft = totalRewardsLeft
		if (poolInfo.rewardTokenSymbol != "HT") {
			const rewardToken = new web3.eth.Contract(erc20ABI, allPoolsConfig[poolAddress].airdropToken)
			poolInfo.totalRewardsLeft = await rewardToken.methods.balanceOf(poolAddress).call()
		}
		poolInfo.totalRewardsLeft = poolInfo.totalRewardsLeft / Math.pow(10, 18)
		poolInfo.withdrawPeriod = 0

		let now = Math.round((new Date()).getTime() / 1000)
		poolInfo.rewardsActive = periodFinish > now

		if (shareholderDetailFromMultiCall) {
			const sharedBlockNumber = shareholderDetailFromMultiCall[1].toNumber();
			const duration = parseInt(durationFromMultiCall);
			if (sharedBlockNumber > (periodFinish - duration) && sharedBlockNumber <= periodFinish) {
				poolInfo.rewardsRedeemed = shareholderDetailFromMultiCall[0].shiftedBy(-18)
			} else {
				poolInfo.rewardsRedeemed = 0
			}
		}

		if (!poolInfo.rewardsActive) {
			poolInfo.rewardsRedeemed = 0
			poolInfo.rewardAPR = 0
		}
		if (poolInfo.rewardTokenSymbol != 'HT') poolInfo.rewardAPR = undefined
		return poolInfo
	}

	//fetching LP Token Info
	if (!isShortcut) {
		poolInfo.lpTokenAddress = await noMintRewardPoolContract.methods.lpToken().call()
	} else {
		poolInfo.lpTokenAddress = poolInfo.address
	}

	poolInfo.lpTokenContract = await new web3.eth.Contract(erc20ABI, poolInfo.lpTokenAddress)

	poolInfo.lpTokenName = allPoolsConfig[poolAddress].lpTokenName
	if (allPoolsConfig[poolAddress].uiLpTokenName) {
		poolInfo.uiLpTokenName = allPoolsConfig[poolAddress].uiLpTokenName
	}
	poolInfo.lpTokenSymbol = allPoolsConfig[poolAddress].lpTokenSymbol
	poolInfo.lpTokenId = allPoolsConfig[poolAddress].lpTokenId
	poolInfo.disabled = !!allPoolsConfig[poolAddress].disabled
	poolInfo.hasLockPeriod = allPoolsConfig[poolAddress].hasLockPeriod
	if (poolInfo.lpTokenName !== 'FilDA') poolInfo.name = poolInfo.lpTokenName

	//Important: We are reading the lpTokenName and lpTokenSymbol from hard coded values from config file.
	// The following code will make them read from the pool manager
	// poolInfo.lpTokenNameFromContract = await poolInfo.lpTokenContract.methods.name().call()
	// poolInfo.lpTokenSymbolFromContract = await poolInfo.lpTokenContract.methods.symbol().call()

	poolInfo.lpTokenLogo = getLogo(poolInfo.lpTokenId)
	if (poolInfo.uiLpTokenId) {
		poolInfo.uiLpTokenLogo = getLogo(poolInfo.uiLpTokenId)
	}

	let lpTokenTotalSupply, lpTokendecimals, lpTokenStakedTotalSupply, lpTokenStakedBalance, lpTokenWalletBalance, allowance, periodFinish, fildaPrice

	let LPTokenFromLocalStorage
	if (LPTokens) {
		LPTokenFromLocalStorage = LPTokens[poolInfo.lpTokenAddress]
	} else {
		LPTokens = {}
	}

	if (LPTokenFromLocalStorage) {
		lpTokendecimals = LPTokenFromLocalStorage.decimals
		// lpTokenTotalSupply = LPTokenFromLocalStorage.totalSupply
	} else {
		lpTokendecimals = lp_decimals//await poolInfo.lpTokenContract.methods.decimals().call()
		// lpTokenTotalSupply = await poolInfo.lpTokenContract.methods.totalSupply().call()

		LPTokenFromLocalStorage = {}
		LPTokenFromLocalStorage.decimals = lpTokendecimals
		// LPTokenFromLocalStorage.totalSupply = lpTokenTotalSupply
		LPTokens[poolInfo.lpTokenAddress] = LPTokenFromLocalStorage
		localStorage.setItem("LPTokens", JSON.stringify(LPTokens))
	}

	lpTokenTotalSupply = lp_totalSupply //await poolInfo.lpTokenContract.methods.totalSupply().call()
	lpTokenWalletBalance = lp_balanceOf //await poolInfo.lpTokenContract.methods.balanceOf(connectedAddress).call()
	allowance = lp_allowance //await poolInfo.lpTokenContract.methods.allowance(connectedAddress, poolAddress).call()
	if (!isShortcut) {
		lpTokenStakedTotalSupply = totalSupplyFromMultiCall
		lpTokenStakedBalance = await noMintRewardPoolContract.methods.balanceOf(connectedAddress).call()
		periodFinish = periodFinishFromMultiCall
	} else {
		lpTokenStakedTotalSupply = poolInfoFromMultiCall.totalAmount
		lpTokenStakedBalance = userInfoFromMultiCall.amount

		periodFinish = 999999999999999
	}
	fildaPrice = await FetchData.getFildaPrice(web3, networkType)

	poolInfo.lpTokendecimals = lpTokendecimals
	poolInfo.lpTokenStakedTotalSupply = lpTokenStakedTotalSupply
	poolInfo.lpTokenStakedTotalSupplyFormatted = poolInfo.lpTokenStakedTotalSupply / Math.pow(10, parseInt(lpTokendecimals))
	poolInfo.lpTokenStakedBalance = lpTokenStakedBalance
	const num = (lpTokenStakedBalance / Math.pow(10, parseInt(lpTokendecimals)))
	poolInfo.lpTokenStakedBalanceFormatted = num
	poolInfo.lpTokenStakedBalanceUsd = BigNumber(num).times(fildaPrice).toNumber()
	poolInfo.lpTokenWalletBalance = lpTokenWalletBalance
	poolInfo.lpTokenWalletBalanceFormatted = new BigNumber(poolInfo.lpTokenWalletBalance).dividedBy(new BigNumber(10).pow(lpTokendecimals)).toFixed()
	poolInfo.periodFinish = periodFinish
	
	let poolAmount
	let dogPrice = 0
	if (poolInfo.lpTokenName.toLowerCase() === poolInfo.lpTokenId.toLowerCase()) {
		poolAmount = poolInfo.lpTokenStakedTotalSupplyFormatted
	} else {
		const uniswapContractABI = isShortcut ? Config.mdex.hecoPoolPair : Config.uniswapPair.ABI
		const uniswapContract = new web3.eth.Contract(uniswapContractABI, poolInfo.lpTokenAddress)

		const reserves = await uniswapContract.methods.getReserves().call()

		let totalLpToken = (reserves.reserve1 ?? reserves._reserve1) * 2 / Math.pow(10, lpTokendecimals)

		poolAmount = totalLpToken * poolInfo.lpTokenStakedTotalSupply / lpTokenTotalSupply

		dogPrice = await FetchData.getDogPrice(web3, fildaPrice)
	}

	poolInfo.lpTokenApproved = Number(allowance) !== 0

	let now = Math.round((new Date()).getTime() / 1000)
	//fetching rewards token info
	let rewardRate = 0
	if (periodFinish > now) {
		if (!isShortcut) {
			rewardRate = rewardRateFromMultiCall
		} else {
			rewardRate = 100
		}
	}

	if (poolInfo.lpPairAddress) {
		let priceInFilDA = await FetchData.getPriceInFilDA(web3, poolInfo.lpPairAddress);
		rewardRate = priceInFilDA.multipliedBy(rewardRate).dividedBy(Math.pow(10,18))
	}

	let rewardRatioPerDay = BigNumber(0)
	if (!isShortcut) {
		if (poolAmount > 0) rewardRatioPerDay = BigNumber(rewardRate).multipliedBy(3600).multipliedBy(24).dividedBy(Math.pow(10,18)).dividedBy(poolAmount)
		let rewardRatioPerYear = rewardRatioPerDay.multipliedBy(365).multipliedBy(100)
		poolInfo.rewardAPR = rewardRatioPerYear.toFixed(2)
		poolInfo.rewardAPY = rewardRatioPerDay.isZero() ? BigNumber(0).toFixed(2) : BigNumber(rewardRatioPerDay.plus(1)).pow(365).multipliedBy(100).toFixed(2)
	} else {
		const allocPoint = poolInfoFromMultiCall.allocPoint

		if (poolInfo.fromDogswap) {
			poolInfo.coefficient = allPoolsConfig[poolAddress].coefficient

			const tvl = new BigNumber(poolAmount).multipliedBy(fildaPrice)

			// totalAllocPoint = await noMintRewardPoolContract.methods.lpAllocPoints().call()
			// mdxPerBlock = await noMintRewardPoolContract.methods.DOGPerBlock().call()
			// rewardRatioPerDay = new BigNumber(mdxPerBlock).dividedBy(1e18).multipliedBy(28800).multipliedBy(allocPoint).dividedBy(totalAllocPoint).multipliedBy(dogPrice).dividedBy(tvl)
			// poolInfo.rewardAPY = rewardRatioPerDay.plus(1).pow(365).multipliedBy(100).toFixed(2)

			const earned365 = get365EarnedByPidFromMultiCall
			poolInfo.rewardAPY = new BigNumber(earned365).dividedBy(1e18).multipliedBy(poolInfo.coefficient).multipliedBy(dogPrice).dividedBy(tvl).multipliedBy(100).toFixed(2)
		} else {
			rewardRatioPerDay = new BigNumber(mdxPerBlockFromMultiCall).dividedBy(Math.pow(10, 18)).multipliedBy(28800).multipliedBy(allocPoint).dividedBy(totalAllocPointFromMultiCall).dividedBy(poolAmount).dividedBy(fildaPrice)
			poolInfo.rewardAPY = rewardRatioPerDay.plus(1).pow(365).multipliedBy(100).toFixed(2)
		}
	}
	poolInfo.poolAmountInUsd = poolAmount * fildaPrice
	poolInfo.poolAmountInUsd = poolInfo.poolAmountInUsd.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

	if (!isShortcut) {
		poolInfo.rewardTokenAddress = rewardTokenFromMultiCall
	} else {
		poolInfo.reward = allPoolsConfig[poolAddress].reward
		poolInfo.rewardTokenAddress = poolInfo.reward.address
	}

	let rewardTokenName, rewardTokenSymbol, rewardDecimals, rewardsEarnedBalance, adminAddress, withdrawPeriod

	let rewardToken
	if (rewardTokens) {
		rewardToken = rewardTokens[poolInfo.rewardTokenAddress]
	} else {
		rewardTokens = {}
	}

	if (rewardToken) {
		rewardTokenName = rewardToken.name
		rewardTokenSymbol = rewardToken.symbol
		rewardDecimals = rewardToken.decimals
	} else {
		poolInfo.rewardTokenContract = await new web3.eth.Contract(erc20ABI, poolInfo.rewardTokenAddress)

		rewardTokenName = await poolInfo.rewardTokenContract.methods.name().call()
		rewardTokenSymbol = await poolInfo.rewardTokenContract.methods.symbol().call()
		rewardDecimals = await poolInfo.rewardTokenContract.methods.decimals().call()
		rewardToken = {}
		rewardToken.name = rewardTokenName
		rewardToken.symbol = rewardTokenSymbol
		rewardToken.decimals = rewardDecimals

		rewardTokens[poolInfo.rewardTokenAddress] = rewardToken
		localStorage.setItem("rewardTokens", JSON.stringify(rewardTokens))
	}
	if (!isShortcut) {
		rewardsEarnedBalance = earnedFromMultiCall
		adminAddress = governanceFromMultiCall
		withdrawPeriod = withdrawPeriodFromMultiCall
	} else {
		if (poolInfo.fromDogswap) {
			rewardsEarnedBalance = pendingDOGFromMultiCall
		} else {
			rewardsEarnedBalance = pendingFromMultiCall
		}
		adminAddress = Config.mdex.factory
		withdrawPeriod = 0
	}

	poolInfo.rewardTokenName = rewardTokenName
	poolInfo.rewardTokenSymbol = rewardTokenSymbol
	poolInfo.rewardTokenLogo = getLogo(poolInfo.rewardTokenSymbol)
	poolInfo.uiRewardTokenLogo = getLogo(poolInfo.rewardTokenSymbol === 'MDX'? poolInfo.rewardTokenSymbol : 'FILDAV2')
	poolInfo.rewardsEarnedBalance = rewardsEarnedBalance
	poolInfo.rewardsEarnedBalanceFormatted = (poolInfo.rewardsEarnedBalance / Math.pow(10, parseInt(rewardDecimals)))

	poolInfo.adminAddress = adminAddress
	poolInfo.stakeActive = periodFinish > now
	poolInfo.withdrawPeriod = parseInt(withdrawPeriod)

	//hardcoding the pooladdress to skip the pool that was wrongly deployed
	if (Number(poolInfo.withdrawPeriod) !== 0) {
		let withdrawTime, lockedBalance
		if (!isShortcut) {
			withdrawTime = await noMintRewardPoolContract.methods.withdrawTime().call({ from: connectedAddress })
			lockedBalance = await noMintRewardPoolContract.methods.lockedBalance().call({ from: connectedAddress })
		} else {
			withdrawTime = 0
			lockedBalance = 0
		}
		poolInfo.withdrawTime = withdrawTime
		poolInfo.withdrawWaitTime = parseInt(poolInfo.withdrawTime) - moment().unix()
		poolInfo.lockedBalance = lockedBalance
		const num = (poolInfo.lockedBalance / Math.pow(10, parseInt(lpTokendecimals)))
		poolInfo.lockedBalanceFormatted = num
		poolInfo.lockedBalanceFormattedUsd = BigNumber(num).times(fildaPrice).toNumber()
	}

	//log.info(poolInfo.name, poolInfo.lpTokenStakedBalance)
	return poolInfo
}

const gasLimit = 800000

const approveERC20 = async (web3, connectedAddress, networkType, tokenAddress, spendAddress) => {

	const erc20ABI = Config.erc20.ABI
	let contract = await new web3.eth.Contract(erc20ABI, tokenAddress);

	const maxApproval = BigNumber(2).pow(256).minus(1).toFixed(0);

	const gasPrice = await web3.eth.getGasPrice()

	log.info(`Initiating approval: ${spendAddress} ${tokenAddress} ${maxApproval}`)
	return await contract.methods.approve(spendAddress, maxApproval.toString()).send({
		from: connectedAddress,
		gasLimit: web3.utils.toHex(gasLimit),     // posted at compound.finance/developers#gas-costs
		gasPrice: web3.utils.toHex(gasPrice) // use ethgasstation.info (mainnet only)
	})
}

const getPoolContract = async (web3, networkType, poolAddress, pool) => {
	const noMintRewardPoolABI = pool ? pool.ABI : Config.noMintRewardPool.ABI;
	let noMintRewardPoolContract = await new web3.eth.Contract(noMintRewardPoolABI, pool ? pool.address : poolAddress)
	return noMintRewardPoolContract
}

const getRawValue = async (web3, networkType, tokenContract, tokenSymbol, value) => {
	let decimals = 18
	if (!CoreData.isNativeToken(tokenSymbol, networkType)) {
		decimals = await tokenContract.methods.decimals().call()
	}

	BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
	const rawValue = BigNumber(value).multipliedBy(BigNumber(10).pow(parseInt(decimals))).toFixed(0)
	return rawValue
}

export default {
	getPoolList: getPoolList,
	getPoolInfo: getPoolInfo,
	approveERC20: approveERC20,
	getPoolContract: getPoolContract,
	getRawValue: getRawValue
}
