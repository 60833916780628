import React, { useContext, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import CoreMethod from '../../methods/CoreMethod'
import LoadingIcon from '../../images/loanloading.svg'
import { useTranslation } from 'react-i18next'
import CheckIcon from '../../images/checkyellow.svg'
import ErrorIcon from '../../images/error.svg'
import CoreData from '../../methods/CoreData'
import BigNumber from 'bignumber.js'
import log from '../../utils/logger'
import { NetworkTypeContext, WalletAddressContext, Web3Context } from '../../context'
import FetchData from '../../methods/FetchData'
import { sleep } from '@src/utils/promise'

export default function RepayModal(props) {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { networkType } = useContext(NetworkTypeContext)
    const { web3 } = useContext(Web3Context)

    const styles = props.styles
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState('')
    const [invalidInput, setInvalidInput] = useState(false)
    const [overPay, setOverPay] = useState(false)
    const [loading, setLoading] = useState(false)
    const [lowBalance, setLowBalance] = useState(false)
    const [repayCompleted, setRepayCompleted] = useState(false)
    const [repayFailed, setRepayFailed] = useState(false)
    const [txnHash, setTxnHash] = useState('')
    const [needsFurtherApproval, setNeedsFurtherApproval] = useState(false)
    const [allowanceFormatted, setAllowanceFormatted] = useState('')
    const [isMax, setIsMax] = useState(false)
    const [firstApproval, setFirstApproval] = useState(true)

    const validateInput = async (value) => {
        setIsMax(false)
        let newValue = new BigNumber(value)

        if (newValue.isNegative()) {
            newValue = newValue.absoluteValue()
            setInputValue(newValue.toString())
            setInvalidInput(false)
        } else {
            setInputValue(value)
            const IS_NUMERIC = /^(\d+(\.\d+)?)?$/
            const isNumeric = (str) => IS_NUMERIC.test(str)
            setInvalidInput(!isNumeric(value))
        }

        const txnValue = await getRawValue(
            CoreData.fixedNaN(newValue)
        )
        if (parseFloat(props.data.loanBalance) < parseFloat(txnValue)) {
            setOverPay(true)
        } else {
            setOverPay(false)
        }

        if (parseFloat(props.data.walletBalance) < parseFloat(txnValue)) {
            setLowBalance(true)
        } else {
            setLowBalance(false)
        }
    }

    const getRawValue = async (value) => {
        return CoreData.getRawValue(web3, networkType, props.data.symbol, value)
    }

    const handleClose = async () => {
        setInputValue('')
        setInvalidInput(false)
        setOverPay(false)
        setLoading(false)
        setLowBalance(false)
        setRepayCompleted(false)
        setRepayFailed(false)
        setTxnHash('')
        setNeedsFurtherApproval(false)
        setIsMax(false)
        setFirstApproval(true)
        props.handleClose()
    }

    const verifyFurtherApproval = async (txnValue) => {
        if (!CoreData.isNativeToken(props.data.symbol, networkType)) {
            const { allowance, allowanceFormatted } = await FetchData.getAccountAllowance(web3, connectedAddress, networkType, props.data)
            if (allowance && allowance < Number(txnValue)) {
                setNeedsFurtherApproval(true)
                setAllowanceFormatted(allowanceFormatted)
                return Promise.resolve(false)
            }
    
        }

        setNeedsFurtherApproval(false)
        setAllowanceFormatted('')
        return Promise.resolve(true)
    }

    const setMaximum = async (fullPayOnly) => {
        let loanRepayAmount = props.data.loanBalanceFormatted;
        if (BigNumber(props.data.walletBalance).gte(BigNumber(props.data.loanBalance))) {
            // max all
            setIsMax(true)
        } else {
            // max part
            setIsMax(false)
            loanRepayAmount = props.data.walletBalanceFormatted;
        }

        setInputValue(loanRepayAmount.toString())
        return loanRepayAmount.toString();
    }



    const handleRepay = async () => {
        const gtagParams = {
            url: window.location.href,
            symbol: props.data.symbol,
        }
        setFirstApproval(true)

        if(lowBalance || overPay || invalidInput) return;

        setLoading(true)
        let repayAmount = isMax ? new BigNumber(2).pow(256).minus(1).toString(10) : await getRawValue(inputValue);

        const isValidAllowance = await verifyFurtherApproval(repayAmount)
        if (!isValidAllowance) {
            setLoading(false)
            return
        }

        const contract = await CoreData.getQTokenContract(web3, networkType, props.data.symbol)
        const gasInfo = await CoreData.getGasInfo(web3)
        const isNativeToken = CoreData.isNativeToken(props.data.symbol, networkType)
        let repayBorrow
        
        if (isNativeToken) {
            if (isMax) {
                repayAmount = BigNumber(props.data.loanBalance).multipliedBy(1.00001).plus(1).toFixed(0)
                const maximillionContract = await CoreData.getMaximillion(web3, networkType)
                repayBorrow = await maximillionContract.methods.repayBehalf(connectedAddress)
            } else {
                repayBorrow = await contract.methods.repayBorrow()
            }
        } else {
            repayBorrow = await contract.methods.repayBorrow(web3.utils.toBN(repayAmount))
        }

        let sendParams = {
            from: connectedAddress,
            gasLimit: web3.utils.toHex(gasInfo.gasLimit),     
            gasPrice: web3.utils.toHex(gasInfo.gasPrice),
        }

        isNativeToken && (sendParams.value = repayAmount)


        repayBorrow.send(sendParams).on('transactionHash', function (hash) {
            setTxnHash(hash)
        })
        .then(response => {
            if (response.events.Failure) {
                window.gtag('event', 'repay', {
                    ...gtagParams,
                    error: 'response event fail'
                })
                setRepayFailed(true)
            } else {
                window.gtag('event', 'repay', gtagParams)
                setRepayCompleted(true)
            }
            setLoading(false)
        })
        .catch(error => {
            if (error.code === 4001) {
                window.gtag('event', 'repay', {
                    ...gtagParams,
                    error: 'user rejected'
                })
                handleClose()
            } else {
                window.gtag('event', 'repay', {
                    ...gtagParams,
                    error: 'other error'
                })
                setRepayFailed(true)
            }
        })
    }

    const handleApprove = async (first = true) => {
        setLoading(true)
        await CoreMethod.approveERC20(web3, connectedAddress, networkType, props.data)
            .then(async response => {
                if (response) {
                    if (first) {
                        setFirstApproval(false)
                    } else {
                        const txnValue = await getRawValue(inputValue)
                        await sleep()
                        const isValidAllowance = await verifyFurtherApproval(txnValue)
                        isValidAllowance && setFirstApproval(false)
                    }
                }
                setLoading(false)
            })
            .catch(error => {
                if (error.code === 4001) {
                    handleClose()
                }
            })
    }

    const RepayButton =
        (invalidInput || overPay || lowBalance || isNaN(parseFloat(inputValue)) || parseFloat(inputValue) <= 0) ?
            <Button variant="loans" disabled>{t('Common.Repay')}</Button> :
            <Button variant="loans" onClick={() => handleRepay()}>{t('Common.Repay')}</Button>


    const ModalLoading =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={LoadingIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="loading"
                    />
                    {
                        txnHash &&
                        <a style={{ color: '#BDB780' }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
                    }
                </div>
            </Modal.Body>
        </div>


    const ModalRepayForm = 
        <div>
            <Modal.Body>
                <Form >
                    <div className={styles.formGroup}>
                        <Form.Group controlId="formDeposit">
                            <Form.Control
                                className={styles.txnValue}
                                type="number"
                                placeholder={"0.00 " + props.data.symbol}
                                autoComplete="off"
                                value={inputValue}
                                min="0"
                                onChange={e => validateInput(e.target.value)} />
                            <Button variant="secondary" onClick={() => setMaximum(false)}>{t('Common.Maximum')}</Button>
                        </Form.Group>
                    </div>
                </Form>
                {invalidInput ? (
                    <div className={styles.txnError}>{t('RepayModal.InvalidInput')}</div>
                ) : overPay ? (
                    <div className={styles.txnError}>{t('RepayModal.OverPayError')}</div>
                ) : lowBalance ? (
                    <div className={styles.txnError}>{t('RepayModal.LowBalanceError')}</div>
                ) : ''}
                <div className={styles.footerInfo}>
                    <div>{t('Common.WalletBalance')}</div>
                    <div className={styles.tokenBalance}>
                        {`${parseFloat(props.data.walletBalanceFormatted).toFixed(4)} ${props.data.symbol}`}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {RepayButton}
                <Button variant="cancel" onClick={handleClose}>{t('Common.Cancel')}</Button>
            </Modal.Footer>
            <div className={styles.footerInfo}>
                <div>{t('RepayModal.LoanAmountDue')}</div>
                <div className={styles.tokenBalance}>
                    {parseFloat(props.data.loanBalanceFormatted).toFixed(2) + ' ' + props.data.symbol}
                </div>
            </div>
            <div className={styles.detailedBalance}>
                {t('RepayModal.DetailedAmountDue')} {parseFloat(props.data.loanBalanceFormatted) + ' ' + props.data.symbol}
            </div>
        </div>

    const ModalApprovalRequest =
        <div>
            <Modal.Body>
                <div className={styles.approvalMsg}>{t('Common.ApprovalMsg')}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="loans" onClick={() => handleApprove(true)}>{t('Common.Approve')}</Button>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Cancel')}</Button>
            </Modal.Footer>
        </div>

    const ModalFurtherApprovalRequest =
        <div>
            <Modal.Body>
                <div className="alertMsg">
                    {t('Common.FurtherApprovalMsg', { type: t('Common.RepayAmount'), inputValue, allowanceFormatted })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="savings" onClick={() => handleApprove(false)}>{t('Common.FurtherApprove')}</Button>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Cancel')}</Button>
            </Modal.Footer>
        </div>

    const TxnSuccessMsg =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={CheckIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="error"
                    />
                </div>
                <div className={styles.approvalMsg}>{t('RepayModal.SuccessMsg')}</div>
                <a className={styles.borrowLink} style={{ color: "#BDB780" }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Close')}</Button>
            </Modal.Footer>
        </div>

    const TxnErrorMsg =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={ErrorIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="error"
                    />
                </div>
                <div className={styles.approvalMsg}>{t('RepayModal.ErrorMsg')}</div>
                <a className={styles.borrowLink} style={{ color: "#BDB780" }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Close')}</Button>
            </Modal.Footer>
        </div>

    const needsApproval = props.data && !(props.data.approved) && firstApproval
    const ModalLoaded = needsApproval ? ModalApprovalRequest : ModalRepayForm
    const ModalRendered = loading ? ModalLoading : needsFurtherApproval ? ModalFurtherApprovalRequest : ModalLoaded

    return (
        <Modal
            show={props.show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            className={styles.txnModal}
            centered
            animation={false}>
            <Modal.Header closeButton>
                <img
                    src={props.data.logo}
                    width="auto"
                    height="36px"
                    className="d-inline-block align-top"
                    alt={`${props.data.name} Logo`}
                />
                <div className={styles.assetName}>{props.data.name}</div>
                {repayCompleted || repayFailed ? '' : <div className={styles.txnTypeDesc}>
                    {t('Common.RepayAssets')}
                </div>}
            </Modal.Header>
            {
                repayCompleted ? TxnSuccessMsg : repayFailed ? TxnErrorMsg : ModalRendered
            }
        </Modal>
    )
}
