import FetchData from '../methods/FetchData'
import Config from '../utils/config'
import { Multicall } from 'ethereum-multicall'
import BigNumber from 'bignumber.js'

export async function BoosterDataFetcher(web3, networkType, connectedAddress, markets) {
	let data = null
	let currentBlockNumber = 0
	const fildaPrice = await FetchData.getFildaPrice(web3, networkType)

	const marketsArr = Object.values(markets).filter(item => {
		return (typeof item) === "object"
	})

	const computeTVL = allPools => {
		allPools.forEach(pool => {
			pool.TVL = pool.getDepositTotal.shiftedBy(-pool.baseToken.decimals).plus(pool.totalAmount.shiftedBy(-pool.decimals).multipliedBy(pool.getBaseTokenPerCToken.shiftedBy(-pool.baseToken.decimals)))
		})
	}

	const computerAPY = pool => {
		return pool.APY = pool.loanAPY
		// const rate = pool.allocPoint.dividedBy(pool.totalAllocPoint)
		// const blocksReward = pool.rewardPerBlock
		// const perBlock = blocksReward.shiftedBy(-Config.booster.rewardToken.decimals).multipliedBy(rate)
		// const daily = perBlock.multipliedBy(28800)
		// const dailyValue = daily.multipliedBy(fildaPrice)
		// const poolAmount = pool.TVL
		// const poolValue = poolAmount.multipliedBy(pool.price)
		// pool.APY = dailyValue.dividedBy(poolValue).plus(1).pow(365).minus(1)
	}

	const parseData = allResults => {
		const allData = Object.values(allResults)

		const poolData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("pool_") === 0
		})

		const tokenData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("token_") === 0
		})

		const rewardsBooData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("rewards_BOO_") === 0
		})

		for (let i = 0; i < poolData.length; i++) {
			const resultFromMultiCall = poolData[i]
			let result = resultFromMultiCall.callsReturnContext

			const thePool = Config.booster.pools[resultFromMultiCall.originalContractCallContext.context.index]
			thePool.from = "booster"

			if (marketsArr && marketsArr.length > 0) {
				const theMarket = marketsArr.find(market => {
					return market.symbol === thePool.baseToken.symbol
				})

				if (theMarket) {
					thePool.price = theMarket.price
					thePool.loanAPY = theMarket.loanAPY
					thePool.loanMintAPY = theMarket.loanMintAPY

					if (!thePool.baseToken.borrowingDisabled) {
						const tempObject = { ...theMarket }
						Object.assign(tempObject, thePool.baseToken)
						thePool.baseToken = tempObject
					}

					thePool.baseToken.symbol = thePool.name
				}
			}

			thePool.getDepositTotal = FetchData.pickFromMultiCallResults("getDepositTotal", result)
			thePool.getBaseTokenPerCToken = FetchData.pickFromMultiCallResults("getBaseTokenPerCToken", result)
			thePool.totalSupply = FetchData.pickFromMultiCallResults("totalSupply", result)
			thePool.allowance = FetchData.pickFromMultiCallResults("allowance", result)
			thePool.balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)

			result = tokenData[i].callsReturnContext
			thePool.baseToken.balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)
			thePool.baseToken.allowance = FetchData.pickFromMultiCallResults("allowance", result)

			result = rewardsBooData[i].callsReturnContext
			thePool.pendingRewardsBoo = FetchData.pickFromMultiCallResults("pendingRewards", result)
			thePool.totalAllocPoint = FetchData.pickFromMultiCallResults("totalAllocPoint", result)
			thePool.rewardPerBlock = FetchData.pickFromMultiCallResults("rewardPerBlock", result)
			const poolInfo = FetchData.pickFromMultiCallResults("poolInfo", result)
			thePool.allocPoint = poolInfo[1]
			thePool.totalAmount = poolInfo[4]

			const userInfo = FetchData.pickFromMultiCallResults("userInfo", result)
			thePool.stakedLPTokenAmount = userInfo[0]
			thePool.stakedTokenAmount = thePool.getBaseTokenPerCToken.shiftedBy(-thePool.baseToken.decimals).multipliedBy(thePool.stakedLPTokenAmount.shiftedBy(-thePool.decimals))
			thePool.totalValue = thePool.stakedTokenAmount.multipliedBy(thePool.price)
		}

		const poolsData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("pools_") === 0
		})

		for (let i = 0; i < poolsData.length; i++) {
			const resultFromMultiCall = poolsData[i]
			const result = resultFromMultiCall.callsReturnContext

			const thePool = Config.booster.pools[resultFromMultiCall.originalContractCallContext.context.index]

			thePool.pendingRewards = FetchData.pickFromMultiCallResults("pendingRewards", result)

			const poolInfo = FetchData.pickFromMultiCallResults("poolInfo", result)
			thePool.lastRewardBlock = poolInfo[4]
			thePool.lastRewardClosed = poolInfo[5]
			thePool.poolTotalRewards = poolInfo[6]
			thePool.accRewardPerShare = poolInfo[7]
		}

		computeTVL(Config.booster.pools)

		for (let i = 0; i < Config.booster.pools.length; i++) {
			computerAPY(Config.booster.pools[i])
		}

		data = Config.booster.pools
	}

	const fetchData = async _ => {
		const multiCall = new Multicall({
			multicallCustomContractAddress: Config.multiCall.network[networkType].address,
			web3Instance: web3
		})

		const contractCallContext = []
		Config.booster.pools.forEach((pool, index) => {
			contractCallContext.push({
				reference: "pool_" + pool.symbol,
				contractAddress: pool.address,
				abi: pool.ABI,
				calls: [
					{ reference: "getDepositTotal", methodName: "getDepositTotal" },
					{ reference: "getBaseTokenPerCToken", methodName: "getBaseTokenPerCToken" },
					{ reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] },
					{ reference: "totalSupply", methodName: "totalSupply" },
					{ reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, Config.booster.staking.address] }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "token_" + pool.symbol,
				contractAddress: pool.baseToken.address,
				abi: pool.baseToken.ABI,
				calls: [
					{ reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] },
					{ reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, pool.address] }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "pools_" + pool.symbol,
				contractAddress: Config.booster.actionPools.address,
				abi: Config.booster.actionPools.ABI,
				calls: [
					{ reference: "poolInfo", methodName: "poolInfo", methodParameters: [pool.indexOfPool] },
					{ reference: "pendingRewards", methodName: "pendingRewards", methodParameters: [pool.indexOfPool, connectedAddress] }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "rewards_BOO_" + pool.symbol,
				contractAddress: Config.booster.staking.address,
				abi: Config.booster.staking.ABI,
				calls: [
					{ reference: "userInfo", methodName: "userInfo", methodParameters: [pool.indexOfPool, connectedAddress] },
					{ reference: "poolInfo", methodName: "poolInfo", methodParameters: [pool.indexOfPool] },
					{ reference: "totalAllocPoint", methodName: "totalAllocPoint" },
					{ reference: "rewardPerBlock", methodName: "rewardPerBlock" },
					{ reference: "pendingRewards", methodName: "pendingRewards", methodParameters: [pool.indexOfPool, connectedAddress] }
				],
				context: { index }
			})
		})

		const resultSet = await multiCall.call(contractCallContext)
		currentBlockNumber = resultSet.blockNumber
		await parseData(resultSet.results)
	}

	await fetchData()
	return data
}