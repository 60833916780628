import BigNumber from 'bignumber.js'
import FetchData from '../methods/FetchData'
import Config from '../utils/config'
import { Multicall } from 'ethereum-multicall'
import { formatBigNumberWithDecimals } from '../utils/numberFormat'
import contractABI from '../utils/contractABI.json'

let HTPrice = 0
let DEPPrice = 0

export async function DepthFiDataFetcher(web3, networkType, connectedAddress, markets) {
	let data = null
	let currentBlockNumber = 0

	const marketsArr = Object.values(markets).filter(item => {
		return (typeof item) === "object"
	})

	const computeThePoolData = async (index, allPools) => {
		const thePool = allPools[index]
		thePool.deposit.tokens[0].balances = thePool.deposit.tokens[0].balances.multipliedBy(2)
		thePool.deposit.tokens[1].balances = thePool.deposit.tokens[1].balances.multipliedBy(2)

		const poolContract = new web3.eth.Contract(thePool.deposit.ABI, thePool.deposit.address)
		thePool.deposit.tokens[0].reserves = await poolContract.methods.get_dy(1, 0, thePool.deposit.tokens[1].balances.toFixed(0)).call()
		thePool.deposit.tokens[1].reserves = await poolContract.methods.get_dy(0, 1, thePool.deposit.tokens[0].balances.toFixed(0)).call()

		if (!thePool.deposit.tokens[0].percent || !thePool.deposit.tokens[1].percent) {
			const reserves0 = new BigNumber(thePool.deposit.tokens[0].reserves).shiftedBy(-thePool.deposit.tokens[0].decimals)
			const reserves1 = new BigNumber(thePool.deposit.tokens[1].reserves).shiftedBy(-thePool.deposit.tokens[1].decimals)
			const sum = reserves0.plus(reserves1)

			thePool.deposit.tokens[0].percent = reserves0.dividedBy(sum)
			thePool.deposit.tokens[1].percent = reserves1.dividedBy(sum)
		}
	}

	const getDepositedWithLPToken = async (amountLPToken, thePool) => {
		if (!thePool.deposit.tokens[0].percent || !thePool.deposit.tokens[1].percent) {
			return
		}

		if (amountLPToken === "0") {
			thePool.deposit.tokens[0].deposited = new BigNumber(0);
			thePool.deposit.tokens[0].depositedValue = new BigNumber(0);
			thePool.deposit.tokens[1].deposited = new BigNumber(0);
			thePool.deposit.tokens[1].depositedValue = new BigNumber(0);
			thePool.deposit.totalDepositedValue = new BigNumber(0);
			return;
		}

		const depositContract = new web3.eth.Contract(thePool.deposit.ABI, thePool.deposit.address)
		let totalDeposited = await depositContract.methods.calc_withdraw_one_coin(amountLPToken, 0).call()
		thePool.deposit.tokens[0].deposited = thePool.deposit.tokens[0].percent.multipliedBy(totalDeposited)
		thePool.deposit.tokens[0].depositedValue = thePool.deposit.tokens[0].deposited.shiftedBy(-thePool.deposit.tokens[0].decimals).multipliedBy(thePool.deposit.tokens[0].price)

		totalDeposited = await depositContract.methods.calc_withdraw_one_coin(amountLPToken, 1).call()
		thePool.deposit.tokens[1].deposited = thePool.deposit.tokens[1].percent.multipliedBy(totalDeposited)
		thePool.deposit.tokens[1].depositedValue = thePool.deposit.tokens[1].deposited.shiftedBy(-thePool.deposit.tokens[1].decimals).multipliedBy(thePool.deposit.tokens[1].price)

		thePool.deposit.totalDepositedValue = thePool.deposit.tokens[0].depositedValue.plus(thePool.deposit.tokens[1].depositedValue)
		if (thePool.deposit.totalDepositedValue.comparedTo(0.0000001) < 0) {
			thePool.deposit.totalDepositedValue = new BigNumber(0)
		}
	}

	const parseData = async allResults => {
		const allData = Object.values(allResults)

		if (HTPrice === 0) {
			HTPrice = marketsArr.find(item => {
				return item.symbol === "HT"
			}).price
		}

		if (DEPPrice === 0) {
			const DEPPriceData = allData.filter(item => {
				return item.originalContractCallContext.reference.indexOf("DEPPrice") === 0
			})
			const getAmountsOut = FetchData.pickFromMultiCallResults("getAmountsOut", DEPPriceData[0].callsReturnContext)
			DEPPrice = getAmountsOut[0].dividedBy(getAmountsOut[1]).multipliedBy(HTPrice)
		}

		const checkLoanAPYBySymbol = symbol => {
			const theToken = marketsArr.find(item => {
				return item.symbol === symbol
			})
			return theToken.savingsMintAPY
		}

		const getPiggyPerBlock = async pool => {
			const contract = new web3.eth.Contract(pool.staking.poolContract.ABI, pool.staking.poolContract.address)
			const arg = parseInt((currentBlockNumber - pool.staking.startBlock.toNumber()) / 28800)
			pool.piggyPerBlock = await contract.methods.getPiggyPerBlock(arg).call()
		}

		const stakingData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("staking_") === 0
		})

		for (let i = 0; i < stakingData.length; i++) {
			const resultFromMultiCall = stakingData[i]
			const result = resultFromMultiCall.callsReturnContext

			const thePool = Config.depthFi.pools[resultFromMultiCall.originalContractCallContext.context.index]
			thePool.from = "depthFi"

			if (marketsArr && marketsArr.length > 0) {
				Object.assign(thePool.deposit.tokens[0], marketsArr.find(market => {
					return market.symbol === thePool.deposit.tokens[0].symbol
				}))

				Object.assign(thePool.deposit.tokens[1], marketsArr.find(market => {
					return market.symbol === thePool.deposit.tokens[1].symbol
				}))
			}

			thePool.totalAllocPoint = FetchData.pickFromMultiCallResults("totalAllocPoint", result)
			thePool.staking.startBlock = FetchData.pickFromMultiCallResults("startBlock", result)
			await getPiggyPerBlock(thePool)
			// thePool.piggyPerBlock = FetchData.pickFromMultiCallResults("getPiggyPerBlock", result)

			const poolInfo = FetchData.pickFromMultiCallResults("poolInfo", result)
			thePool.allocPoint = poolInfo[1]
			thePool.staking.APY = thePool.allocPoint.dividedBy(thePool.totalAllocPoint).multipliedBy(100)
			thePool.dailyOutput = thePool.allocPoint.dividedBy(thePool.totalAllocPoint).multipliedBy(thePool.piggyPerBlock).multipliedBy(28800)
			thePool.dailyOutputString = formatBigNumberWithDecimals(thePool.dailyOutput, thePool.staking.reward.decimals, 0)
			thePool.totalDeposit = poolInfo[4]
			thePool.totalLocked = formatBigNumberWithDecimals(thePool.totalDeposit, thePool.staking.LPToken.decimals, 2)
			// thePool.RewardValueDaily = thePool.dailyOutput.shiftedBy(-thePool.staking.LPToken.decimals).multipliedBy(MDX价格)

			thePool.staking.poolContract.pendingPiggy = FetchData.pickFromMultiCallResults("pendingPiggy", result)

			const userInfo = FetchData.pickFromMultiCallResults("userInfo", result)
			thePool.staking.amount = userInfo[0]
			thePool.staking.rewardDebt = userInfo[1]
			thePool.staking.pendingReward = userInfo[2]
		}

		const poolData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("curve") === 0
		})

		const depositTokenData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("depositToken_0_") === 0
		})

		const coinData0 = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("coin0_") === 0
		})

		const coinData1 = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("coin1_") === 0
		})

		for (let i = 0; i < depositTokenData.length; i++) {
			const resultFromMultiCall = depositTokenData[i]
			const result = resultFromMultiCall.callsReturnContext

			const thePool = Config.depthFi.pools[resultFromMultiCall.originalContractCallContext.context.index]

			thePool.deposit.tokens[0].allowance = FetchData.pickFromMultiCallResults("allowance", result)
			if (thePool.deposit.tokens[0].allowance.comparedTo(0) > 0) {
				thePool.deposit.tokens[0].approved = true
			} else {
				thePool.deposit.tokens[0].approved = false
			}

			thePool.deposit.tokens[0].balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)
			thePool.deposit.tokens[0].formatedBalance = formatBigNumberWithDecimals(thePool.deposit.tokens[0].balanceOf, thePool.deposit.tokens[0].decimals)

			thePool.deposit.tokens[0].balances = FetchData.pickFromMultiCallResults("balances", poolData[i].callsReturnContext, "balances0")

			thePool.deposit.fee = FetchData.pickFromMultiCallResults("fee", poolData[i].callsReturnContext)

			let rate = FetchData.pickFromMultiCallResults("exchangeRateStored", coinData0[i].callsReturnContext)
			thePool.deposit.tokens[0].lendingRate = rate.shiftedBy(-thePool.deposit.tokens[0].decimals)

			rate = FetchData.pickFromMultiCallResults("exchangeRateStored", coinData1[i].callsReturnContext)
			thePool.deposit.tokens[1].lendingRate = rate.shiftedBy(-thePool.deposit.tokens[1].decimals)
		}

		const depositTokenData_1 = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("depositToken_1_") === 0
		})

		for (let i = 0; i < depositTokenData_1.length; i++) {
			const resultFromMultiCall = depositTokenData_1[i]
			const result = resultFromMultiCall.callsReturnContext

			const thePool = Config.depthFi.pools[resultFromMultiCall.originalContractCallContext.context.index]

			thePool.deposit.tokens[1].allowance = FetchData.pickFromMultiCallResults("allowance", result)
			if (thePool.deposit.tokens[1].allowance.comparedTo(0) > 0) {
				thePool.deposit.tokens[1].approved = true
			} else {
				thePool.deposit.tokens[1].approved = false
			}

			thePool.deposit.tokens[1].balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)
			thePool.deposit.tokens[1].formatedBalance = formatBigNumberWithDecimals(thePool.deposit.tokens[1].balanceOf, thePool.deposit.tokens[1].decimals)

			thePool.deposit.tokens[1].balances = FetchData.pickFromMultiCallResults("balances", poolData[i].callsReturnContext, "balances1")
		}

		await computeThePoolData(0, Config.depthFi.pools)

		const stakingTokenData = allData.filter(item => {
			return item.originalContractCallContext.reference.indexOf("stakingToken_") === 0
		})

		for (let i = 0; i < stakingTokenData.length; i++) {
			const resultFromMultiCall = stakingTokenData[i]
			const result = resultFromMultiCall.callsReturnContext
			const thePool = Config.depthFi.pools[resultFromMultiCall.originalContractCallContext.context.index]

			thePool.staking.LPToken.allowance = FetchData.pickFromMultiCallResults("allowance", result)
			thePool.staking.LPToken.allowanceForWithdrawal = FetchData.pickFromMultiCallResults("allowance", result, "allowanceForWithdrawal")
			if (thePool.staking.LPToken.allowance.comparedTo(0) > 0) {
				thePool.staking.LPToken.approved = true
			} else {
				thePool.staking.LPToken.approved = false
			}

			thePool.staking.LPToken.balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)
			thePool.staking.LPToken.balanceOfPool = FetchData.pickFromMultiCallResults("balanceOf", result, "balanceOfPool")

			thePool.APY = thePool.dailyOutput.dividedBy(thePool.staking.LPToken.balanceOfPool).multipliedBy(DEPPrice).plus(1).pow(365).minus(1).multipliedBy(100).plus(checkLoanAPYBySymbol(thePool.deposit.tokens[1].symbol))

			await getDepositedWithLPToken(thePool.staking.LPToken.balanceOf.toFixed(0), thePool)
		}

		data = Config.depthFi.pools
	}

	const fetchData = async _ => {
		const multiCall = new Multicall({
			multicallCustomContractAddress: Config.multiCall.network[networkType].address,
			web3Instance: web3
		})

		const contractCallContext = []
		Config.depthFi.pools.forEach((pool, index) => {
			contractCallContext.push({
				reference: "staking_" + pool.name,
				contractAddress: pool.staking.poolContract.address,
				abi: pool.staking.poolContract.ABI,
				calls: [
					{ reference: "poolInfo", methodName: "poolInfo", methodParameters: [pool.staking.LPToken.indexOfPool] },
					{ reference: "totalAllocPoint", methodName: "totalAllocPoint" },
					// { reference: "getPiggyPerBlock", methodName: "getPiggyPerBlock", methodParameters: [pool.staking.LPToken.indexOfPool] },
					{ reference: "pendingPiggy", methodName: "pendingPiggy", methodParameters: [pool.staking.LPToken.indexOfPool, connectedAddress] },
					{ reference: "userInfo", methodName: "userInfo", methodParameters: [pool.staking.LPToken.indexOfPool, connectedAddress] },
					{ reference: "startBlock", methodName: "startBlock" }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "depositToken_0_" + pool.name,
				contractAddress: pool.deposit.tokens[0].address,
				abi: pool.deposit.tokens[0].ABI,
				calls: [
					{ reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, pool.deposit.address] },
					{ reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "depositToken_1_" + pool.name,
				contractAddress: pool.deposit.tokens[1].address,
				abi: pool.deposit.tokens[1].ABI,
				calls: [
					{ reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, pool.deposit.address] },
					{ reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "curve",
				contractAddress: pool.deposit.address,
				abi: pool.deposit.ABI,
				calls: [
					{ reference: "balances0", methodName: "balances", methodParameters: [pool.deposit.tokens[0].indexOfPool] },
					{ reference: "balances1", methodName: "balances", methodParameters: [pool.deposit.tokens[1].indexOfPool] },
					{ reference: "fee", methodName: "fee" }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "stakingToken_" + pool.name,
				contractAddress: pool.staking.LPToken.address,
				abi: pool.staking.LPToken.ABI,
				calls: [
					{ reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, pool.staking.poolContract.address] },
					{ reference: "allowanceForWithdrawal", methodName: "allowance", methodParameters: [connectedAddress, pool.deposit.address] },
					{ reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] },
					{ reference: "balanceOfPool", methodName: "balanceOf", methodParameters: [pool.staking.poolContract.address] }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "coin0_" + pool.name,
				contractAddress: pool.deposit.coins[0].address,
				abi: pool.deposit.coins[0].ABI,
				calls: [
					{ reference: "exchangeRateStored", methodName: "exchangeRateStored" }
					// { reference: "supplyRatePerBlock", methodName: "supplyRatePerBlock" },
					// { reference: "accrualBlockNumber", methodName: "accrualBlockNumber" }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "coin1_" + pool.name,
				contractAddress: pool.deposit.coins[1].address,
				abi: pool.deposit.coins[1].ABI,
				calls: [
					{ reference: "exchangeRateStored", methodName: "exchangeRateStored" }
					// { reference: "supplyRatePerBlock", methodName: "supplyRatePerBlock" },
					// { reference: "accrualBlockNumber", methodName: "accrualBlockNumber" }
				],
				context: { index }
			})

			contractCallContext.push({
				reference: "DEPPrice",
				contractAddress: Config.MDEXRouter,
				abi: contractABI.MDEXRouterABI,
				calls: [
					{ reference: "getAmountsOut", methodName: "getAmountsOut", methodParameters: ["1000000000000000000", ["0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F", "0x48C859531254F25e57D1C1A8E030Ef0B1c895c27"]] }
				]
			})
		})


		const response = await multiCall.call(contractCallContext)
		currentBlockNumber = response.blockNumber
		await parseData(response.results)
	}

	await fetchData()
	return data
}