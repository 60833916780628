import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import SavingsLoadingIcon from '../../../images/savingsloading.svg'
import CheckIcon from '../../../images/check.svg'
import ErrorIcon from '../../../images/error.svg'
import { useTranslation } from 'react-i18next'
import StakingData from '../../../methods/StakingData'
import CoreData from '../../../methods/CoreData'
import BigNumber from 'bignumber.js'
import { NetworkTypeContext, WalletAddressContext, Web3Context } from '../../../context'
import { formatBigNumberWithDecimals } from '../../../utils/numberFormat'
import styles from './GeneralInvestModal.module.scss'
import borrowModalStyles from "../../Markets.module.scss"
import config from '../../../utils/config'
import BorrowModal from '../BorrowModal'

let isBorrowing = false
const amountOptions = [0.25, 0.5, 0.75, 1]
const theInputs = []
let thePreviousStep = -1

export default function GeneralInvestModal(props) {
	const { connectedAddress } = useContext(WalletAddressContext)
	const { networkType } = useContext(NetworkTypeContext)
	const { web3 } = useContext(Web3Context)

	const { t } = useTranslation();
	const [inputValue, setInputValue] = useState([])
	const [lowBalance, setLowBalance] = useState([])
	const [loading, setLoading] = useState(false)
	const [stakeCompleted, setStakeCompleted] = useState(false)
	const [stakeFailed, setStakeFailed] = useState(false)
	const [txnHash, setTxnHash] = useState('')
	const [steps, setSteps] = useState([])
	const [currentStep, setCurrentStep] = useState(0)
	const [showBorrowModal, setShowBorrowModal] = useState(false)
	const [tokenToBorrow, setTokenToBorrow] = useState(props.data)
	const [currentAmountOption, setCurrentAmountOption] = useState(-1)

	const formatDepthFiData = _ => {
		setSteps([
			{
				id: 0,
				title: t("Common.Deposit"),
				borrowable: true,
				contract: props.data.deposit,
				token: props.data.deposit.tokens[0]
			},
			{
				id: 1,
				title: t("Common.Deposit"),
				borrowable: true,
				contract: props.data.deposit,
				token: props.data.deposit.tokens[1]
			},
			{
				id: 2,
				title: t("Stake.Stake"),
				contract: props.data.staking.poolContract,
				token: props.data.staking.LPToken
			}
		])
		setLowBalance([true, true, true])
	}

	const formatPilotData = _ => {
		setSteps([
			{
				id: 1,
				title: t("Common.Deposit"),
				borrowable: true,
				contract: {
					address: (props.data.pToken.symbol === "pHUSD" && !props.data.isHiddenFromInvest) ? props.data.pToken.address : config.pilot.address,
					ABI: (props.data.pToken.symbol === "pHUSD" && !props.data.isHiddenFromInvest) ? props.data.pToken.ABI : config.pilot.ABI
				},
				token: props.data.token
			},
			{
				id: 2,
				title: t("Stake.Stake"),
				contract: props.data.stakingRewards,
				token: (props.data.pToken.symbol === "pHUSD" && !props.data.isHiddenFromInvest) ? {
					...props.data.pToken,
					address: props.data.pToken.realToken.address,
					ABI: props.data.pToken.realToken.ABI
				} : props.data.pToken
			}
		])
		setLowBalance([true, true])
	}

	const formatSoloData = _ => {
		setSteps([
			{
				id: 1,
				title: t("Common.Deposit"),
				borrowable: true,
				contract: config.solo.poolContract,
				token: props.data
			}
		])
		setLowBalance([true])
	}

	const formatBoosterData = _ => {
		setSteps([
			{
				id: 1,
				title: t("Common.Deposit"),
				borrowable: !props.data.baseToken.borrowingDisabled,
				contract: props.data,
				token: props.data.baseToken
			},
			{
				id: 2,
				title: t("Stake.Stake"),
				contract: config.booster.staking,
				token: props.data
			}
		])
		setLowBalance([true, true])
	}

	useEffect(_ => {
		if (!props.data) {
			return
		}

		switch (props.from) {
			case "depthFi":
				formatDepthFiData()
				break

			case "pilot":
				formatPilotData()
				break

			case "solo":
				formatSoloData()
				break

			case "booster":
				formatBoosterData()
				break

			default:
				setSteps([])
				break
		}

		setCurrentStep(0)

		window.document.body.style.setProperty("--theme-color", props.themeColor)
	}, [props.data])

	useEffect(_ => {
		if ((props.from === "depthFi" || props.from === "booster") && steps.length > 0) {
			return setTokenToBorrow(steps[currentStep].token)
		} else {
			return setTokenToBorrow(props.data)
		}
	}, [steps, currentStep, props.data, props.from])

	const getAmountInputColor = _ => {
		let color = "white"
		switch (props.from) {
			case "pilot":
				color = "#f1eef9"
				break

			case "solo":
				color = "#fbf2f5"
				break

			case "depthFi":
				color = "#edf3fe"
				break

			case "booster":
				color = "#fff3eb"
				break

			default:
				color = "white"
				break
		}
		return color
	}

	const validateInput = event => {
		const value = event.target.value

		if (!isNaN(value)) {
			const numberValue = Number(value).toFixed(steps[currentStep].token.decimals)
			inputValue[currentStep] = numberValue
			setInputValue([...inputValue])

			if (steps[currentStep].token.balanceOf.shiftedBy(-steps[currentStep].token.decimals).comparedTo(numberValue) < 0) {
				lowBalance[currentStep] = true
			} else {
				lowBalance[currentStep] = false
			}
			setLowBalance([...lowBalance])
		}
	}

	const handleBorrow = _ => {
		setShowBorrowModal(true)
		isBorrowing = true

		return props.handleBorrowBegin()
	}

	// const handleNext = _ => {
	// 	if (currentStep < (steps.length - 1)) {
	// 		setCurrentStep(currentStep + 1)
	// 	}

	// 	document.getElementById("theGeneralInvextModalDOM").parentElement.scrollBy(0, 258)
	// }

	const handleClose = async () => {
		setInputValue([])
		setLowBalance([])
		setLoading(false)
		setStakeCompleted(false)
		setStakeFailed(false)
		setTxnHash('')
		setCurrentStep(0)
		setCurrentAmountOption(-1)
		thePreviousStep = -1
		// setSteps([])
		props.handleClose('stake')
	}

	const handleCloseBorrowModal = _ => {
		setShowBorrowModal(false)

		if (isBorrowing) {
			props.handleBorrowDone()
			isBorrowing = false
		}
	}

	const handleBorrowDone = borrowValue => {
		steps[currentStep].token.balanceOf = steps[currentStep].token.balanceOf.plus(borrowValue)
		isBorrowing = false
		return props.handleBorrowDone()
	}

	const handleStake = async () => {
		if (steps.length === 0) {
			return
		}

		setLoading(true);

		const poolContract = new web3.eth.Contract(steps[currentStep].contract.ABI, steps[currentStep].contract.address)

		let func = null
		let args = []
		let amount = "0"

		switch (props.from) {
			case "depthFi":
				if (currentStep === 0 || currentStep === 1) {
					func = poolContract.methods.add_liquidity

					if (currentStep === 0) {
						args = [[new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1), 0], 0]
					}

					if (currentStep === 1) {
						args = [[0, new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1)], 0]
					}
				} else {
					func = poolContract.methods.stake
					args = [steps[currentStep].token.indexOfPool, new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1)]
				}
				break

			case "pilot":
				if (currentStep === 0) {
					if (props.data.pToken.symbol === "pHUSD" && !props.data.isHiddenFromInvest) {
						func = poolContract.methods.deposit
						args = [new BigNumber(inputValue[currentStep]).shiftedBy(steps[currentStep].token.decimals).toFixed(0, 1)]
					} else if (props.data.coin_symbol === "HT") {
						func = poolContract.methods.deposit
						args = ["0x0000000000000000000000000000000000000000", 0]
						amount = new BigNumber(inputValue[currentStep]).shiftedBy(steps[currentStep].token.decimals).toFixed(0, 1)
					} else {
						func = poolContract.methods.deposit
						args = [steps[currentStep].token.address, new BigNumber(inputValue[currentStep]).shiftedBy(steps[currentStep].token.decimals).toFixed(0, 1)]
					}
				}

				if (currentStep === 1) {
					if (props.data.pToken.symbol === "pHUSD" && !props.data.isHiddenFromInvest) {
						func = poolContract.methods.stake
						args = [new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1)]
					} else {
						func = poolContract.methods.stake
						args = [new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1)]
					}
				}
				break

			case "solo":
				if (currentStep === 0) {
					func = poolContract.methods.deposit
					args = [steps[currentStep].token.indexOfPool, new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1)]
				}
				break

			case "booster":
				if (currentStep === 0) {
					func = poolContract.methods.deposit
					args = [new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1)]
				}

				if (currentStep === 1) {
					func = poolContract.methods.deposit
					args = [steps[currentStep].token.indexOfPool, new BigNumber(inputValue[currentStep]).multipliedBy(new BigNumber(10).pow(steps[currentStep].token.decimals)).toFixed(0, 1)]
				}

				break

			default:
				return
		}

		func(...args).send({
			from: connectedAddress,
			value: amount
		}).on('transactionHash', function (hash) {
			setTxnHash(hash)
		}).on('confirmation', function (confirmationNumber, receipt) {
			//
		}).on('receipt', function (receipt) {
			if (receipt) {
				setTimeout(() => {
					if (props.tokenUpdater) {
						if (props.from === "depthFi") {
							setTokenToBorrow(steps[currentStep].token)
						}

						props.tokenUpdater(_ => {
							setLoading(false)

							if (currentStep < steps.length - 1) {
								setCurrentStep(currentStep + 1)
							} else {
								setStakeCompleted(true)
								setCurrentStep(0)
							}
						})
					} else {
						setLoading(false)

						if (currentStep < steps.length - 1) {
							setCurrentStep(currentStep + 1)
						} else {
							setStakeCompleted(true)
							setCurrentStep(0)
						}
					}
				}, 3000);
			} else {
				setStakeFailed(true)
			}
		}).on('error', function (error, receipt) {
			if (error.code === 4001) {
				handleClose()
			} else {
				setStakeFailed(true)
			}
		})
	}

	const handleApprove = async event => {
		if (steps.length <= 0) {
			return
		}

		setLoading(true)

		const index = event.currentTarget.id
		let args = [steps[index].token.address, steps[index].contract.address]

		await StakingData.approveERC20(web3, connectedAddress, networkType, ...args).then(response => {
			if (response) {
				steps[index].token.approved = true
				setLoading(false)
			}
		}).catch(error => {
			if (error.code === 4001) {
				handleClose()
			}
		})
	}

	const changeInputValue = event => {
		const newStep = parseInt(event.currentTarget.dataset.indexofstep)

		if (steps[newStep].token.balanceOf.comparedTo(0) <= 0) {
			return
		}

		if (thePreviousStep !== newStep) {
			setInputValue([])

			if (thePreviousStep >= 0) {
				theInputs[thePreviousStep].value = ""
			}

			if (newStep >= 0 && theInputs.length > 0) {
				theInputs[newStep].value = ""
			}

			setCurrentStep(newStep)
			thePreviousStep = newStep

			lowBalance[newStep] = false
			setLowBalance([...lowBalance])
		}

		const index = parseInt(event.currentTarget.id)
		setCurrentAmountOption(index)

		const value = amountOptions[index]
		if (steps.length > 0) {
			inputValue[newStep] = steps[newStep].token.balanceOf.multipliedBy(value).shiftedBy(-parseInt(steps[newStep].token.decimals)).toFixed()
			theInputs[newStep].value = inputValue[newStep]
			setInputValue([...inputValue])
		}
	}

	//UI Rendering

	const DepositButton = index => {
		const isDisabled = lowBalance[index] || isNaN(parseFloat(inputValue[currentStep])) || parseFloat(inputValue[currentStep]) <= 0
		return (<button
			className={styles.buttonsInInvestModal}
			style={{
				backgroundColor: isDisabled ? getAmountInputColor() : "",
				color: isDisabled ? props.themeColor : ""
			}}
			disabled={isDisabled}
			onClick={handleStake}>{steps[index].title}&nbsp;{steps.length > 0 && steps[index].token.symbol}</button>)
	}


	const ModalLoading =
		<div>
			<Modal.Body>
				<div className={styles.loadingContainer}>
					<img
						src={SavingsLoadingIcon}
						width="auto"
						height="60px"
						// className="d-inline-block align-top"
						alt="loading"
					/>
					{
						txnHash == null ? '' :
							<a className={styles.textLink} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank" rel="noopener noreferrer">{t('Common.ViewTxnOnExplorer')}</a>
					}

				</div>
			</Modal.Body>
		</div>


	const ModalApprovalRequest = index => (<div>
		<Modal.Body>
			<div className={styles.approvalMsg}>
				{t("Common.ApprovalMsg")}
			</div>
		</Modal.Body>
		<Modal.Footer className={styles.buttonsBlock}>
			<button id={index} className={styles.buttonsInInvestModal} onClick={handleApprove}>{t('Common.Approve')}&nbsp;{steps.length > 0 && steps[index].token.symbol}</button>
			<button className={styles.buttonsInInvestModal} onClick={handleClose}>{t('Common.Cancel')}</button>
		</Modal.Footer>
	</div>)

	const ModalDepositForm = index => (<div className={styles.sectionContainer}>
		<div className={styles.footerInfo}>
			{steps.length > 0 && (
				<>
					<div>{t('Common.WalletBalance')}</div>
					<div className={styles.tokenBalance}>
						{formatBigNumberWithDecimals(steps[index].token.balanceOf, steps[index].token.decimals) + ' ' + steps[index].token.symbol}
					</div>
				</>
			)}
		</div>

		<input
			ref={node => { theInputs[index] = node }}
			type="number"
			placeholder={"0.00 " + (steps.length > 0 ? steps[index].token.symbol : "")}
			autoComplete="off"
			className={styles.amountInput}
			style={{
				backgroundColor: getAmountInputColor()
			}}
			onInput={validateInput} />

		<div className={styles.amountOptionGroup}>
			{amountOptions.map((option, indexOfOptions) => (<div
				key={indexOfOptions}
				className={indexOfOptions === currentAmountOption && currentStep === index ? styles.amountOptionSelected : styles.amountOption}
				style={{
					backgroundColor: indexOfOptions === currentAmountOption && currentStep === index ? props.themeColor : getAmountInputColor()
				}}
				onClick={changeInputValue}
				data-indexofstep={index}
				id={indexOfOptions}>{(option * 100).toFixed()}%</div>))}
		</div>

		<div className={styles.buttonsBlock}>
			{steps[index].borrowable && (<>
				<button className={styles.buttonsInInvestModal} onClick={handleBorrow}>{t('Common.Borrow')}</button>
			</>)}

			{DepositButton(index)}

			{/* {(index < (steps.length - 1) && steps[index + 1].token.balanceOf && steps[index + 1].token.balanceOf.comparedTo(0) > 0) && (<>
				<button className={styles.buttonsInInvestModal} onClick={handleNext}>{t('Common.Next')}</button>
			</>)} */}

			<button className={styles.buttonsInInvestModal} onClick={handleClose} style={{
				backgroundColor: getAmountInputColor(),
				color: props.themeColor
			}}>{t('Common.Cancel')}</button>
		</div>
	</div>)

	const TxnSuccessMsg =
		<div>
			<Modal.Body>
				<div className={styles.loadingContainer}>
					<img
						src={CheckIcon}
						width="auto"
						height="60px"
						className="d-inline-block align-top"
						alt="error"
					/>
				</div>
				<div className={styles.approvalMsg}>{t('DepositModal.SuccessMsg')}</div>
				<a href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank" rel="noopener noreferrer">{t('Common.ViewTxnOnExplorer')}</a>
			</Modal.Body>
			<Modal.Footer>
				<button className={styles.buttonsInInvestModal} onClick={handleClose}>{t('Common.Close')}</button>
			</Modal.Footer>
		</div>

	const TxnErrorMsg =
		<div>
			<Modal.Body>
				<div className={styles.loadingContainer}>
					<img
						src={ErrorIcon}
						width="auto"
						height="60px"
						className="d-inline-block align-top"
						alt="error"
					/>
				</div>
				<div className={styles.approvalMsg}>{t('DepositModal.ErrorMsg')}</div>
				<a href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank" rel="noopener noreferrer">{t('Common.ViewTxnOnExplorer')}</a>
			</Modal.Body>
			<Modal.Footer>
				<button className={styles.buttonsInInvestModal} onClick={handleClose}>{t('Common.Close')}</button>
			</Modal.Footer>
		</div>

	const renderSection = index => {
		const theStep = steps[index]
		return <>
			<div className={styles.sectionTitle}>{theStep.title}&nbsp;{theStep.token.symbol}</div>
			{((theStep.token.allowance && theStep.token.allowance.comparedTo(0) > 0) || theStep.token.approved) ? ModalDepositForm(index) : ModalApprovalRequest(index)}
		</>
	}

	const renderSections = steps.map((step, index) => {
		// const isDisabled = index !== currentStep && steps[index].token.balanceOf.comparedTo(0) <= 0
		const isDisabled = false
		return (<div key={index}>
			<div
				className={styles.section} key={step.id} style={{
					pointerEvents: isDisabled ? "none" : "auto",
					filter: isDisabled ? "grayscale(100%)" : "none",
					opacity: isDisabled ? 0.3 : 1,
					boxShadow: index === currentStep ? "0px 20px 20px " + getAmountInputColor() : "none	"
				}}>{renderSection(index)}</div>
			<div style={{
				display: index === (steps.length - 1) ? "none" : "",
				textAlign: "center"
			}}>↡</div>
		</div>)
	})

	const ModalLoaded = steps && steps.length > 0 && renderSections
	const ModalRendered = loading ? ModalLoading : ModalLoaded

	return (<>
		<Modal
			id="theGeneralInvextModalDOM"
			show={props.show}
			onHide={handleClose}
			aria-labelledby="contained-modal-title-vcenter"
			className={styles.modalContainer}
			centered
			animation={false}>
			<Modal.Header closeButton>
				<img
					src={props.marketLogo ?? props.data.logo}
					width="auto"
					height="36px"
					className="d-inline-block align-top"
					alt="QuickSilver Logo"
				/>

				{/* <div>
					{props.data ? props.data.name : ""}&nbsp;{t('Stake.DepositToStake')}
				</div> */}
			</Modal.Header>

			{/* <Modal.Body>{ModalLoaded}</Modal.Body> */}
			{stakeCompleted ? TxnSuccessMsg : (stakeFailed ? TxnErrorMsg : ModalRendered)}

			<div className={styles.tips}>
				<a href={props.website} target="blank" style={{ color: props.themeColor }}>{t("Invest.risks")}</a>
			</div>
		</Modal>

		{tokenToBorrow && (<BorrowModal
			data={tokenToBorrow}
			accountLiquidityInFiat={props.dataTree.accountLiquidityInFiat}
			totalBorrowLimitFiat={props.dataTree.totalBorrowLimitFiat}
			totalLoanBalance={props.dataTree.totalLoanBalance}
			show={showBorrowModal}
			handleClose={handleCloseBorrowModal}
			callback={handleBorrowDone}
			styles={borrowModalStyles} />)}
	</>)
}
