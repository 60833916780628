
export const noticeList = [
  {
    content: {
      "en": "[NEW Asset] TUSD deposit and borrow is now live",
      "ru": "[NEW Asset] TUSD deposit and borrow is now live",
      "zh-CN": "[市场] FilDA上线TUSD借贷功能"
    },
    // url: 'https://www.163.com/dy/article/GFOLTM7K0512DU6N.html',
  },
  {
    content: {
      "en": "Heco node election: Vote for FilDA, 100% of HT rewards are distributed to voters",
      "ru": "Возвращается 100% дохода узла FilDA, и вы можете получить вознаграждение, проголосовав!",
      "zh-CN": "FilDA节点收益100%返还，投票即可领奖励！"
    },
    // url: 'https://www.163.com/dy/article/GFOLTM7K0512DU6N.html',
  }, 
  {
    content: {
      "en": "ATTENTION: Current market volatility increases loan liquidation risk. Please check your positions",
      "ru": "Волатильность рынка, обратите внимание на риски ликвидации для пользователей депозитов и кредитов",
      "zh-CN": "市场行情波动，请各位存贷用户注意清算风险"
    },
    // url: 'https://www.163.com/dy/article/GFOLTM7K0512DU6N.html'
  }
]
