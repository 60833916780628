import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import BigNumber from 'bignumber.js'
import ContentLoader from 'react-content-loader'
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti'
import { isMobile } from "react-device-detect";
import { useWindowWidth } from '@react-hook/window-size'
import styles from './Pilot.module.scss'

import { NetworkTypeContext, WalletAddressContext, Web3Context } from '../context'
import FetchData from '../methods/FetchData'
import PtdLogo from '../images/logo_ptd.webp'
import Config from '../utils/config'
import { Multicall } from 'ethereum-multicall'
import contractABI from '../utils/contractABI.json'
import GeneralInvestModal from './subComponents/staking/GeneralInvestModal'

function getLocaleString(s) {
    return parseFloat(parseFloat(s).toFixed(2) || 0).toLocaleString()
}

function Pilot(props) {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { networkType } = useContext(NetworkTypeContext)
    const { web3 } = useContext(Web3Context)
    const clientWidth = useWindowWidth()
    const mobileMode = isMobile || clientWidth < 980

    const [loadingMarkets, setLoadingMarkets] = useState(true)
    const [banks, setBanks] = useState()
    const [lossLessMining, setLossLessMining] = useState()
    const [sortField, setSortField] = useState('')
    const [sortDireciton, setSortDirection] = useState(true)
    const [selectedBank, setSelectedBank] = useState()
    const [showInvestModal, setShowInvestModal] = useState(false)
    // const [selectedBankForStaking, setSelectedBankForStaking] = useState()

    const { t } = useTranslation()

    useEffect(() => {
        if (!props.marketsArr) {
            return
        }

        let fetching = false
        async function loadData() {
            if (!fetching && connectedAddress && networkType && networkType !== "unsupported") {
                try {
                    fetching = true
                    const data = await fetch(Config.thirdMarkets["PTD"]["data"])
                    const res = await data.json()
                    const marketsArr = props.marketsArr //FetchData.getNetworkMarkets(networkType)
                    const pilotPools = Config.pilot.stakingRewards.pairs

                    const filteredBanks = {}
                    Object.values(res.banks).forEach(b => {
                        if (pilotPools.findIndex(item => {
                            return item.pool === b.coin_symbol
                        }) === -1) {
                            return
                        }

                        const index = marketsArr.findIndex(e => e.symbol === b.coin_symbol)
                        if (index > -1) {
                            filteredBanks[b.coin_symbol] = {
                                ...b,
                                ...marketsArr[index]
                            }
                            if (selectedBank && selectedBank.coin_symbol === b.coin_symbol) {
                                setSelectedBank(filteredBanks[b.coin_symbol])
                                setShowInvestModal(true)
                            }
                            // if (selectedBankForStaking && selectedBankForStaking.coin_symbol === b.coin_symbol) {
                            //     setSelectedBankForStaking(filteredBanks[b.coin_symbol])
                            // }
                        }

                        const pilotPool = pilotPools.find(pool => {
                            return pool.pool === b.coin_symbol
                        })

                        if (pilotPool) {
                            filteredBanks[b.coin_symbol].token = pilotPool.token
                            filteredBanks[b.coin_symbol].pToken = pilotPool.pToken
                            filteredBanks[b.coin_symbol].stakingRewards = pilotPool.stakingRewards
                        }
                    })

                    setBanks({ ...filteredBanks })
                    setLossLessMining({ ...res.loss_less_mining })
                    setLoadingMarkets(false)
                } catch (e) {
                } finally {
                    fetching = false
                }
            }
        }

        let timer = null
        const updateData = () => {
            loadData()
            timer = setTimeout(updateData, 10000)
        }
        updateData()
        return () => {
            clearTimeout(timer)
            setBanks()
            setLossLessMining()
        }
    }, [connectedAddress, props.marketsArr, networkType])

    useEffect(_ => {
        if (!networkType || networkType === "" || !banks) {
            return
        }

        const allBanks = Object.values(banks)

        const parsePools = async resultsFromMultiCall => {
            const allData = Object.values(resultsFromMultiCall)

            const totalTokenData = allData.filter(data => {
                return data.originalContractCallContext.reference.indexOf("totalToken_") === 0
            })

            for (let i = 0; i < totalTokenData.length; i++) {
                const resultFromMultiCall = totalTokenData[i]
                const result = resultFromMultiCall.callsReturnContext

                const bank = allBanks[resultFromMultiCall.originalContractCallContext.context.index]
                bank.pToken.totalToken = FetchData.pickFromMultiCallResults("totalToken", result)
            }

            const tokenData = allData.filter(data => {
                return data.originalContractCallContext.reference.indexOf("token_") === 0
            })

            for (let i = 0; i < tokenData.length; i++) {
                const resultFromMultiCall = tokenData[i]
                const result = resultFromMultiCall.callsReturnContext

                const bank = allBanks[resultFromMultiCall.originalContractCallContext.context.index]

                bank.token.allowance = FetchData.pickFromMultiCallResults("allowance", result)
                bank.token.balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)
                // bank.token.totalSupply = FetchData.pickFromMultiCallResults("totalSupply", result)
                bank.token.decimals = FetchData.pickFromMultiCallResults("decimals", result)
            }

            const HTBank = allBanks.find(bank => {
                return bank.coin_symbol === "HT"
            })
            HTBank.token.approved = true
            HTBank.token.balanceOf = new BigNumber(await web3.eth.getBalance(connectedAddress))
            HTBank.token.decimals = 18

            let pTokenData = allData.filter(data => {
                return data.originalContractCallContext.reference.indexOf("pToken_") === 0
            })

            const pTokenHUSDData = allData.filter(data => {
                return data.originalContractCallContext.reference.indexOf("pTokenHUSD_") === 0
            })[0].callsReturnContext

            for (let i = 0; i < pTokenData.length; i++) {
                const resultFromMultiCall = pTokenData[i]
                let result = resultFromMultiCall.callsReturnContext

                const bank = allBanks[resultFromMultiCall.originalContractCallContext.context.index]
                if (bank.pToken.symbol === "pHUSD" && pTokenHUSDData) {
                    result = result.concat(pTokenHUSDData)
                }

                bank.pToken.balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)
                bank.pToken.totalSupply = FetchData.pickFromMultiCallResults("totalSupply", result)
                bank.pToken.allowance = FetchData.pickFromMultiCallResults("allowance", result)
            }

            const stakingRewardsData = allData.filter(data => {
                return data.originalContractCallContext.reference.indexOf("stakingRewards_") === 0
            })

            for (let i = 0; i < stakingRewardsData.length; i++) {
                const resultFromMultiCall = stakingRewardsData[i]
                const result = resultFromMultiCall.callsReturnContext

                const bank = allBanks[resultFromMultiCall.originalContractCallContext.context.index]
                bank.stakingRewards.balanceOf = FetchData.pickFromMultiCallResults("balanceOf", result)
            }
        }

        const fetchData = async () => {
            const multiCall = new Multicall({
                multicallCustomContractAddress: Config.multiCall.network[networkType].address,
                web3Instance: web3
            })

            let contractCallContext = []
            for (let i = 0; i < allBanks.length; i++) {
                const bank = allBanks[i]
                let isHT = false
                let contractAddress = "0x0000000000000000000000000000000000000000"

                if (bank.network) {
                    contractAddress = bank.network[networkType].address
                    isHT = false
                } else {
                    isHT = true
                }

                let spender = Config.pilot.address
                if (bank.pToken.symbol === "pHUSD") {
                    spender = bank.pToken.address
                }

                if (!isHT) {
                    contractCallContext.push({
                        reference: "token_" + bank.coin_symbol,
                        contractAddress: contractAddress,
                        abi: contractABI.pTokenABI,
                        calls: [
                            { reference: "balanceOf", methodName: "balanceOf", methodParameters: [connectedAddress] },
                            { reference: "decimals", methodName: "decimals" },
                            { reference: "totalSupply", methodName: "totalSupply" },
                            { reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, spender] },
                        ],
                        context: { index: i }
                    })
                }

                contractCallContext.push({
                    reference: "totalToken_" + bank.coin_symbol,
                    contractAddress: Config.pilot.address,
                    abi: Config.pilot.ABI,
                    calls: [
                        { reference: "totalToken", methodName: "totalToken", methodParameters: [contractAddress] },
                    ],
                    context: { index: i }
                })

                if (bank.pToken.symbol === "pHUSD") {
                    contractCallContext.push({
                        reference: "pToken_" + bank.coin_symbol,
                        contractAddress: bank.pToken.realToken.address,
                        abi: bank.pToken.realToken.ABI,
                        calls: [
                            { reference: "balanceOf_pToken", methodName: "balanceOf", methodParameters: [connectedAddress] }
                        ],
                        context: { index: i }
                    })

                    contractCallContext.push({
                        reference: "pTokenHUSD_" + bank.coin_symbol,
                        contractAddress: bank.pToken.midToken.address,
                        abi: bank.pToken.midToken.ABI,
                        calls: [
                            { reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, bank.stakingRewards.address] },
                            { reference: "totalSupply_pToken", methodName: "totalSupply" }
                        ],
                        context: { index: i }
                    })
                } else {
                    contractCallContext.push({
                        reference: "pToken_" + bank.coin_symbol,
                        contractAddress: bank.pToken.address,
                        abi: bank.pToken.ABI,
                        calls: [
                            { reference: "balanceOf_pToken", methodName: "balanceOf", methodParameters: [connectedAddress] },
                            { reference: "allowance", methodName: "allowance", methodParameters: [connectedAddress, bank.stakingRewards.address] },
                            { reference: "totalSupply_pToken", methodName: "totalSupply" }
                        ],
                        context: { index: i }
                    })
                }

                contractCallContext.push({
                    reference: "stakingRewards_" + bank.coin_symbol,
                    contractAddress: bank.stakingRewards.address,
                    abi: Config.pilot.stakingRewards.ABI,
                    calls: [
                        { reference: "balanceOf_StakingRewards", methodName: "balanceOf", methodParameters: [connectedAddress] }
                    ],
                    context: { index: i }
                })
            }

            const results = (await multiCall.call(contractCallContext)).results
            await parsePools(results)
        }

        fetchData()
    }, [banks, networkType, connectedAddress, web3])

    if (loadingMarkets) {
        return (
            <div className={styles.backContainer}>
                <Container className={styles.marketContainer} style={{ marginTop: 80 }}>
                    <div className={styles.title}>
                        <img
                            className={styles.logo}
                            src={PtdLogo}
                            alt="Pilot"
                        />
                    </div>
                    <div className={styles.market}>
                        <ContentLoader
                            height={130}
                            width={"100%"}
                            speed={1}
                        >
                            <rect x="0" y="20" rx="4" ry="4" width="100%" height="40" />
                            <rect x="0" y="80" rx="4" ry="4" width="100%" height="40" />
                        </ContentLoader>
                    </div>
                </Container>
            </div>
        )
    }

    const tokenUpdater = async callback => {
        const updaterContract = new web3.eth.Contract(
            selectedBank.pToken.ABI,
            selectedBank.pToken.address
            // selectedBank.pToken.symbol === "pHUSD" ? selectedBank.pToken.realToken.ABI : Config.pilot.pTokenABI,
            // selectedBank.pToken.symbol === "pHUSD" ? selectedBank.pToken.realToken.address : selectedBank.pToken.address
        )
        const balance = await updaterContract.methods.balanceOf(connectedAddress).call()

        selectedBank.pToken.balanceOf = new BigNumber(balance)
        setSelectedBank({ ...selectedBank })
        return callback()
    }

    const handleBorrowBegin = _ => {
        setShowInvestModal(false)
    }

    const handleBorrowDone = _ => {
        setShowInvestModal(true)
    }

    const handleInvestClose = _ => {
        setSelectedBank()
        setShowInvestModal(false)
    }

    const handleSortChange = (field) => {
        let newField;
        let newDirection
        if (field !== sortField) {
            newField = field
            newDirection = true
        } else if (sortDireciton) {
            newField = sortField
            newDirection = false
        } else {
            newField = ""
            newDirection = true
        }

        setSortField(newField)
        setSortDirection(newDirection)
    }

    const sortButton = (field) => (
        field !== sortField ? <TiArrowUnsorted /> :
            sortDireciton ? <TiArrowSortedDown /> : <TiArrowSortedUp />
    )

    const header = (
        !mobileMode ?
            <Row className={styles.headerRow}>
                <Col md={2} className={styles.headerItem} style={{ justifyContent: 'left' }}>{t('Pilot.Symbol')}</Col>
                <Col md={2} className={styles.headerItem}>{t('Pilot.TotalDeposit')}</Col>
                <Col md={2} className={styles.headerItem}>{t('Pilot.TotalLoan')}</Col>
                <Col md={2} className={classNames(styles.headerItem, sortField === 'utilRate' && styles.active)}
                    style={{ justifyContent: 'flex-end', cursor: 'pointer' }}
                    onClick={() => handleSortChange('utilRate')}>
                    {t('Pilot.FundsUtilityRate')} {sortButton('utilRate')}
                </Col>
                <Col md={1} className={classNames(styles.headerItem, sortField === 'profit' && styles.active)}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleSortChange('profit')}>
                    {t('Pilot.Profit')} {sortButton('profit')}
                </Col>
                <Col md={1} className={styles.headerItem}>{t("Common.BorrowAPY")}</Col>
                <Col md={2} className={styles.headerItem} style={{ justifyContent: 'center' }}>{t('Pilot.Operate')}</Col>
            </Row> :
            <div className={`${styles.headerRow} d-flex`}>
                <div className={classNames(styles.headerItem, sortField === 'utilRate' && styles.active)} style={{ textAlign: 'left', width: '50%' }} onClick={() => handleSortChange('utilRate')}>
                    {t('Pilot.Symbol')}/{t('Pilot.FundsUtilityRate')} {sortButton('utilRate')}
                </div>
                <div className={classNames(styles.headerItem, sortField === 'profit' && styles.active)}
                    style={{ width: '20%' }}
                    onClick={() => handleSortChange('profit')}>
                    {t('Pilot.Profit')} {sortButton('profit')}
                </div>
                <div className={styles.headerItem} style={{ textAlign: 'left', width: '30%' }}>/{t('Pilot.TotalDeposit')}/{t('Pilot.TotalLoan')}/{t('Pilot.Operate')}</div>
            </div>
    )

    const getProfitRate = (bank) => {
        if (!lossLessMining) {
            return 0
        }

        return Math.max(
            ...Object.keys(lossLessMining)
                .filter(key => key.includes("p" + bank.coin_symbol))
                .map((poolName) => Number(lossLessMining[poolName].profitRate) || 0)
        ) + (Number(bank.interestRate) || 0)
    }

    const getUtilRate = (bank) => {
        return parseFloat(Math.min((parseFloat(bank.totalBorrow) / parseFloat(bank.totalDeposited) * 100.0), 100.0).toFixed(2))
    }

    const sortBanks = (bank1, bank2) => {
        const compare = (s1, s2) => s1 === s2 ? 0 : s1 < s2 ? -1 : 1
        let res = 0;
        if (sortField === 'utilRate') {
            res = compare(getUtilRate(bank1), getUtilRate(bank2))
        }
        if (sortField === 'profit') {
            res = compare(getProfitRate(bank1), getProfitRate(bank2))
        }
        return sortDireciton ? -res : res
    }

    const handleInvest = bank => {
        setSelectedBank(bank)
        setShowInvestModal(true)
    }

    const rows = banks && Object.values(banks).sort(sortBanks).map(bank => {
        const profitRate = getProfitRate(bank)
        const utilRate = getUtilRate(bank)

        return (
            !mobileMode ?
                <Row key={bank.coin_symbol} className={styles.pool} style={{ display: bank.isHiddenFromInvest ? "none" : "" }}>
                    <Col md={2} className={classNames(styles.poolItem, styles.boldDark)} style={{ justifyContent: 'flex-start' }}>
                        <img
                            className={styles.logo}
                            src={bank.logo}
                            alt="logo"
                        />
                        {bank.coin_symbol}
                    </Col>
                    <Col md={2} className={styles.poolItem}>
                        <span>{getLocaleString(bank.totalDeposited)} {bank.coin_symbol}</span>
                    </Col>
                    <Col md={2} className={styles.poolItem}>
                        {getLocaleString(bank.totalBorrow)} {bank.coin_symbol}
                    </Col>

                    <Col md={2} className={classNames(styles.poolItem, styles.boldDark, styles.utilRate)} style={{ justifyContent: 'flex-end' }}>
                        <span>{utilRate}%</span>

                        <div className={styles.rateBar}>
                            <span className={styles.greenBar} style={{ width: `${utilRate}%` }}></span>
                        </div>
                    </Col>

                    <Col md={1} className={classNames(styles.poolItem, styles.profit)}>{(profitRate * 100.0).toFixed(2)}%</Col>
                    <Col md={1} className={classNames(styles.poolItem, styles.profit)}>{(bank.loanAPY - bank.loanMintAPY).toFixed(2)}%</Col>
                    <Col md={2} className={styles.poolItem} style={{ justifyContent: 'space-evenly' }}>
                        <button className={styles.operateButton} onClick={_ => { handleInvest(bank) }}>{t('Pilot.Invest')}</button>
                        {/* <button className={styles.operateButton} onClick={() => setSelectedBankForStaking(bank)}>{t('Pilot.Farm')}</button> */}
                    </Col>
                </Row> :
                <div key={bank.coin_symbol} className={styles.poolMobile} style={{ display: bank.isHiddenFromInvest ? "none" : "" }}>
                    <div className="d-flex flex-column align-items-start">
                        <div className={styles.symbol}>{bank.coin_symbol}</div>

                        <div className={styles.mobildPoolItem}>
                            <div>{t('Pilot.FundsUtilityRate')}</div>
                            <div className={`${styles.utilRate} justify-content-start`}>
                                <span>{utilRate}%</span>
                                <div className={styles.rateBar}>
                                    <span className={styles.greenBar} style={{ width: `${utilRate}%` }}></span>
                                </div>
                            </div>
                        </div>

                        <div className={styles.mobildPoolItem}>
                            <div>{t('Pilot.Profit')}</div>
                            <div className="green">{(profitRate * 100.0).toFixed(2)}%</div>
                        </div>

                        <div className={styles.mobildPoolItem}>
                            <div>{t('Pilot.TotalDeposit')}</div>
                            <div>{getLocaleString(bank.totalDeposited)} {bank.coin_symbol}</div>
                        </div>

                        <div className={styles.mobildPoolItem}>
                            <div>{t('Pilot.TotalLoan')}</div>
                            <div>{getLocaleString(bank.totalBorrow)} {bank.coin_symbol}</div>
                        </div>
                    </div>

                    <div className={styles.operate}>
                        <button className={styles.operateButton} onClick={_ => { handleInvest(bank) }}>{t('Pilot.Invest')}</button>
                        {/* <button className={styles.operateButton} onClick={() => setSelectedBankForStaking(bank)}>{t('Pilot.Farm')}</button> */}
                    </div>
                </div>
        )
    })

    const marketTable =
        <Container className={styles.marketContainer}>
            <div className={styles.title}>
                <img
                    width="100px"
                    src={PtdLogo}
                    alt="Pilot"
                />
            </div>
            <div className={styles.market}>
                <div className={styles.header}>
                    {header}
                </div>
                <div className={styles.rows}>
                    {rows}
                </div>
            </div>
        </Container>

    return (
        <div className={styles.backContainer}>
            {marketTable}
            {/* <DepositModal
                styles={styles}
                mobileMode={mobileMode}
                show={!!selectedBank}
                bank={selectedBank}
                handleClose={() => setSelectedBank()} /> */}
            <GeneralInvestModal
                mobileMode={mobileMode}
                show={showInvestModal}
                marketLogo={PtdLogo}
                data={selectedBank}
                dataTree={props.marketsArr}
                tokenUpdater={tokenUpdater}
                handleBorrowBegin={handleBorrowBegin}
                handleBorrowDone={handleBorrowDone}
                from="pilot"
                handleClose={handleInvestClose}
                themeColor="#5b31b9"
                website={Config.pilot.website} />

            {/* <StakeModal
                styles={styles}
                mobileMode={mobileMode}
                show={!!selectedBankForStaking}
                bank={selectedBankForStaking}
                handleClose={() => setSelectedBankForStaking()} /> */}
        </div>
    )
}

export default Pilot
