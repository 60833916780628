import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import BigNumber from 'bignumber.js'
import { NetworkTypeContext, WalletAddressContext, Web3Context } from '../../context'
import FetchData from '../../methods/FetchData'
import { Multicall } from 'ethereum-multicall'
import Config from "../../utils/config"
import { formatBigNumberWithDecimals } from '../../utils/numberFormat'
import LoadingSpinner from '../../images/loadingspin.svg'
import classNames from 'classnames'
import CheckIcon from '../../images/check.svg'
import ErrorIcon from '../../images/error.svg'
import SavingsLoadingIcon from '../../images/savingsloading.svg'
import CoreData from '../../methods/CoreData'
import StakingData from '../../methods/StakingData'

let poolContract = []
let stakingToken = []
let rewardToken = []
let inputs = []

export function GeneralStakeModal(props) {
	const { connectedAddress } = useContext(WalletAddressContext)
	const { networkType } = useContext(NetworkTypeContext)
	const { web3 } = useContext(Web3Context)

	const { styles, pool } = props;

	const [inputValue, setInputValue] = useState([])
	const [earned, setEarned] = useState(null)
	const [loading, setLoading] = useState(true)
	const [deposited, setDeposited] = useState(null)
	const [stakingTokenSymbol, setStakingTokenSymbol] = useState("")
	const [stakingTokenDecimals, setStakingTokenDecimals] = useState(18)
	const [rewardTokenSymbol, setRewardTokenSymbol] = useState("")
	const [rewardTokenDecimals, setRewardTokenDecimals] = useState(18)
	const [investCompleted, setInvestCompleted] = useState(false)
	const [investFailed, setInvestFailed] = useState(false)
	const [txnHash, setTxnHash] = useState('')
	const [exchangable, setExchangable] = useState(null)
	const [currentStep, setCurrentStep] = useState(0)
	const [needApprove, setNeedApprove] = useState(false)

	const { t } = useTranslation();

	const setTokens = _ => {
		if (!pool) {
			return
		}

		if (pool.from === "pilot") {
			poolContract = [
				pool.stakingRewards,
				pool.pToken.symbol === "pHUSD" && !pool.isHiddenFromInvest ? pool.pToken : Config.pilot
			]
			rewardToken = [Config.pilot.rewardToken, pool.token]
			stakingToken = [pool.pToken, pool.pToken]
		}

		if (pool.from === "solo") {
			poolContract = [Config.solo.poolContract]
			rewardToken = [Config.solo.reward]
			stakingToken = [pool]
		}

		if (pool.from === "depthFi") {
			poolContract = [pool.staking.poolContract, pool.deposit]
			rewardToken = [pool.staking.reward, pool.staking.LPToken]
			stakingToken = [pool.staking.LPToken, pool.deposit.tokens]
		}

		if (pool.from === "booster") {
			poolContract = [
				[Config.booster.staking, Config.booster.actionPools],
				pool
			]
			rewardToken = [
				[Config.booster.rewardToken, Config.booster.fildaAsReward],
				pool
			]
			stakingToken = [pool, pool]
		}
	}

	const updatePilotState = _ => {
		if (currentStep === 0) {
			setDeposited(pool.stakingRewards.balanceOf)
			setEarned(pool.stakingRewards.earned)
			setStakingTokenDecimals(pool.pToken.decimals)
			setStakingTokenSymbol(pool.pToken.symbol)
			setRewardTokenSymbol(Config.pilot.rewardToken.symbol)
			setRewardTokenDecimals(Config.pilot.rewardToken.decimals)
			// } else if (pool.pToken.balanceOf.comparedTo(0) > 0) {
		} else {
			setDeposited(pool.pToken.balanceOf)
			setEarned(null)
			setStakingTokenDecimals(pool.pToken.decimals)
			setStakingTokenSymbol(pool.pToken.symbol)
			setRewardTokenSymbol(pool.token.symbol)
			setRewardTokenDecimals(pool.token.decimals)

			if (pool.pToken.symbol === "pHUSD" && pool.pToken.allowance.comparedTo(0) === 0 && !pool.pToken.approved) {
				setNeedApprove(true)
			}
		}
	}

	const updateDepthFiState = _ => {
		if (currentStep === 0) {
			setDeposited(pool.staking.amount)
			setEarned(pool.staking.poolContract.pendingPiggy)
			setStakingTokenDecimals(pool.staking.LPToken.decimals)
			setStakingTokenSymbol(pool.staking.LPToken.symbol)
			setRewardTokenSymbol(pool.staking.reward.symbol)
			setRewardTokenDecimals(pool.staking.reward.decimals)
			// } else if (pool.deposit.totalDepositedValue.comparedTo(0) > 0) {
		} else {
			setDeposited(null)
			setEarned(null)
			setStakingTokenDecimals([pool.deposit.tokens[0].decimals, pool.deposit.tokens[1].decimals])
			setStakingTokenSymbol([pool.deposit.tokens[0].symbol, pool.deposit.tokens[1].symbol])
			setRewardTokenSymbol(pool.staking.LPToken.symbol)
			setRewardTokenDecimals(pool.staking.LPToken.decimals)

			if (!pool.staking.LPToken.allowanceForWithdrawal || pool.staking.LPToken.allowanceForWithdrawal.comparedTo(0) === 0) {
				setNeedApprove(true)
			}
		}
	}

	const updateBoosterState = _ => {
		if (currentStep === 0) {
			setDeposited(pool.stakedLPTokenAmount)
			setEarned([
				{
					...Config.booster.rewardToken,
					amount: pool.pendingRewardsBoo,
				}, {
					...Config.booster.fildaAsReward,
					amount: pool.pendingRewards
				}
			])
			setStakingTokenDecimals(pool.decimals)
			setStakingTokenSymbol(pool.symbol)
			setRewardTokenSymbol(pool.baseToken.symbol)
			setRewardTokenDecimals(pool.baseToken.decimals)
		} else {
			setDeposited(pool.balanceOf)
			setEarned(null)
			setStakingTokenDecimals(pool.decimals)
			setStakingTokenSymbol(pool.symbol)
			setRewardTokenSymbol(pool.baseToken.symbol)
			setRewardTokenDecimals(pool.baseToken.decimals)
		}
	}

	useEffect(() => {
		if (!pool) {
			return
		}

		setTokens()

		window.document.body.style.setProperty("--dark-theme-color", props.darkThemeColor)
		window.document.body.style.setProperty("--light-theme-color", props.lightThemeColor)

		if (pool.from === "pilot") {
			updatePilotState()
			setLoading(false)
			return
		}

		if (pool.from === "depthFi") {
			updateDepthFiState()
			setLoading(false)
			return
		}

		if (pool.from === "booster") {
			updateBoosterState()
			setLoading(false)
			return
		}

		const parsePools = resultsFromMultiCall => {
			const resultsOfPool = resultsFromMultiCall["pool"].callsReturnContext
			const users = FetchData.pickFromMultiCallResults("users", resultsOfPool)
			setDeposited(users[0])
			pool.deposited = users[0]
			setEarned(FetchData.pickFromMultiCallResults("unclaimedReward", resultsOfPool))

			// const resultsOfStakingRewards = resultsFromMultiCall["stakingToken"].callsReturnContext
			setStakingTokenDecimals(stakingToken[0].decimals)
			setStakingTokenSymbol(stakingToken[0].symbol)

			// const resultsOfRewardsToken = resultsFromMultiCall["rewardsToken"].callsReturnContext
			// setRewardTokenSymbol(FetchData.pickFromMultiCallResults("symbol", resultsOfRewardsToken))
			setRewardTokenDecimals(rewardToken[0].decimals)
			setRewardTokenSymbol(rewardToken[0].symbol)
		}

		const fetchData = async () => {
			let results = null

			if (pool.from === "solo") {
				const multiCall = new Multicall({
					multicallCustomContractAddress: Config.multiCall.network[networkType].address,
					web3Instance: web3
				})

				let contractCallContext = []

				contractCallContext.push({
					reference: "pool",
					contractAddress: poolContract[0].address,
					abi: poolContract[0].ABI,
					calls: [
						{ reference: "unclaimedReward", methodName: "unclaimedReward", methodParameters: [pool.indexOfPool, connectedAddress] },
						{ reference: "users", methodName: "users", methodParameters: [pool.indexOfPool, connectedAddress] }
						// { reference: "pools", methodName: "pools", methodParameters: [pool.indexOfPool] }
					]
				})

				contractCallContext.push({
					reference: "rewardsToken",
					contractAddress: rewardToken[0].address,
					abi: rewardToken[0].ABI,
					calls: [
						{ reference: "symbol_RewardsToken", methodName: "symbol" }
					]
				})

				results = (await multiCall.call(contractCallContext)).results
			}

			parsePools(results)
		}

		fetchData().then(() => {
			setLoading(false);
		})
	}, [pool, connectedAddress, networkType, web3, props.darkThemeColor])

	const handleClose = async () => {
		setInputValue([])
		setInvestCompleted(false)
		setInvestFailed(false)
		setTxnHash('')
		setNeedApprove(false)
		props.handleClose()
	}

	const computeExchangable = value => {
		if (!pool.token || !pool.token.totalSupply || !stakingToken[currentStep] || !stakingToken[currentStep].totalSupply || !rewardToken[currentStep] || !rewardToken[currentStep].totalDebt || !rewardToken[currentStep].totalReserve || value === "") {
			return setExchangable(null)
		}

		setExchangable(
			new BigNumber(value)
				.shiftedBy(stakingToken[currentStep].decimals)
				.multipliedBy(
					pool.token.totalSupply
						.plus(rewardToken[currentStep].totalDebt)
						.minus(rewardToken[currentStep].totalReserve)
						.dividedBy(stakingToken[currentStep].totalSupply)
				)
		)
	}

	const handleInput = event => {
		const index = parseInt(event.currentTarget.id)
		const value = Number(event.currentTarget.value)
		if (!isNaN(value) && value > 0) {
			if (pool.from === "depthFi" && currentStep === 1) {
				let anotherIndex = 0
				if (index === 0) {
					anotherIndex = 1
				}
				const anotherValue = stakingToken[currentStep][anotherIndex].percent.multipliedBy(value).dividedBy(stakingToken[currentStep][index].percent)
				if (stakingToken[currentStep][anotherIndex].deposited.comparedTo(anotherValue) >= 0) {
					inputValue[anotherIndex] = anotherValue
					inputs[anotherIndex].value = anotherValue
				} else {
					return setInputValue([])
				}
			} else {
				inputValue[index] = value
				computeExchangable(value)
			}
		} else {
			inputValue.splice(index, 1)
		}
		setInputValue([...inputValue])
	}

	const handleMaximumInput = event => {
		const index = parseInt(event.currentTarget.dataset.index)
		const value = event.currentTarget.dataset.value
		if (value > 0) {
			if (pool.from === "depthFi" && currentStep === 1) {
				let anotherIndex = 0
				if (index === 0) {
					anotherIndex = 1
				}
				const anotherValue = stakingToken[currentStep][anotherIndex].percent.multipliedBy(value).dividedBy(stakingToken[currentStep][index].percent)

				inputValue[anotherIndex] = anotherValue
				inputs[anotherIndex].value = anotherValue

				inputValue[index] = value
				inputs[index].value = new BigNumber(value).toFixed()

				if (stakingToken[currentStep][anotherIndex].deposited.shiftedBy(-stakingToken[currentStep][anotherIndex].decimals).comparedTo(anotherValue) < 0) {
					return setInputValue([])
				}
			} else {
				inputValue[index] = value
				inputs[index].value = new BigNumber(value).toFixed()
				computeExchangable(value)
			}
		} else {
			inputValue.splice(index, 1)
		}
		setInputValue([...inputValue])
	}

	const handleApprove = _ => {
		setLoading(true)

		let args = null
		if (pool.from === "pilot") {
			args = [pool.pToken.realToken.address, pool.pToken.address]
		}

		if (pool.from === "depthFi") {
			args = [pool.staking.LPToken.address, pool.deposit.address]
		}

		if (!args) {
			return setLoading(false)
		}

		StakingData.approveERC20(web3, connectedAddress, networkType, ...args).then(async response => {
			if (response) {
				if (props.updateData) {
					await props.updateData()
				}

				setLoading(false)
				setNeedApprove(false)
			}
		}).catch(error => {
			console.error(error)
			handleClose()
			setLoading(false)
		})
	}

	const handleConfirm = async () => {
		if (!pool) {
			return
		}

		setLoading(true)

		setTokens()

		let contract
		if (pool.from === "booster" && pool.stakedLPTokenAmount.comparedTo(0) > 0) {
			contract = new web3.eth.Contract(Config.booster.staking.ABI, Config.booster.staking.address)
		} else if (pool.from === "booster" && pool.balanceOf.comparedTo(0) > 0) {
			contract = new web3.eth.Contract(pool.ABI, pool.address)
		} else {
			contract = new web3.eth.Contract(poolContract[currentStep].ABI, poolContract[currentStep].address)
		}
		let func = null
		let args = []

		switch (pool.from) {
			case "pilot":
				if (pool.stakingRewards.earned && pool.stakingRewards.balanceOf.comparedTo(0) > 0) {
					func = contract.methods.withdraw
					args = [new BigNumber(inputValue).shiftedBy(stakingTokenDecimals).toFixed(0, 1)]
				} else if (pool.pToken.balanceOf.comparedTo(0) > 0) {
					func = contract.methods.withdraw

					if (pool.pToken.symbol === "pHUSD" && !pool.isHiddenFromInvest) {
						args = [new BigNumber(inputValue).shiftedBy(stakingTokenDecimals).toFixed(0, 1)]
					} else {
						args = [pool.token.address, new BigNumber(inputValue).shiftedBy(stakingTokenDecimals).toFixed(0, 1)]
					}
				}
				break

			case "depthFi":
				if (pool.staking.rewardDebt.comparedTo(0) > 0) {
					func = contract.methods.unStake
					args = [stakingToken[currentStep].indexOfPool, new BigNumber(inputValue[0]).multipliedBy(new BigNumber(10).pow(stakingTokenDecimals)).toFixed(0, 1)]
				} else if (pool.deposit.totalDepositedValue.comparedTo(0) > 0) {
					func = contract.methods.remove_liquidity
					args = [
						rewardToken[currentStep].balanceOf.toFixed(),
						[
							new BigNumber(inputValue[0]).shiftedBy(stakingToken[currentStep][0].decimals).minus(0.0001).toFixed(0, 1),
							new BigNumber(inputValue[1]).shiftedBy(stakingToken[currentStep][1].decimals).minus(0.0001).toFixed(0, 1)
						],
						true
					]
				}
				break

			case "solo":
				func = contract.methods.withdraw
				args = [stakingToken[currentStep].indexOfPool, new BigNumber(inputValue[0]).multipliedBy(new BigNumber(10).pow(stakingTokenDecimals)).toFixed(0, 1)]
				break

			case "booster":
				func = contract.methods.withdraw

				if (pool.stakedLPTokenAmount.comparedTo(0) > 0) {
					// for unstaking.
					args = [stakingToken[currentStep].indexOfPool, new BigNumber(inputValue[0]).multipliedBy(new BigNumber(10).pow(stakingTokenDecimals)).toFixed(0, 1)]
				} else if (pool.balanceOf.comparedTo(0) > 0) {
					// for withdrawal.
					args = [new BigNumber(inputValue[0]).multipliedBy(new BigNumber(10).pow(stakingTokenDecimals)).toFixed(0, 1)]
				} else {
					return
				}
				break

			default:
				return
		}

		func(...args).send({
			from: connectedAddress,
			value: 0
		}).on('transactionHash', function (hash) {
			setTxnHash(hash)
		}).on('confirmation', function (confirmationNumber, receipt) {
			// 
		}).on('receipt', function (receipt) {
			if (receipt) {
				if (props.updateData) {
					props.updateData()
				}
				// handleClose()
				setInvestCompleted(true)
			}

			setLoading(false)
		}).on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
			console.error(error)
			setInvestFailed(true)
			setLoading(false)
		})
	}

	const handleClaim = async event => {
		if (!pool) {
			return
		}

		setLoading(true)
		setTokens()

		const idx = parseInt(event.currentTarget.id)
		let contract
		if (poolContract.length && poolContract.length > 1 && poolContract[0].length && poolContract[0].length > 1) {
			contract = new web3.eth.Contract(poolContract[0][idx].ABI, poolContract[0][idx].address)
		} else {
			contract = new web3.eth.Contract(poolContract[0].ABI, poolContract[0].address)
		}

		let func = null
		let args = []

		switch (pool.from) {
			case "pilot":
				func = contract.methods.getReward
				args = []
				break

			case "depthFi":
				func = contract.methods.claim
				args = [stakingToken[currentStep].indexOfPool]
				break

			case "solo":
				func = contract.methods.deposit
				args = [stakingToken[currentStep].indexOfPool, 0]
				break

			case "booster":
				func = contract.methods.claim
				args = [stakingToken[currentStep].indexOfPool]
				break

			default:
				return
		}

		func(...args).send({
			from: connectedAddress,
			value: 0
		}).on('transactionHash', function (hash) {
			setTxnHash(hash)
		}).on('confirmation', function (confirmationNumber, receipt) {
			// 
		}).on('receipt', function (receipt) {
			if (receipt) {
				if (props.updateData) {
					props.updateData()
				}
				handleClose()
			}
			setLoading(false)
		}).on('error', function (error, receipt) { // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
			console.error(error)
			setInvestFailed(true)
			setLoading(false)
		})
	}

	const depositInfo = earned && deposited && deposited.comparedTo(0) > 0 && (<div className={styles.depositInfo}>
		<div className="d-flex flex-column justify-content-start">
			<span className={styles.title}>{t('Pilot.Deposited')}</span>
			<span className={styles.content}>{formatBigNumberWithDecimals(deposited, stakingTokenDecimals, 8)}&nbsp;{stakingTokenSymbol}</span>
		</div>
		<div className="d-flex-column" style={{ marginTop: "21px" }}>
			<span className={styles.title}>{t('Pilot.Earned')}</span>

			{earned && earned.length && earned.length > 1 ? earned.map((item, index) => {
				return (<div key={index} className="d-flex flex-row justify-content-between" style={{ marginTop: "0.5rem" }}>
					<span className={styles.content}>{formatBigNumberWithDecimals(item.amount, item.decimals, 6)}&nbsp;{item.symbol}</span>
					<button className={styles.operateButtonInModal} id={index} onClick={handleClaim}>{t('Pilot.Claim')}</button>
				</div>)
			}) : (<div className="d-flex flex-row justify-content-between">
				<span className={styles.content}>{formatBigNumberWithDecimals(earned, rewardTokenDecimals, 4)}&nbsp;{rewardTokenSymbol}</span>
				<button className={styles.operateButtonInModal} id={0} onClick={handleClaim}>{t('Pilot.Claim')}</button>
			</div>)}
		</div>
	</div>)

	const renderWithdrwalBlock = _ => {
		if (!stakingToken[currentStep]) {
			return
		}

		return stakingToken[currentStep].length > 1 ? (stakingToken[currentStep].map((item, index) => {
			return (<div key={item.address}>
				{amountInputCaption(item)}
				{amountInput(item, index)}
				{exchangableLabel}
			</div>)
		})) : (<>
			{amountInputCaption(stakingToken[currentStep])}
			{amountInput(stakingToken[currentStep], 0)}
			{exchangableLabel}
		</>)
	}

	const amountInputCaption = item => {
		return (<div className="d-flex justify-content-between mt-4">
			<span>&nbsp;</span>
			<div className="d-flex">
				<span className="text-secondary ts-14">{t('Pilot.Withdrawable')}</span>
				<span className="ml-2 ts-14">{formatBigNumberWithDecimals(item.deposited || deposited, item.decimals, 8) || 0}&nbsp;{item.symbol}</span>
			</div>
		</div>)
	}

	const exchangableLabel = exchangable && (<div style={{
		marginTop: "0.5rem",
		fontWeight: "bold",
		textAlign: "left"
	}}>
		<span>{t("Common.Withdraw")}:&nbsp;</span>
		<span>{formatBigNumberWithDecimals(exchangable, stakingTokenDecimals, 8)}&nbsp;{rewardTokenSymbol}</span>
	</div>)

	const amountInput = (item, index) => {
		if (!item.deposited && !deposited && !item.balanceOf) {
			return (<div className={`mt-1 ${styles.amountInput}`}>
				<input disabled />
				<span className={styles.suffix}>
					<span className="text-secondary ts-18 tw-5">{item.symbol}</span>
					<div className={`d-flex ml-3 ts-18 tw-5 ${styles.maximum}`}>&nbsp;</div>
				</span>
			</div>)
		}

		return (<div className={`mt-1 ${styles.amountInput}`}>
			<input
				ref={node => { inputs[index] = node }}
				id={index}
				onChange={handleInput}
			/>
			<span className={styles.suffix}>
				<span className="text-secondary ts-18 tw-5">{item.symbol}</span>
				<div className={`d-flex ml-3 ts-18 tw-5 ${styles.maximum}`} data-value={(item.deposited || deposited || new BigNumber(0)).shiftedBy(-(item.decimals || stakingTokenDecimals))} data-index={index} onClick={handleMaximumInput}>{t('Pilot.Maximum')}</div>
			</span>
		</div>)
	}

	const warningInfo =
		<div className="mt-4 ts-12 text-muted text-left">
			{t('Pilot.Warning')}
		</div>

	const TxnSuccessMsg =
		<div>
			<Modal.Body>
				<div className={styles.loadingContainer} style={{ filter: "grayscale(100%)" }}>
					<img
						src={CheckIcon}
						width="auto"
						height="60px"
						className="d-inline-block align-top"
						alt="error"
					/>
				</div>

				<a href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank" style={{ color: "black" }} rel="noopener noreferrer">{t('Common.ViewTxnOnExplorer')}</a>
			</Modal.Body>
			<Modal.Footer>
				<button className={styles.buttonsInInvestModal} style={{ backgroundColor: props.darkThemeColor }} onClick={handleClose}>{t('Common.Close')}</button>
			</Modal.Footer>
		</div>

	const TxnErrorMsg =
		<div>
			<Modal.Body>
				<div className={styles.loadingContainer} style={{ filter: "grayscale(100%)" }}>
					<img
						src={ErrorIcon}
						width="auto"
						height="60px"
						className="d-inline-block align-top"
						alt="error" />

					<p>{t("WithdrawModal.ErrorMsg")}</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className={styles.buttonsInInvestModal} style={{ backgroundColor: props.darkThemeColor }} onClick={handleClose}>{t('Common.Close')}</button>
			</Modal.Footer>
		</div>

	const ModalLoading =
		<div>
			<Modal.Body>
				<div className={styles.loadingContainer} style={{ filter: "grayscale(100%)", display: "flex", flexDirection: "column" }}>
					<img
						src={SavingsLoadingIcon}
						width="auto"
						height="60px"
						// className="d-inline-block align-top"
						alt="loading"
					/>
				</div>
			</Modal.Body>
		</div>

	const handleModeUpdate = event => {
		setCurrentStep(parseInt(event.currentTarget.id))
		setExchangable(null)
		setInputValue([])

		if (inputs.length > 0) {
			inputs.forEach(input => {
				if (input) {
					return input.value = ""
				}
			})
		}
	}

	useEffect(_ => {
		if (pool.from === "pilot") {
			updatePilotState()
		}

		if (pool.from === "depthFi") {
			updateDepthFiState()
		}

		if (pool.from === "booster") {
			updateBoosterState()
		}
	}, [currentStep, pool.from])

	const toggle = (poolContract.length === 2 && <div className={styles.toggle}>
		<span className={classNames(styles.item, currentStep === 0 && styles.active)} id="0" onClick={handleModeUpdate}>{t('Stake.Unstake')}</span>
		<span className={classNames(styles.item, currentStep === 1 && styles.active)} id="1" onClick={handleModeUpdate}>{t('Pilot.Withdraw')}</span>
		<div className={classNames(styles.ball, currentStep === 0 ? styles.left : styles.right)} />
	</div>)

	const makeButtons = needApprove ? (
		<button className={`${styles.modalButton} text-center mt-2`} onClick={handleApprove}>{t('Common.Approve')}</button>
	) : (
		<button className={`${styles.modalButton} text-center mt-2`} disabled={inputValue.length === 0} onClick={handleConfirm}>{t("Pilot.Confirm")}</button>
	)

	return (
		<Modal
			show={true}
			onHide={handleClose}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className={classNames(styles.modalContainer, props.mobileMode && styles.mobile)}
			animation={false}>
			<Modal.Header closeButton>
				<img
					src={props.marketLogo}
					width="auto"
					height="36px"
					className={styles.marketLogo}
					alt="QuickSilver Logo" />
			</Modal.Header>

			{investCompleted ? TxnSuccessMsg : (investFailed ? TxnErrorMsg : (loading ? ModalLoading : (<>
				<Modal.Body>
					{depositInfo}
					{toggle}
					{renderWithdrwalBlock()}
					{warningInfo}
				</Modal.Body>
				<Modal.Footer>
					{loading ? <img width={30} src={LoadingSpinner} alt="Loading Spinner" /> : makeButtons}
				</Modal.Footer>
			</>)))}

		</Modal >
	)
}