import React, { useState, useEffect, useContext } from 'react'
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import { FaAngleDown, FaExclamationTriangle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import BigNumber from 'bignumber.js'
import CoreMethod from '../../methods/CoreMethod'
import LoadingIcon from '../../images/loanloading.svg'
import LoadingSpinner from '../../images/loadingspin.svg'
import CheckIcon from '../../images/checkyellow.svg'
import ErrorIcon from '../../images/error.svg'
import CoreData from '../../methods/CoreData'
import FetchData from '../../methods/FetchData'
import log from '../../utils/logger'
import FlashLoan from '../../lib/FlashLoan'
import SwapRouter from '../../lib/SwapRouter'
import Config from '../../utils/config'
import { NetworkTypeContext, WalletAddressContext, Web3Context } from '../../context'
import { formatBigNumber } from '@src/utils/numberFormat'
import { sleep } from '@src/utils/promise'
const FeeManager = require('@src/lib/FeeManager.json')

export default function DepositRepayModal(props) {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { networkType } = useContext(NetworkTypeContext)
    const { web3 } = useContext(Web3Context)

    const styles = props.styles;
    const { t } = useTranslation();

    const [swapValue, setSwapValue] = useState('')
    const [repayValue, setRepayValue] = useState('')
    const [submitAmount, setSubmitAmount] = useState('')
    const [serviceValue, setServiceValue] = useState('')
    const [daoValue, setDaoValue] = useState('')
    const [showDao, setShowDao] = useState(false)
    const [swapAsset, setSwapAsset] = useState()
    const [swapData, setSwapData] = useState([])
    const [swapPath, setSwapPath] = useState([])
    const [showAssetSelect, setShowAssetSelect] = useState(false)
    const [loading, setLoading] = useState(false)
    const [initDone, setInitDone] = useState(false)

    const [invalidInput, setInvalidInput] = useState(false)
    const [overPay, setOverPay] = useState(false)
    const [calculating, setCalculating] = useState(false)
    const [lowBalance, setLowBalance] = useState(false)
    const [noSwapRoute, setNoSwapRoute] = useState(false)
    const [largeDeviation, setLargeDeviation] = useState(false)
    const [firstApproval, setFirstApproval] = useState(true)
    const [needsFurtherApproval, setNeedsFurtherApproval] = useState(false)
    const [allowanceFormatted, setAllowanceFormatted] = useState('')

    const [confirmFullRepay, setConfirmFullRepay] = useState(false)

    const [confirmDeviation, setConfirmDeviation] = useState(false)
    const [deviationAmount, setDeviationAmount] = useState('')
    const [checkedDeviation, setCheckedDeviation] = useState(false)

    const [repayCompleted, setRepayCompleted] = useState(false)
    const [repayFailed, setRepayFailed] = useState(false)
    const [txnHash, setTxnHash] = useState('')

    const [rate, setRate] = useState(0)
    const [mode, setMode] = useState(0) // 0: input , 1: max, 2: max all
    const [reApproveFtoken, setReApproveFtoken] = useState('')

    const fetchRate = async (amount) => {
        const myContract = new web3.eth.Contract(FeeManager.abi, Config.FeeManagerContract[networkType])
        const molecular = await myContract.methods.FEE_MOLECULAR().call()
        const denominator = await myContract.methods.FEE_DENOMINATOR().call()
        return new BigNumber(molecular).div(denominator).toString()
    }


    useEffect(() => {
        if (props.show) {
            const getRate = async () => {
                const rate = await fetchRate();
                setRate(rate)
            }
            getRate();
        }
    }, [props.show]);

    useEffect(() => {
        if (props.show === true) {
            const swapPairs = ["HBTC", "HETH", "USDT", "HUSD", "MDX", "HT", "DAI", "USDC", "TUSD"]
            const data = props.allData.filter((d) => swapPairs.includes(d.symbol) && parseFloat(d.savingsBalance) > 0)
            setSwapData(data)
        }
    }, [props.allData, props.data, props.show])

    useEffect(() => {
        if ((swapData || []).length === 0) {
            setSwapAsset(undefined)
        } else if (!swapAsset || (swapData.findIndex((d) => d.symbol === swapAsset.symbol) < 0)) {
            setSwapAsset(swapData[0])
        }
        setInitDone(true)
    }, [swapData])

    function numberFromString(s) {
        return CoreData.fixedNaN(parseFloat(s))
    }

    const getRawValue = async (symbol, value) => {
        return CoreData.getRawValue(web3, networkType, symbol, value)
    }

    const getQTokenRawValue = async (symbol, value) => {
        const contract = await CoreData.getQTokenContract(web3, networkType, symbol)
        let decimals = await contract.methods.decimals().call()
        BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })
        return BigNumber(value).shiftedBy(parseInt(decimals)).toFixed(0)
    }

    const fromWei = async (market, amount) => {
        if (CoreData.isNativeToken(market.symbol, networkType)) {
            return await web3.utils.fromWei(amount.toString(), 'ether')
        }
        const decimals = await FetchData.getDecimals(web3, networkType, market);
        return BigNumber(amount).shiftedBy(-parseInt(decimals))
    }

    const getTokenAddress = (symbol) => {
        if (symbol === 'HT') return Config.WHT
        return Config.markets[symbol].network[networkType].address
    }

    const getQTokenAddress = (symbol) => {
        return Config.markets[symbol].qToken.network[networkType].address
    }

    const newFlashLoan = () => {
        return new FlashLoan(web3, Config.FlashLoanContract[networkType], Config.DepositRepayContract, onTransactionHash);
    }

    const newSwapRouter = () => {
        return new SwapRouter(web3, getTokenAddress('USDT'), getTokenAddress('HUSD'), Config.WHT, Config.MDEXRouter);
    }


    const calculateInAmount = async (amountOut) => {
        const swapRouter = newSwapRouter();
        const res = await swapRouter.getAmountOutRouter(amountOut, getTokenAddress(swapAsset.symbol), getTokenAddress(props.data.symbol))
        setNoSwapRoute(res.path.length === 0)
        setSwapPath(res.path)
        return res
    }

    const calculateOutAmount = async (amountIn) => {
        const swapRouter = newSwapRouter();
        const res = await swapRouter.getAmountInRouter(amountIn, getTokenAddress(swapAsset.symbol), getTokenAddress(props.data.symbol))
        setNoSwapRoute(res.path.length === 0)
        setSwapPath(res.path)
        return res
    }

    const fetchServiceCharge = async (amount) => {
        const myContract = new web3.eth.Contract(FeeManager.abi, Config.FeeManagerContract[networkType])
        return await myContract.methods.getFee(connectedAddress, amount).call()
    }


    const calculateDao = async (amount, serviceAmount) => {
        setShowDao(false)
        setDaoValue('')
        const thousandAmount = BigNumber(amount).multipliedBy(rate)
        const thousandAmountInt = thousandAmount.integerValue(BigNumber.ROUND_DOWN).toString()

        if (thousandAmountInt !== serviceAmount) {
            // dao持仓优惠
            const daoValue = thousandAmount.minus(serviceAmount).toFixed(0)
            const daoUIValue = await fromWei(swapAsset, daoValue)
            const daoUIDecimals6 = numberFromString(daoUIValue.toString()).toFixed(6)

            if (parseFloat(daoUIDecimals6) !== 0) {
                // 优惠取6位
                setShowDao(true)
                setDaoValue(daoUIDecimals6)
            }
        }
    }

    const onTransactionHash = (hash) => {
        setTxnHash(hash) // we use this only for the modal's state
    }

    const verifyFurtherApproval = async (txnValue) => {
        const { allowance, allowanceFormatted } = await FetchData.getDepositRepayAllowance(web3, connectedAddress, networkType, swapAsset)
        if (allowance && allowance < Number(txnValue)) {
            setNeedsFurtherApproval(true)
            setAllowanceFormatted(allowanceFormatted)
            return Promise.resolve(false)
        }

        setNeedsFurtherApproval(false)
        setAllowanceFormatted('')
        return Promise.resolve(true)  
    }

    const getQTokenAmount = async (amount) => {
        const ctoken = await CoreData.getQTokenContract(web3, networkType, swapAsset.symbol)
        const exrate = await ctoken.methods.exchangeRateCurrent().call();

        return BigNumber(amount).times(Math.pow(10, 18)).div(exrate).toFixed(0);
    }

    const setMaximum = async () => {
        if (parseFloat(swapAsset.savingsBalance) === 0) {
            resetTipsError()
            setSwapValue(swapAsset.savingsBalance)
            setLowBalance(true)
            return;
        }
        setCalculating(true)
        let borrowAmount = props.data.loanBalance;
        let savingAmount = swapAsset.savingsBalance;
        let outAmount;
        if (props.data.symbol !== swapAsset.symbol) {
            const res = await calculateOutAmount(savingAmount)
            savingAmount = res.amount
        }

        if ( BigNumber(savingAmount).gte(BigNumber(borrowAmount)) ) {
            // console.log('max all')
            setMode(2)

            const b = BigNumber(borrowAmount).times(1.00001).plus(1)

            if (swapAsset.symbol === props.data.symbol) {
                setNoSwapRoute(false)
                setSwapPath([])
                outAmount = BigNumber(b).div(BigNumber(1).minus(rate)).toFixed(0)
            } else {
                let res = await calculateInAmount(b.toFixed(0));
                if (res.amount == 0) return;
                outAmount = BigNumber(res.amount).div(BigNumber(1).minus(rate)).toFixed(0)
            }

            setSubmitAmount(outAmount)

            const swapAmount = await fromWei(swapAsset, outAmount)
            setSwapValue(swapAmount.toString())
            // 
            const repayUIValue = await fromWei(props.data, borrowAmount)
            setRepayValue(repayUIValue.toString())
            // 手续费
            const serviceValue = await fetchServiceCharge(outAmount)
            const serviceUIValue = await fromWei(swapAsset, serviceValue)
            setServiceValue(serviceUIValue.toString())

            // dao
            calculateDao(outAmount, serviceValue)
            
            setLowBalance(new BigNumber(swapAsset.savingsBalance).isLessThan(outAmount))

        } else {
            // console.log('max part')
            setMode(1)
            setSwapValue(swapAsset.savingsBalanceFormatted)
            await mode0And1(swapAsset.savingsBalance)
        }

        setCalculating(false)
    }

    

    const mode0And1 = async (txnValue) => {
        let outAmount;

        let amount = BigNumber(txnValue).times(1.001).toFixed(0)
        setSubmitAmount(amount)

        // 扣掉手续费
        const sAmount = await fetchServiceCharge(amount)
        outAmount = BigNumber(txnValue).minus(sAmount).toFixed(0)

        if (swapAsset.symbol === props.data.symbol) {
            setNoSwapRoute(false)
            setSwapPath([])
        } else {
            let res = await calculateOutAmount(outAmount);
            if (res.amount == 0) return;
            outAmount = res.amount
        }


        const repayUIValue = await fromWei(props.data, outAmount)
        setRepayValue(repayUIValue.toString())


        // 手续费
        const serviceValue = await fetchServiceCharge(txnValue)
        const serviceUIValue = await fromWei(swapAsset, serviceValue)
        setServiceValue(serviceUIValue.toString())

        // dao
        calculateDao(txnValue, serviceValue)


        setOverPay(new BigNumber(props.data.loanBalance).isLessThan(outAmount))  // 偿还超过您所欠的
        setLowBalance(new BigNumber(swapAsset.savingsBalance).isLessThan(txnValue)) // 存款金额不足
    }

    const onSwapValueUpdate = async (value) => {
        resetTipsError()

        let inputValue = new BigNumber(value)

        if (inputValue.isNegative()) {
            inputValue = inputValue.absoluteValue()
            setSwapValue(inputValue.toString())
            setInvalidInput(false)
        } else {
            setSwapValue(value)
            const IS_NUMERIC = /^(\d+(\.\d+)?)?$/
            const isNumeric = (str) => IS_NUMERIC.test(str)
            setInvalidInput(!isNumeric(value))
        }

        setOverPay(false)
        setLowBalance(false)
        setLargeDeviation(false)
        if (numberFromString(inputValue) === 0) {
            setSubmitAmount('')
            setRepayValue('')
            setServiceValue('')
            setDaoValue('')
            return
        }

        // 
        setMode(0)
        const txnValue = await getRawValue(swapAsset.symbol, numberFromString(inputValue))
        setCalculating(true)
        await mode0And1(txnValue)
        setCalculating(false)
    }


    const handleRepay = async () => {
        const gtagParams = {
            url: window.location.href,
            from: swapAsset.symbol,
            to: props.data.symbol,
        }
        setFirstApproval(true)
        if (swapAsset.symbol !== props.data.symbol) {
            // bad price check
            const warningLevel = 2 // (%) - show warnings
            const criticalLevel = 10 // (%) - stop repay

            const bPrice = new BigNumber(swapValue).multipliedBy(swapAsset.price)
            const aPrice = new BigNumber(repayValue).multipliedBy(props.data.price)
            const deviation = bPrice.minus(aPrice)
            setDeviationAmount(deviation.toFixed(6))
            if (deviation > bPrice.multipliedBy(criticalLevel / 100.0)) {
                setConfirmFullRepay(false)
                setLargeDeviation(true)
                return
            }
      
            if (deviation > bPrice.multipliedBy(warningLevel / 100.0) && !confirmDeviation) {
                setConfirmDeviation(true)
                setCheckedDeviation(false)
                return
            }
        }
      
        if (lowBalance || overPay || invalidInput) {
            setLoading(false)
            return
        }
      
        setConfirmDeviation(false)
      
        // swap and repay
        setLoading(true)
        setConfirmFullRepay(true)
        const flashLoan = newFlashLoan()
      
        const tokenB = getTokenAddress(swapAsset.symbol)
        const ftokenB = getQTokenAddress(swapAsset.symbol)
        const amountB = await getRawValue(swapAsset.symbol, swapValue)
      
      
        const tokenA = getTokenAddress(props.data.symbol)
        const ftokenA = getQTokenAddress(props.data.symbol)
        const amountA = await getRawValue(props.data.symbol, repayValue)
      

        try {
            let slippage = 0
            
            if (props.data.symbol !== swapAsset.symbol) {
                slippage = BigNumber(mode === 2 ? submitAmount : amountA).times(mode === 2 ? 1.02 : 0.98).toFixed(0) // 输入 | max  0.98, max all 1.02,
            }
             
            const ftokenAmount = await getQTokenAmount(amountB)

            setReApproveFtoken(ftokenAmount)

            const isValidAllowance = await verifyFurtherApproval(ftokenAmount)
            if (!isValidAllowance) {
                setLoading(false)
                return
            }
      
            let response = await flashLoan.depositRepayLoan({
                path: swapPath,
                ftokenB,
                tokenB,
                ftokenA,
                tokenA,
                ftokenAmount,
                account: connectedAddress,
                mode,
                slippage,
                amount: submitAmount,
            })

            if (!props.show) return;
    
            if (response.events.Failure) {
                window.gtag('event', 'deposit_repay', {
                    ...gtagParams,
                    error: 'response event fail'
                })
                setRepayFailed(true)
            } else {
                window.gtag('event', 'deposit_repay', gtagParams)
                setRepayCompleted(true)
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            if (error.code === 4001) {
                window.gtag('event', 'deposit_repay', {
                    ...gtagParams,
                    error: 'user rejected'
                })
                handleClose()
            } else {
                window.gtag('event', 'deposit_repay', {
                    ...gtagParams,
                    error: 'other error'
                })
                setRepayFailed(true)
            }
        }
      }

    const resetTipsError = () => {
        setInvalidInput(false)
        setOverPay(false)
        setLowBalance(false)
        setNoSwapRoute(false)
        setLargeDeviation(false)
        setShowDao(false)
    }




    const handleApprove = async (first = true) => {
        setLoading(true)
        await CoreMethod.approveDepositRepayERC20(web3, connectedAddress, networkType, swapAsset)
            .then(async response => {
                if (response) {
                    if (first) {
                        setFirstApproval(false)
                    } else {
                        await sleep()
                        const isValidAllowance = await verifyFurtherApproval(reApproveFtoken)
                        isValidAllowance && setFirstApproval(false)
                    }
                }
                setLoading(false)
            })
            .catch(error => {
                if (error.code === 4001) {
                    handleClose()
                }
            })
    }

    const handleSelectAsset = async (asset) => {
        setShowAssetSelect(false)
        setSwapAsset(asset)
        setNoSwapRoute(false)
        setLowBalance(false)
        setOverPay(false)
        setLargeDeviation(false)
        setSwapValue('')
        setRepayValue('')
        setServiceValue('')
        setDaoValue('')
        setSubmitAmount('')
        setShowDao(false)
        setFirstApproval(true)
        setMode(0)
        setReApproveFtoken('')
    }

    const handleClose = async () => {
        setInvalidInput(false)
        setOverPay(false)
        setLowBalance(false)
        setLargeDeviation(false)
        setNoSwapRoute(false)

        setLoading(false)
        setCalculating(false)
        setInitDone(false)

        setSwapAsset()
        setSwapValue('')
        setRepayValue('')
        setServiceValue('')
        setDaoValue('')
        setSubmitAmount('')
        setShowDao(false)
        setRepayCompleted(false)
        setRepayFailed(false)
        setTxnHash('')

        setNeedsFurtherApproval(false)
        setConfirmFullRepay(false)
        setConfirmDeviation(false)
        setShowAssetSelect(false)
        setFirstApproval(true)
        setMode(0)
        setReApproveFtoken('')
        props.handleClose()
    }


    const getTransactionLimit = () => {
        let result = '0'
        if (props?.allData?.transactionLimit?.amountFiat) {
            result = props.allData.transactionLimit.amountFiat.toFixed(0)
        }
        return formatBigNumber(result)
    }

    //UI Rendering

    const RepayButton =
        (calculating || invalidInput || overPay || lowBalance || isNaN(parseFloat(swapValue)) || isNaN(parseFloat(repayValue)) || parseFloat(swapValue) <= 0 || parseFloat(repayValue) <= 0) ?
            <Button variant="loans" disabled>{t('Common.Repay')}</Button> :
            <Button variant="loans" onClick={() => handleRepay()}>{t('Common.Repay')}</Button>

    const ModalLoading = () =>
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={LoadingIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="loading"
                        />
                    {
                        txnHash &&
                        <a style={{ color: '#BDB780' }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
                    }
                </div>
            </Modal.Body>
        </div>


        const ModalRepayCustom = () =>
            <div>
                <Modal.Body>
                    <div className={styles.footerInfo}>
                        <span className={styles.hd}>{`${t('Common.SavingsBalance')}`}</span>
                        <span className={styles.bd}>
                            {`${parseFloat(swapAsset.savingsBalanceFormatted).toFixed(6)} ${swapAsset.symbol}`}
                        </span>
                    </div>
                    <Form>
                        <div className={styles.formGroup}>
                            <Form.Group controlId="formSwapValue">
                                <Form.Control
                                className={styles.txnValue}
                                type="number"
                                placeholder={"0.00 " + swapAsset.symbol}
                                autoComplete="off"
                                value={swapValue}
                                min="0"
                                onChange={e => onSwapValueUpdate(e.target.value)} />
                                <Button variant="secondary" onClick={() => setMaximum()}>{t('Common.Maximum')}</Button>
                            </Form.Group>
                        </div>
                    </Form>
                    {invalidInput ? (
                        <div className={styles.txnError}>{t('RepayModal.InvalidInput')}</div>
                    ) : overPay ? (
                        <div className={styles.txnError}>{t('RepayModal.OverPayError')}</div>
                    ) : lowBalance ? (
                        <div className={styles.txnError}>{t('DepositRepayModal.LowBalanceError')}</div>
                    ) : noSwapRoute ? (
                        <div className={styles.txnError}>{t('SwapRepayModal.NoSwapRouteError')}</div>
                    ) : largeDeviation ? (
                        <div className={styles.txnError}>{t('SwapRepayModal.LargeDeviationError')}</div>
                    ) : ''}
                    <div className={styles.footerInfo}>
                        <div>{t('Common.EstExchange')}</div>
                        <div className={styles.tokenBalance}>
                            {`${numberFromString(repayValue).toFixed(6)} ${props.data.symbol} `}
                            {calculating && <img src={LoadingSpinner} width="20" className="d-inline-block align-top" alt="" />}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {RepayButton}
                    <Button variant="cancel" onClick={handleClose}>{t('Common.Cancel')}</Button>
                </Modal.Footer>
                <div className={styles.footerInfo}>
                    <span className={styles.hd}>{t('Common.MaximumSlippage')}:</span>
                    <span className={styles.bd}>2%</span>
                </div>
                <div className={styles.footerInfo}>
                    <span className={styles.hd}>{t('Common.MDEXHandlingFee')}:</span>
                    <span className={styles.bd}>0.3%</span>
                </div>
                <div className={styles.footerInfo}>
                    <span className={styles.hd}>{t('Common.HandlingFee')}:</span>
                    <span className={styles.bd}>{`${numberFromString(serviceValue).toFixed(6)} ${swapAsset?.symbol}`}</span>
                </div>
                {
                    showDao &&
                    <div className={styles.footerInfo}>
                        <span className={styles.bd}>{` ( ${t('Common.DAOHoldingDiscount')} ${daoValue} ${swapAsset?.symbol} )`}</span>
                    </div>
                }
                <div className={styles.footerInfo}>
                    <span className={styles.hd}>{`${t('Common.LoanBalance')}:`}</span>
                    <span className={styles.bd}>{`${props.data.loanBalanceFormatted} ${props.data.symbol}`}</span>
                </div>
            </div>


    const ModalConfirmDeviation = () =>
        <div>
            <Modal.Body>
                <FaExclamationTriangle className={styles.warning} />
                <div className={styles.approvalMsg}>
                    {t('SwapRepayModal.DeviationWarning',
                    { value: deviationAmount })}
                </div>
                <Form.Check
                    className={styles.checkBox}
                    type="checkbox"
                    label={t('Common.IUnderstand')}
                    onChange={() => { setCheckedDeviation(v => !v) }}
                    checked={checkedDeviation}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="loans" onClick={() => handleRepay()} disabled={!checkedDeviation}>{t('Common.Repay')}</Button>
                <Button variant="cancel" onClick={() => setConfirmDeviation(false)}>{t('Common.Cancel')}</Button>
            </Modal.Footer>
        </div>

    const ModalApprovalRequest = () =>
        <div>
            <Modal.Body>
                <div className={styles.approvalMsg}>{t('Common.ApprovalMsg')}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="loans" onClick={() => handleApprove(true)}>{t('Common.Approve')}</Button>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Cancel')}</Button>
            </Modal.Footer>
        </div>

    const ModalFurtherApprovalRequest = () =>
        <div>
            <Modal.Body>
                <div className="alertMsg">
                    {t('Common.ReApprovalMsg')}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="savings" onClick={() => handleApprove(false)}>{t('Common.FurtherApprove')}</Button>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Cancel')}</Button>
            </Modal.Footer>
        </div>

    const AssetSelectDialog = () =>
        <Modal
            show={showAssetSelect}
            onHide={() => setShowAssetSelect(false)}
            aria-labelledby="contained-modal-title-vcenter"
            className={styles.assetSelectModal}
            centered
            animation={false}>
            <Modal.Header closeButton>
                <div className={styles.selectAssetDesc}>
                    {t('Common.SelectAsset')}
                </div>
            </Modal.Header>
            <div className={styles.selectContainer}>
                {
                    swapData.map(data =>
                        <Row className={styles.assetItemRow} key={data.symbol} onClick={() => handleSelectAsset(data)}>
                            <Col md={12} className={styles.assetNameContainer}>
                                <img
                                    src={data.logo}
                                    width="40"
                                    height="40"
                                    className="d-inline-block align-top"
                                    alt="Logo"
                                    />
                                <div className={styles.assetName}>{data.name}</div>
                            </Col>
                        </Row>)
                }
            </div>
        </Modal>

    const TxnSuccessMsg =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={CheckIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="error"
                        />
                </div>
                <div className={styles.approvalMsg}>{t('RepayModal.SuccessMsg')}</div>
                <a className={styles.borrowLink} style={{ color: "#BDB780" }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Close')}</Button>
            </Modal.Footer>
        </div>

    const TxnErrorMsg =
        <div>
            <Modal.Body>
                <div className={styles.loadingContainer}>
                    <img
                        src={ErrorIcon}
                        width="auto"
                        height="60px"
                        className="d-inline-block align-top"
                        alt="error"
                        />
                </div>
                <div className={styles.approvalMsg}>{t('RepayModal.ErrorMsg')}</div>
                {txnHash && <a className={styles.borrowLink} style={{ color: "#BDB780" }} href={CoreData.getExplorerUrl(txnHash, networkType)} target="_blank">{t('Common.ViewTxnOnExplorer')}</a>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Close')}</Button>
            </Modal.Footer>
        </div>

    const NoSwapList =
        <div>
            <Modal.Body>
                <div className={styles.approvalMsg}>{t('SwapRepayModal.NoSwapList')}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="cancel" onClick={handleClose}>{t('Common.Close')}</Button>
            </Modal.Footer>
        </div>

    const needsApproval = swapAsset && !swapAsset.depositRepayApproved && firstApproval
    const ModalRepayForm = () => confirmDeviation ? ModalConfirmDeviation() : ModalRepayCustom()
    const ModalLoaded = () => !swapAsset ? NoSwapList : needsApproval ? ModalApprovalRequest() : ModalRepayForm()
    const ModalRendered = () => loading ? ModalLoading() : needsFurtherApproval ? ModalFurtherApprovalRequest() : ModalLoaded()
    return showAssetSelect ? AssetSelectDialog() :
        <Modal
            show={props.show && initDone}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            className={styles.txnModal}
            centered
            animation={false}>
            <Modal.Header closeButton>
                {
                    swapAsset && <>
                        <img
                            src={swapAsset.logo}
                            width="auto"
                            height="36px"
                            className="d-inline-block align-top"
                            alt="Filda Logo"
                            />
                        <Button className={styles.assetName} variant="outline-*" onClick={() => setShowAssetSelect(true)} disabled={confirmFullRepay}>
                            {swapAsset.name} <FaAngleDown />
                        </Button>
                    </>
                }
                <div className={styles.headerTips}>
                    {t('Common.DepositRepayAssetsLimitTips', { limitValue: getTransactionLimit() })}
                </div>
                {repayCompleted || repayFailed ? '' : <>
                <div className={styles.txnTypeDesc}>{t('Common.DepositRepayAssets')}</div>
                </>}
            </Modal.Header>
            {
                repayCompleted ? TxnSuccessMsg :
                repayFailed ? TxnErrorMsg : ModalRendered()
            }
        </Modal>
}
