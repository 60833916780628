import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Container, Row, Col, Nav, Button, Modal } from 'react-bootstrap'
import Web3 from "web3";
import truncateMiddle from 'truncate-middle'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel';
import { WalletAddressContext, WalletTypeContext, NetworkTypeContext } from '@src/context'
import { noticeList } from '@src/utils/notice';
import Config from '@src/utils/config';
import { switchToWallet } from '@src/methods/Wallet';
import CoreData from '@src/methods/CoreData'
import { SwitchLang } from '@src/components/littleComponents/switchLang'
import FildaLogo from '@src/images/v2/logo_text.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from './commonHeader.module.scss'


const configData = {
  wallet: {
    metamask: {
      name: 'METAMASK',
      img: 'header-wallet-metamask.svg',
      modalImg: 'header-wallet-metamask.svg',
    },
    walletconnect: {
      name: 'WALLET CONNECT',
      img: 'header-wallet-walletconnect.svg',
      modalImg: 'header-wallet-walletconnect.svg',
    }
  },
  network: {
    bsc: {
      name: 'BINANCE',
      chain: 'BSC',
      img: 'header-network-bsc.svg',
      modalImg: 'header-network-bscModal.svg',
    },
    heco: {
      name: 'HUOBI',
      chain: 'HECO',
      img: 'header-network-heco.svg',
      modalImg: 'header-network-hecoModal.svg',
    }
  }
}


export const  CommonHeader = () => {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { walletType } = useContext(WalletTypeContext)
    const { networkType } = useContext(NetworkTypeContext)
    const [showWallet, setWalletShow] = useState(false);
    const [showNetwork, setNetworkShow] = useState(false);
    const [showMenuModal, setMenuModalShow] = useState(false);
    const { t, i18n } = useTranslation()
    const { pathname } = useLocation()

    const handleClose = () => {
      setWalletShow(false)
      setNetworkShow(false)
      setMenuModalShow(false)
    }

    // render UI
    const renderNav = () => {
      return (
        <ul className="fd-navMenu">
          <li className="menuItem">
            <NavLink 
              activeClassName="active"
              className="headerLink" 
              to="/bankV2"
            >
              <span>
                {CoreData.toUpperCase(i18n.language, t('Header.Nav.Lend1'))}
              </span>
            </NavLink>
          </li>
          <li className="menuItem">
            <NavLink 
              activeClassName="active"
              className="headerLink" 
              to="/stakingV2"
            >
              <span>
                {CoreData.toUpperCase(i18n.language, t('Header.Nav.Stake'))}
              </span>
            </NavLink>
          </li>
          {/* <li className="menuItem">
            <NavLink 
              activeClassName="active"
              className="headerLink" 
              to="/voteV2"
            >
              <span>
                {CoreData.toUpperCase(i18n.language, t('Header.Nav.Vote1'))}
              </span>
            </NavLink>
          </li> */}
          {/* {
            networkType === Config.chainIdMap["128"] && (
              <li className="menuItem">
                <NavLink 
                  activeClassName="active"
                  className="headerLink" 
                  to="/third"
                >
                  <span>
                    {CoreData.toUpperCase(i18n.language, t('Header.Nav.ThirdMarkets'))}
                  </span>
                </NavLink>
              </li>
            )
          } */}
        </ul>
      )
    }


    const renderBtn = () => {
        return (
            <div className={styles.headerBtnGroup}>
              {
                connectedAddress && connectedAddress !== '0x0000000000000000000000000000000000000000'
                ? (
                  <>
                    <Button className={classNames(styles.btn, {[styles.btnError]: networkType === 'unsupported'})} onClick={() => setNetworkShow(true)}>
                      <img src={networkType && require(`@src/images/v2/${configData?.network[networkType]?.img}`)} />
                      <dl className={styles.txtGroup}>
                        <dt>{t('Common.Network')}</dt>
                        <dd>{configData?.network[networkType]?.chain}</dd>
                      </dl>
                    </Button>
                    <Button className={styles.btn} onClick={() => setWalletShow(true)}>
                      <img src={walletType && require(`@src/images/v2/${configData?.wallet[walletType]?.img}`)} />
                      <dl className={styles.txtGroup}>
                        <dt>{t('Common.Wallet')}</dt>
                        <dd>{truncateMiddle(connectedAddress,4,3,'...')}</dd>
                      </dl>
                    </Button>
                  </>
                )
                : (
                  <Button className={classNames('fd-primaryBtn', styles.connectWalletBtn)} size="sm" onClick={() => setWalletShow(true)}>
                    <img className="fd-primaryBtn-icon" src={require('@src/images/v2/icon-folder.svg')}/>
                    <span className="fd-primaryBtn-txt">{t('Header.ConnectYourWallet1')}</span>
                  </Button>
                )
              }
              <SwitchLang/>
              <div className={styles.unfold} onClick={() => setMenuModalShow(true)}>
                <img src={require('@src/images/v2/header-menu.svg')} />
              </div>
            </div>
        )
    }


    

    const renderNotice = () => {
        const settings = {
            autoPlay: true,
            infiniteLoop: true,
            interval: 5000,
            showArrows: false,
            showIndicators: false,
            showStatus: false,
            showThumbs: false,
        }
        
        let list = [...noticeList]
    
        if (noticeList.length === 1) {
            list = [...noticeList, ...noticeList]
        }
    
    
        return list.length > 0 && (
            <div className={styles.notice}>
                <Carousel {...settings}>
                    {
                        list.map((item, index) => 
                            <div key={index} className={styles.noticeItem}>
                                <a href={item?.url?.[i18n.language] || item?.url?.en} target="_blank">{item.content[i18n.language] || item.content.en}</a>
                            </div>
                        )
                    }
                </Carousel>
            </div>
    
        )
      }

    
    const WalletModal = () => {
      const {wallet} = configData;
      let result = []
      for (let [key, value] of Object.entries(wallet)) {
        result.push((
          <div className={styles.item} key={key} onClick={() => {
            switchToWallet(key);
            handleClose()
          }}>
            <img src={require(`@src/images/v2/${value?.modalImg}`)} />
            <span className={styles.txt1}>{value?.name}</span>
          </div>
        ))
      }

      return (
        <Modal
            show={showWallet}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            className={classNames('commonModal', styles.walletNetworkModal, styles.walletModal)}
            centered
            animation={false}
        >
            <Modal.Body>
              <div className="formWrap">
                {result}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="confirmBtn" onClick={handleClose}>
                {CoreData.toUpperCase(i18n.language, t('Common.Close'))}
              </Button>
            </Modal.Footer>
        </Modal>
      )
    }

    const NetworkModal = () => {
      const {network} = configData;
      let result = []
      for (let [key, value] of Object.entries(network)) {
        result.push((
          <div className={styles.item} key={key} onClick={() => {
            // switchToWallet(key);
            handleClose()
          }}>
            <img src={require(`@src/images/v2/${value?.modalImg}`)} />
            <span className={styles.txt1}>{value?.name}</span>
            <span className={styles.txt2}>{value?.chain}</span>
          </div>
        ))
      }

      return (
        <Modal
            show={showNetwork}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            className={classNames('commonModal', styles.walletNetworkModal, styles.networkModal)}
            centered
            animation={false}
        >
            <Modal.Body>
              <div className="formWrap">
                {result}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="confirmBtn" onClick={handleClose}>
                {CoreData.toUpperCase(i18n.language, t('Common.Close'))}
              </Button>
            </Modal.Footer>
        </Modal>
      )
    }


    const MenuModal = () => {
      return (
        <Modal
            show={showMenuModal}
            onHide={() => setMenuModalShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            className={classNames('commonModal', styles.menuModal)}
            centered
            animation={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              <div className="formWrap">
                <NavLink to="/">
                  <img className={styles.logo} src={FildaLogo} alt="Filda logo"/>
                </NavLink>
                {renderNav()}
                <SwitchLang drop={'up'}/>
              </div>
            </Modal.Body>
        </Modal>
      )
    }

    return (
        <div className={styles.commonHeader}>
            <Container>
              <div className={classNames(styles.container)}>
                <NavLink to="/welcome">
                  <img className={styles.logo} src={FildaLogo} alt="Filda logo"/>
                </NavLink>
                {renderNav()}
                {renderBtn()}
              </div>
              <div className={classNames(styles.tipsWrap)}>
                { 
                  networkType === 'unsupported' && 
                  <p className={classNames(styles.errorInfo, 'px-0')}>
                    <img src={require('@src/images/v2/icon-broadcast.svg')}/>
                    <span>{t('Common.UnsupportNetwork')}</span>
                  </p>
                }
                {renderNotice()}
              </div>
            </Container>
            {WalletModal()}
            {NetworkModal()}
            {MenuModal()}
        </div>
    )
}

