import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'
import { FaInfoCircle } from 'react-icons/fa'
import styles from './Booster.module.scss'
import BoosterLogo from '../images/logo_booster.png'
import ReactTooltip from 'react-tooltip'
import GeneralInvestModal from './subComponents/staking/GeneralInvestModal'
import { useWindowWidth } from '@react-hook/window-size'
import config from '../utils/config'
import { isMobile } from 'react-device-detect'
import { WalletAddressContext, Web3Context } from '../context'
import BigNumber from 'bignumber.js'


function getLocaleString(s) {
    return parseFloat(parseFloat(s).toFixed(2) || 0).toLocaleString()
}

function Booster(props) {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { web3 } = useContext(Web3Context)
    const pools = props.data
    const { t } = useTranslation()
    const clientWidth = useWindowWidth()
    const mobileMode = isMobile || clientWidth < 980
    const [showInvestModal, setShowInvestModal] = useState(false)
    const [selectedPool, setSelectedPool] = useState()

    const header =
        <Row className={styles.header}>
            <Col md={2} className={styles.headerItem}>Token</Col>
            <Col md={3} className={styles.headerItem}>TVL</Col>
            <Col md={3} className={styles.headerItem}>APY</Col>
            <Col md={2} className={styles.headerItem}>{t("Common.BorrowAPY")}</Col>
            {/* <Col md={2} className={styles.headerItem}>My TVL</Col>
            <Col md={2} className={styles.headerItem}>My Rewards</Col> */}
            <Col md={2} className={styles.headerItem}>Action</Col>
        </Row>

    const handleInvest = event => {
        setSelectedPool(pools[parseInt(event.currentTarget.id)])
        setShowInvestModal(true)
    }

    const rows = pools.map((r, i) => (
        <Row className={styles.pool} key={`booster_${i}`}>
            <Col md={2} className={classNames(styles.poolItem, styles.token)}>
                <div>
                    <img className={styles.logo} src={r.logo} alt="logo" />
                    <span className="mt-1">&nbsp;{r.name}</span>
                </div>
            </Col>

            <Col md={3} className={styles.poolItem}>
                <div className={styles.label}>TVL</div>

                <div>
                    <span className="mr-1">{getLocaleString(r.TVL)} {r.name}</span>
                    <span data-tip data-for={"tvl" + i}><FaInfoCircle /></span>
                    <ReactTooltip className={styles.tip} place="right" id={"tvl" + i} type="info">
                        <div>Total Supply: {r.getDepositTotal.shiftedBy(-r.baseToken.decimals).toFixed(2)}&nbsp;{r.baseToken.symbol}</div>
                        <div>Total Stake: {r.totalAmount.shiftedBy(-r.decimals).toFixed(2)}&nbsp;{r.symbol}</div>
                        <div>Swap Rate: 1&nbsp;{r.symbol} = {r.getBaseTokenPerCToken.shiftedBy(-r.baseToken.decimals).toFixed(4)}&nbsp;{r.baseToken.symbol}</div>
                    </ReactTooltip>
                </div>
            </Col>

            <Col md={3} className={`${styles.poolItem} d-flex`}>
                <div className={styles.label}>APY</div>

                <span className="mr-1">{r.APY ? r.APY.toFixed(2) : "-"} %</span>
            </Col>

            <Col md={2} className={styles.poolItem}>
                <div className={styles.label}>{t("Common.BorrowAPY")}</div>
                <div>{(r.loanAPY - r.loanMintAPY).toFixed(2)}%</div>
            </Col>

            {/* <Col md={2} className={styles.poolItem}>
                {parseFloat(r.myTVL).toFixed(2)} {r.name}
            </Col>

            <Col md={2} className={`${styles.poolItem} flex-column`}>
                <span>{parseFloat(r.myRewards).toFixed(2)} BOO</span>
                <span className="mt-1">{parseFloat(r.myRewards).toFixed(2)} {r.name}</span>
            </Col> */}

            <Col md={2} className={styles.poolItem}>
                <div className={styles.label}></div>

                <button id={i} className={styles.operateButton} onClick={handleInvest}>{t('Pilot.Invest')}</button>
            </Col>
        </Row>
    ))

    const marketTable =
        <Container className={styles.marketContainer}>
            <div className={styles.title}>
                <img
                    className={styles.logo}
                    src={BoosterLogo}
                    alt="Booster"
                />
            </div>
            <div className={styles.market}>
                {header}
                {rows}
            </div>
        </Container>

    const tokenUpdater = async callback => {
        const updaterContract = new web3.eth.Contract(selectedPool.ABI, selectedPool.address)
        const balance = await updaterContract.methods.balanceOf(connectedAddress).call()
        selectedPool.balanceOf = new BigNumber(balance)
        setSelectedPool(selectedPool)
        return callback()
    }

    const handleBorrowBegin = _ => {
        setShowInvestModal(false)
    }

    const handleBorrowDone = _ => {
        setShowInvestModal(true)
    }

    const handleInvestClose = _ => {
        setSelectedPool()
        setShowInvestModal(false)
    }

    return (
        <div className={styles.backContainer}>
            {marketTable}

            <GeneralInvestModal
                mobileMode={mobileMode}
                show={showInvestModal}
                marketLogo={BoosterLogo}
                data={selectedPool}
                dataTree={props.marketsArr}
                tokenUpdater={tokenUpdater}
                handleBorrowBegin={handleBorrowBegin}
                handleBorrowDone={handleBorrowDone}
                from="booster"
                handleClose={handleInvestClose}
                themeColor="#ff6800"
                website={config.booster.website} />
        </div>
    )
}

export default Booster
