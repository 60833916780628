import React, {useContext, useState, useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, Button} from 'react-bootstrap'
import styles from './Markets.module.scss';
import DepositModal from './subComponents/DepositModal'
import BorrowModal from './subComponents/BorrowModal'
import { useTranslation } from 'react-i18next'
import ContentLoader from 'react-content-loader'
import { BiLinkExternal } from 'react-icons/bi'
import FetchData from '../methods/FetchData'
import log from '../utils/logger'
import { WalletAddressContext } from '../context'
import SearchSuggest from './searchSuggest'

function Markets(props) {
  const { connectedAddress } = useContext(WalletAddressContext)
  const [showDepositModal, setShowDepositModal] = useState(false)
  const [showBorrowModal, setShowBorrowModal] = useState(false)
  const [modalMarketIndex, setModalMarketIndex] = useState(0)
  const [curList, setCurList] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [searchInputValue, setSearchInputValue] = useState('')

  let loading = props.data.loading === undefined ? true
                : connectedAddress === undefined ? true
                : props.data.loading

  log.info('Markets: ', props.data)
  const handleClose = (mode) => {
    mode === 'deposit' ? setShowDepositModal(false) : setShowBorrowModal(false)
  }
  const handleShow = (mode, i, e) => {
    e.stopPropagation()
    setModalMarketIndex(i)
    mode === 'deposit' ? setShowDepositModal(true) : setShowBorrowModal(true)
  }
  const { t } = useTranslation();

  const loadingColors = {
    background: "#111722",
    foreground: "#1A212D"
  }

  const marketsLoading =
    <ContentLoader
      height={200}
      width={"100%"}
      speed={1}
      backgroundColor={loadingColors.background}
      foregroundColor={loadingColors.foreground}
    >
      {/* Only SVG shapes */}
      <rect x="0" y="20" rx="4" ry="4" width="100%" height="40" />
      <rect x="0" y="80" rx="4" ry="4" width="100%" height="40" />
      <rect x="0" y="140" rx="4" ry="4" width="100%" height="40" />
  </ContentLoader>

  const itemClass = `${styles.marketValueItem} px-0 px-sm-1`

  const history = useHistory()
  const handleOnMarketClick = useCallback((symbol) => history.push('/markets/' + symbol), [history])

  const setCurListData = (index, list=props.data) => {
    switch(index) {
      case 0:
        setCurList(list)
        break;
      case 1:
        setCurList(list.filter(item => !item?.isLPToken))
        setSearchInputValue('')
        break;
      case 2:
        setCurList(list.filter(item => item?.isLPToken))
        setSearchInputValue('')
        break;
      default:
        setCurList(list)
    }
  }

  const tabHandler = (index) => {
    setTabIndex(index)
    setCurListData(index)
  }


  const filterResult = (list, inputValue) => {
    if (inputValue !== '') {
      for (let [key, value] of Object.entries(props.data)) {
        if (isNaN(parseInt(key))) {
          list[key] = value
        }
      }

      setCurListData(0, list)
      setTabIndex(0)
    } else {
      setCurListData(tabIndex)
    }

    setSearchInputValue(inputValue)
   

  }


  const marketsList = curList.map((market, i) => {
    return (
      <Row className={styles.marketsItemRow} key={i} onClick={() => handleOnMarketClick(market.symbol)}>
        <Col md={3}>
          <Row>
            <Col className={styles.marketNameContainer}>
              <img
                src={market.logo}
                width={market.isLPToken ? 48 : 24}
                height={24}
                className="d-inline-block align-top"
                alt={`${market.symbol} logo`}
                />
              <div className={styles.marketName} title={market.name}>
                {market.name}
                <span className={styles.marketIcon}><BiLinkExternal/></span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} lg={7} className={styles.marketValue}>
          <Row>
            <Col xs={4} md={2} className={itemClass}>
              <div className={styles.marketValueLabel}>{t('Common.SavingsAPY')}</div>
              <div>
                {(parseFloat(market.savingsAPY) + parseFloat(market.isLPToken ? market.lpTotalAPY : market.savingsMintAPY)).toFixed(2) + '%'}
              </div>
              <div className={styles.tokenValue}>
                {parseFloat(market.savingsAPY).toFixed(2) + '%'} + {parseFloat(market.isLPToken ? market.lpTotalAPY : market.savingsMintAPY).toFixed(2) + '%'}
              </div>
            </Col>
            <Col xs={4} md={2} className={itemClass}>
              <div className={styles.marketValueLabel}>{t('Common.BorrowAPY')}</div>
              <div>
                {(parseFloat(market.loanAPY) - parseFloat(market.loanMintAPY)).toFixed(2) + '%'}
              </div>
              <div className={styles.tokenValue}>
                {parseFloat(market.loanAPY).toFixed(2) + '%'} - {parseFloat(market.loanMintAPY).toFixed(2) + '%'}
              </div>
            </Col>
            <Col xs={4} md={2} className={itemClass}>
              <div className={styles.marketValueLabel}>{t('Common.Liquidity')}</div>
              <div>{FetchData.getCurrencyFormatted(market.liquidityFiat)}</div>
              <div className={styles.tokenValue}>{parseFloat(market.liquidityFormatted).toFixed(2) + ' ' + market.symbol}</div>
            </Col>
            <Col xs={4} md={2} className={`${itemClass} pr-1`}>
              <div className={styles.marketValueLabel}>{t('Common.TotalBorrowed')}</div>
              <div>{FetchData.getCurrencyFormatted(market.totalBorrowedFiat)}</div>
              <div className={styles.tokenValue}>{parseFloat(market.totalBorrowedFormatted).toFixed(2) + ' ' + market.symbol}</div>
            </Col>
            <Col xs={4} md={2} className={`${itemClass} pr-1`}>
              <div className={styles.marketValueLabel}>{t('Common.TotalSupply')}</div>
              <div>{FetchData.getCurrencyFormatted(market.totalSupplyFiat)}</div>
              <div className={styles.tokenValue}>{parseFloat(market.totalSupplyFormatted).toFixed(2) + ' ' + market.symbol}</div>
            </Col>
            <Col xs={4} md={2} className={`${itemClass} text-md-center text-left`}>
              <div className={styles.marketValueLabel}>{t('Common.UtilRate')}</div>
              <div>{parseFloat(market.utilRate) < 1e-10 ? '-' : parseFloat(market.utilRate).toFixed(2) + '%'}</div>
            </Col>
          </Row>
        </Col>
        <Col md={3} lg={2} className={`${styles.marketAction} px-md-2`}>
          <Row>
            <Col xs={6} className={`${styles.deposit} pl-0 pl-md-2 pr-2`}>
              <Button variant="savings" size="sm" block disabled={market.isMintPaused} onClick={(e) => handleShow("deposit", i, e)}>{t('Common.Deposit')}</Button>
            </Col>
            {
              tabIndex !== 2 && (
                <Col xs={6} className={`${styles.borrow} pl-2 pr-0 pr-md-2`}>
                  <Button variant="loans" size="sm" block disabled={market.isBorrowPaused || market.isLPToken} onClick={(e) => handleShow("borrow", i, e)}>{t('Common.Borrow')}</Button>
                </Col>
              )
            }
          </Row>
        </Col>
      </Row>
    )
  })


  const renderSuggestion = suggestion => (
    <div className={styles.suggList}>
      <img
        className={styles.suggLogo}
        src={suggestion.logo}
        width={suggestion.isLPToken ? 36 : 18}
        height={18}
        alt={`${suggestion.symbol} logo`}
      />
      <span className={styles.suggName}>{suggestion.name}</span>
    </div>
  );
  return (
    <div className={styles.marketsContainer}>
      <Container className={styles.marketsContainer}>
        <div className={styles.marketsTitle}>{t('Markets.Title')}</div>
        <div className={styles.tabAndSearchAssetsWrap}>
            <div className={styles.marketsBizWrap}>
              <div className={styles.marketsBiz}>
                <span className={styles.marketsBizLabel}>{t('Markets.BizSize')}</span>
                <span className={styles.marketsBizValue}>${props.data.totalBizSize || 0}</span>
              </div>
              <div className={styles.marketsBiz}>
                <span className={styles.marketsBizLabel}>{t('Markets.TVL')}</span>
                <span className={styles.marketsBizValue}>${props.data.totalTVLFiat || 0}</span>
              </div>
            </div>

            <div className={styles.tabAndSearchAssets}>
              <div className={styles.tabMarket}>
                <Button size="sm" className={{[styles.isActive]: tabIndex === 0}} onClick={() => tabHandler(0)}>{t('Markets.AllAsset')}</Button>
                <Button size="sm" className={{[styles.isActive]: tabIndex === 1}} onClick={() => tabHandler(1)}>{t('Markets.SingleAsset')}</Button>
                <Button size="sm" className={{[styles.isActive]: tabIndex === 2}} onClick={() => tabHandler(2)}>{t('Markets.MDEXLP')}</Button>
              </div>
              <div className={styles.searchAssets}>
                <SearchSuggest 
                  data={props.data} 
                  filterResult={filterResult}
                  placeholder={t('Common.SearchAssets')}
                  renderSuggestion={renderSuggestion}
                  searchInputValue={searchInputValue}
                />
              </div>
            </div>
        </div>
        <Row className={styles.marketsTableTitleRow}>
          <Col md={3} className={styles.marketsTableTitle}>
            <Row>
              <Col md={12}>{t('Common.Assets')}</Col>
            </Row>
          </Col>
          <Col md={6} lg={7} className={styles.marketsTableTitle}>
            <Row>
              <Col md={2} className={'px-0 px-sm-1'}>{t('Common.SavingsAPY')}</Col>
              <Col md={2} className={'px-0 px-sm-1'}>{t('Common.BorrowAPY')}</Col>
              <Col md={2} className={'px-0 px-sm-1'}>{t('Common.Liquidity')}</Col>
              <Col md={2} className={'px-0 px-sm-1'}>{t('Common.TotalBorrowed')}</Col>
              <Col md={2} className={'px-0 px-sm-1'}>{t('Common.TotalSupply')}</Col>
              <Col md={2} className={'px-0 px-sm-1 text-md-center'}>{t('Common.UtilRate')}</Col>
            </Row>
          </Col>
          <Col md={3} lg={2} className={styles.marketsTableTitle}>
            <Row>
              <Col>{t("Markets.DepositOrBorrowAssets")}</Col>
            </Row>
          </Col>
        </Row>
        {loading ? marketsLoading : marketsList}
        <div className={styles.marketsTableNotes}>{t('Markets.Notes')}</div>
      </Container>
      {
        curList.length > 0  && showDepositModal &&
        <DepositModal
          data={curList[modalMarketIndex]}
          show={showDepositModal}
          handleClose={(mode) => handleClose(mode)}
          styles={styles}
        />
      }
      {
        curList.length > 0  && showBorrowModal &&
        <BorrowModal
          data={curList[modalMarketIndex]}
          accountLiquidityInFiat={curList.accountLiquidityInFiat}
          totalBorrowLimitFiat={curList.totalBorrowLimitFiat}
          totalLoanBalance={curList.totalLoanBalance}
          show={showBorrowModal}
          handleClose={(mode) => handleClose(mode)}
          styles={styles}
        />
      }
    </div>
  );
}

export default Markets;
