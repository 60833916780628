import React, {useState, useContext, useEffect} from 'react'
import classNames from 'classnames'
import {DropdownButton, Dropdown} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import Config from '@src/utils/config'
import { LanguageContext } from '@src/context'

export const SwitchLang = ({drop="down"}) => {
  const { t, i18n } = useTranslation()
  const { language, setLanguage } = useContext(LanguageContext)
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (i18n.language) {
      const curLang = i18n.language.startsWith('zh') ? 'zh-CN' : i18n.language.split('-')[0]
      setLanguage(curLang)
      i18n.changeLanguage(curLang)
    }
  }, [i18n.language])

  const changeLanguage = (e, code) => {
    e.stopPropagation()
    i18n.changeLanguage(code)
    setLanguage(code)
  }

  // render UI
  const CurLang = (
    <div>
      <img width="16" src={language ? require(`@src/images/country/icon-flag-${language}.svg`) : ''} />
      <span style={{fontWeight: language === 'zh-CN' ? 'bold' : 'normal'}}>
        {Config.LanguageList.find(item => item.key === language)?.name}
      </span>
    </div>
  )

  const langItem = () => {
    return Config.LanguageList.map(item => (
      <Dropdown.Item 
        key={item.key} 
        onClick={(e) => changeLanguage(e, item.key)} 
        style={{fontWeight: item.key === 'zh-CN' ? 'bold' : 'normal'}}
      >
        <img width="16" src={require(`@src/images/country/icon-flag-${item.key}.svg`)} />
        <span>{item.name}</span>
      </Dropdown.Item>
    ))
  }

  let timer = null;

  const dropdownButtonProps = {
    id: 'dropdown-basic-button',
    title: CurLang,
    drop: drop,
    show: isClicked
  }

  if (isMobile) {
    dropdownButtonProps.onToggle = () => setIsClicked(!isClicked)
  } else {
    dropdownButtonProps.onMouseEnter = () => {
      clearTimeout(timer)
      setIsClicked(true)

    }
    dropdownButtonProps.onMouseLeave = () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        setIsClicked(false)
      }, 500)
    }
    dropdownButtonProps.onSelect = () => {
      setIsClicked(false)
    }
  }

  return (
    <div className={classNames('fd-switchLang')}>
      <DropdownButton 
        {...dropdownButtonProps}
      >
        {langItem()}
      </DropdownButton>
    </div>
  )
}