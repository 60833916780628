import React, { useContext, useEffect, useState } from 'react'
import Pilot from './components/Pilot'
// import Solo from './components/Solo'
import Booster from './components/Booster'
import Depth from './components/Depth'
import MyPositions from './components/MyPositions'
import styles from './ThirdMarkets.module.scss'
import { Container } from 'react-bootstrap'
import PtdLogo from './images/logo_ptd.webp'
import BoosterLogo from './images/logo_booster.png'
import DepthLogo from './images/logo_depth.png'
// import SoloLogo from './images/logo_solo.png'
import FetchData from './methods/FetchData'
import { NetworkTypeContext, ReadonlyWeb3Context, WalletAddressContext, Web3Context } from './context'
import { loadAllMarketData } from './Home'
import { DepthFiDataFetcher } from './methods/DepthFiDataFetcher'
import { BoosterDataFetcher } from "./methods/BoosterDataFetcher"

let dataTree = null

function ThirdMarkets() {
    const { connectedAddress } = useContext(WalletAddressContext)
    const { networkType } = useContext(NetworkTypeContext)
    const { web3 } = useContext(Web3Context)
    const { readonlyWeb3 } = useContext(ReadonlyWeb3Context)
    const [marketsState, setMarketsState] = useState(null)
    const [currentMarket, setCurrentMarket] = useState(0)
    const [depthFiData, setDepthFiData] = useState(null)
    const [boosterData, setBoosterData] = useState(null)

    useEffect(_ => {
        if (!networkType || !connectedAddress || connectedAddress === "" || networkType === "unsupported") {
            return
        }

        let timer = null

        const marketsArr = FetchData.getNetworkMarkets(networkType)

        async function initialLoad() {
            if (connectedAddress && networkType && networkType !== "unsupported" && readonlyWeb3 && web3 && marketsArr) {
                try {
                    dataTree = await loadAllMarketData(readonlyWeb3, networkType, connectedAddress, marketsArr, web3)
                    dataTree.loading = false
                    setMarketsState(dataTree)

                    setDepthFiData(await DepthFiDataFetcher(web3, networkType, connectedAddress, dataTree))
                    setBoosterData(await BoosterDataFetcher(web3, networkType, connectedAddress, dataTree))

                    timer = setInterval(async _ => {
                        setDepthFiData(await DepthFiDataFetcher(web3, networkType, connectedAddress, dataTree))
                        setBoosterData(await BoosterDataFetcher(web3, networkType, connectedAddress, dataTree))
                    }, 30000);
                } catch (e) {
                    console.error('Error to load markets data:', e)
                }
            }
        }

        initialLoad()

        return () => {
            clearInterval(timer)
            timer = null
        }
    }, [networkType, connectedAddress, readonlyWeb3, web3])

    const updateData = async _ => {
        let data = await DepthFiDataFetcher(web3, networkType, connectedAddress, dataTree)
        setDepthFiData(data)

        data = await BoosterDataFetcher(web3, networkType, connectedAddress, dataTree)
        setBoosterData(data)
    }

    const handleSwitch = event => {
        setCurrentMarket(parseInt(event.currentTarget.id))
    }

    const markets = [
        { logo: PtdLogo, name: 'pilot', component: <Pilot marketsArr={marketsState} /> },
        // { logo: SoloLogo, name: 'solo', component: <Solo marketsArr={marketsState} /> },
        { logo: DepthLogo, name: 'depth', component: <Depth marketsArr={marketsState} data={depthFiData} updateData={updateData} /> },
        { logo: BoosterLogo, name: 'booster', component: <Booster marketsArr={marketsState} data={boosterData} updateData={updateData} /> }
    ]

    const marketList = markets.map((m, i) => (
        <div key={`market_${i}`} className={styles.logoCol} id={i} onClick={handleSwitch} style={{
            backgroundColor: currentMarket === i ? "white" : "",
            border: currentMarket === i ? "none" : "",
            boxShadow: currentMarket === i ? "0px 0px 30px gainsboro" : "none"
        }}>
            <img className={styles.logo} src={m.logo} alt="Logo" />
        </div>
    ))

    return (
        <div className={styles.thirdMarkets}>
            {depthFiData && boosterData && (<>
                <MyPositions
                    marketsArr={marketsState}
                    depthFiData={depthFiData}
                    boosterData={boosterData}
                    updateData={updateData} />

                <Container className={styles.marketList}>
                    <div className={styles.marketsRow}>
                        {marketList}
                    </div>
                </Container>
            </>)}

            {markets[currentMarket].component}
        </div>
    )
}

export default ThirdMarkets
